import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AcademicCLassTimetableService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/class-timetable`;

      }
      all() {
            let url = `${this.#api}-all`;
            return apiService.get(url);
      }
      getByClass(classId){
            let url = `${this.#api}/class/`+classId;

            return apiService.get(url);
      }


      paginate(type = null) {
            let url = `${this.#api}`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      cloneTimetableFromWeekDay(data)
      {
            let url = `${this.#api}/clone-timetable`
            return apiService.post(url,data);
      }
}
