<template>
  <v-app>
    <div class="">
      <table class="table">
        <thead>
          <tr class="px-3">
            <th class="px-3 wrap-column" style="width: 40px !important; white-space: pre-wrap;">
              <v-checkbox
                class="id-card__checkbox"
                @change="selectAll()"
                v-model="checkAll"
              ></v-checkbox>
            </th>
            <th
              class="px-3 wrap-column"
              colspan="2"
            >
              Student
            </th>
            <th class="px-3 wrap-column">
              Courses
            </th>
            <th class="px-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-show="assigned_students.length > 0"
            v-for="(item, index) in assigned_students"
            :key="index"
          >
            <td class="px-3 wrap-column"  style="width: 40px !important; white-space: pre-wrap;">
              <v-checkbox
                @click="selectOne"
                v-model="checked_students"
                :value="item"
              ></v-checkbox>
            </td>
            <td class="px-3 wrap-column">
              <div class="symbol-label">
                <img v-if="item.image" :src="item.image" alt="" style="height: 30px" />
                <span v-else class="symbol symbol-35 symbol-light-success">
                  <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                    {{ item.full_name.charAt(0) }}
                  </span>
                </span>
              </div>
            </td>
            <td class="px-3" style="vertical-align: top !important;">
              <a href="#!" @click.prevent="goToSummaryPage(item)" class="mb-1">
                {{ item.full_name ? item.full_name : "NA" }}
              </a>|
              <a href="#!" @click.prevent="goToSummaryPage(item)" class="mb-1">
                <b> {{ item.crn_no }} </b> <i class="fa fa-book-open font-size-xs"></i>
                <span class="badge-dot" v-bind:class="{ 'bg-color-green': item.is_active, 'bg-color-red': !item.is_active }">
                        </span><br />
              </a>
              <span class="text-secondary" v-if="item.midas_id && item.midas_id">
                        <b> External ID:</b> {{ item.midas_id != null ? item.midas_id : 'N/A' }}
                      </span>
              <span
                  :class="{
                  'badge badge-primary': item.class_status == 'Current',
                  'badge badge-warning': item.class_status == 'Leaved',
                  'badge badge-success': item.class_status == 'Complete',
                }"
              >{{ item.class_status }}</span
              >
            </td>
            <td class="px-3 wrap-column">
              <span style="list-style: none" v-if="item && item.courses && item.courses.length > 0">
                <span v-for="(course, index) of item.courses" :key="index">
                  <semibold class="font-size-sm" label small text-color="white">{{course}}</semibold> |
                </span>
              </span>
              <span class="" v-else> NA </span>
            </td>
            <td class="px-3 text-center">
              <template v-if="item.class_status != 'Complete'">
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <slot>
                      <span>
                        <i class="flaticon-more-1"></i> </span
                    ></slot>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover">
                    <b-dropdown-text
                      tag="div"
                      class="navi-item"
                      v-if="
                        item.class_status == 'Current' &&
                        item.id &&
                        checkIsAccessible('academic-class', 'mange-student-course')
                      "
                    >
                      <a href="#" class="navi-link" @click="manageCourse(item)">
                        <span class="navi-icon">
                          <i class="fas fa-book"></i>
                        </span>
                        <span class="navi-text"> Manage course </span>
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-text
                      tag="div"
                      class="navi-item"
                      v-if="
                        item.id && checkIsAccessible('academic-class', 'remove-student')
                      "
                    >
                      <a href="#" class="navi-link" @click="removeStudentFromClass(item)">
                        <span class="navi-icon">
                          <i class="fas fa-xmark"></i>
                        </span>
                        <span class="navi-text"> Remove from class</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
              <template v-else> N/A </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <v-dialog v-model="dialog" width="900" title="Manage Student Course">
      <v-card>
        <v-card-title> Student courses </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-autocomplete
                outlined
                dense
                multiple
                label="Assign Course"
                v-model="student_courses"
                :items="assigned_courses"
                item-text="display_text_with_subject_code"
                item-value="id"
                chips
                deletable-chips
              >
              </v-autocomplete>

              <!--              <v-multiselect-listbox-->
              <!--                  :options="courses"-->
              <!--                  :reduce-display-property="(course) => course.title"-->
              <!--                  :reduce-value-property="(course) => course.id"-->
              <!--                  v-model="selectedItem"></v-multiselect-listbox>-->
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard" text @click="closeDialog"> Close </v-btn>
          <v-btn
            class="btn btn-primary text-white"
            text
            :loading="loading"
            @click="saveOrUpdateUserCurrentCourses"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <!--  <pre>{{newCourse}}</pre>-->
    </v-dialog>
  </v-app>
</template>

<script>
import StudentGradeService from "@/core/services/user/student/StudentGrade/StudentGradeService";

const gradeService = new StudentGradeService();
export default {
  name: "class-summary-students",
  props: ["academic_class", "assigned_students", "isLoading", "assigned_courses"],
  data() {
    return {
      dialog: false,
      loading: false,
      checked_students: [],
      student_courses: [],
      selected_student: {},
      headers: [
        {
          text: "",
          align: "start",
          filterable: false,
          sortable: false,
          value: "thumbnail",
        },
        { text: "#", value: "checkbox", filterable: false, sortable: false },
        { text: "Full Name", value: "full_name", filterable: true, sortable: true },
        { text: "CRN", value: "crn_no", filterable: true, sortable: true },
        { text: "Phone", value: "phone", filterable: true, sortable: true },
        { text: "Courses", value: "courses", filterable: true, sortable: true },
        { text: "Action", value: "action", filterable: false, sortable: true },
      ],
      checkAll: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.isLoading = false;
      this.selected_student = {};
      this.$emit("refresh_student_list");
    },
    manageCourse(item) {
      this.dialog = true;
      this.selected_student = item;
      this.student_courses = [];
      if (item.course_ids && item.course_ids.length > 0) {
        item.course_ids.map((ele) => {
          this.student_courses.push(parseInt(ele));
        });
      }
      // gradeService.getCurrentStudentGrade(item.id).then(response => {
      //   let studentGrade = response.data.student_grade
      //   let courses = studentGrade.courses
      //   if (courses && courses.length > 0) {
      //
      //   }
      // })
    },
    saveOrUpdateUserCurrentCourses() {
      if (this.student_courses && this.student_courses.length > 0) {
        let data = { courses: this.student_courses.join(",") };
        this.loading = true;
        gradeService
          .saveOrUpdateUserCurrentCourses(
            this.selected_student.id,
            this.academic_class.id,
            data
          )
          .then((response) => {
            this.loading = false;
            this.$snotify.success("Student course updated");
            this.closeDialog();
          }).catch(() => {
          this.loading = false;
        });
      } else {
        this.$snotify.error("No courses selected");
      }
    },
    goToSummaryPage(item) {
      this.$router.push({
        name: "students-summary",
        params: {
          id: item.id,
          type: "student",
        },
      });
    },
    removeStudentFromClass(item) {
      this.isLoading = true;
      this.$confirm({
        message: `Do you delete this student from class?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            gradeService
              .removeStudentFromClass(item.id, this.academic_class.id)
              .then((response) => {
                this.$snotify.success("Student removed from this classroom");
                this.closeDialog();
              })
              .catch((error) => {
                this.isLoading = false;
                this.$snotify.error("Something went wrong please try again later");
              });
          }
        },
      });
    },
    selectAll() {
        if (!this.checkAll) {
            this.checked_students = [];
            this.$emit("on-students-selected", this.checked_students);
        } else {
            this.assigned_students.forEach(user => {
                this.checked_students.push(user);
            })
            this.$emit("on-students-selected", this.checked_students);
        }
    },
    selectOne() {
      this.$emit("on-students-selected", this.checked_students);
    },
    refreshCheckedStudents() {
      this.checked_students = [];
      this.selectAll();
    },
    refreshAllCheckedStudent() {
      this.checked_students = [];
    },
  },
};
</script>

<style scoped></style>
