<template>
  <v-dialog  v-model="attendance_dialog" max-width="1300px">
    <v-card>
      <v-toolbar dark>
        <v-card-title class="headline">
          Student attendance
          <hr>
        </v-card-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon  @click="closeDialog">
            <i class="fas fa-times"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
<!--      <v-card-title>
        Student attendance
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>-->
      <v-card-text>
        <div class="row" v-if="report && report.length>0">
          <div class="col-12 ">
            <div class="mb-1 font-weight-medium body-1">
              <strong>Attendance date</strong>:
              {{ report[0].attendance_date }}
            </div>
            <div class="mb-1 font-weight-medium body-1">
              <strong>Course</strong>:
              {{ report[0].course }}
            </div>

<!--            <th>Course</th>-->
<!--            <td>{{ report[0].course }}</td>-->
          </div>
        </div>
        <v-row v-if="report">
          <v-col cols="12">
            <table class="table">
              <thead>
              <tr class="px-3">
                <th class="text-center">Name</th>
                <th class="text-center"> Present</th>
                <th class="text-center"> Absent</th>
                <th class="text-center"> Late</th>
                <th class="text-center"> Half</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(rep, index) of report" :key="index">
                <td class="px-3">{{ rep.full_name }}</td>
                <td  :style="{backgroundColor: getColor(rep.attendance)}" class="text-center text-white "
                    v-if="rep.attendance == 'p'">{{ getAttendanceDay(rep.attendance) }}
                </td>
                <td class=" text-center bg-gray" v-else>-</td>
                <td :style="{backgroundColor: getColor(rep.attendance)}" class="text-center text-white "
                    v-if="rep.attendance == 'a'">{{ getAttendanceDay(rep.attendance) }}
                </td>
                <td class="text-center bg-gray" v-else>-</td>
                <td :style="{backgroundColor: getColor(rep.attendance)}" class="text-center text-white  "
                    v-if="rep.attendance == 'pl'">{{ getAttendanceDay(rep.attendance) }}
                </td>
                <td class="text-center bg-gray " v-else>-</td>
                <td :style="{backgroundColor: getColor(rep.attendance)}" class="text-center text-white "
                    v-if="rep.attendance == 'ph'">{{ getAttendanceDay(rep.attendance) }}
                </td>
                <td class="text-center bg-gray px-3" v-else>-</td>

              </tr>
              </tbody>
              <!--          <tr v-for="(student,index) in report" :key="index">-->
              <!--            <td style="width: 330px" class=" pl-1">-->
              <!--              {{ student.full_name }}-->
              <!--            </td>-->
              <!--            <td class="text-center" style="width: 80px">-->
              <!--              {{ student.total_present }}-->
              <!--            </td>-->
              <!--            <td class="text-center" style="width: 80px">-->
              <!--              {{ student.total_absent }}-->
              <!--            </td>-->
              <!--            <td class="text-center" style="width: 80px">-->
              <!--              {{ student.total_late }}-->
              <!--            </td>-->
              <!--            <td class="text-center" style="width: 80px">-->
              <!--              {{ student.total_half_day }}-->
              <!--            </td>-->
              <!--            <td v-for="(day, key) in student.attendee_all" :key="key" @click="status(index, day)"-->
              <!--                :style="{backgroundColor: getColor(day[key+1])}"-->
              <!--                class="text-center text-white">-->
              <!--              {{ getAttendanceDay(day[key + 1]) }}-->
              <!--            </td>-->
              <!--          </tr>-->
            </table>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            class="cancel-btn"
            x-large
            @click="closeDialog">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import AttendanceService from "@/core/services/attendance/AttendanceService";
import {required} from "vuelidate/lib/validators";
import {AttendanceMixin} from "@/mixins/AttendanceMixin";

const attendanceService = new AttendanceService();
export default {
  mixins: [AttendanceMixin],
  name: "AttendanceReport",
  props: ["attendance_dialog", 'report'],
  methods: {
    closeDialog() {
      this.$emit('close_dialog')
    }
  }

}
</script>

<style>
.form-control {
  border: none !important;
}

.attenedance table {
  border-collapse: collapse;
}

.attenedance table, th, td {
  /*border: 1px solid black;*/
}

.table th, .table thead th, .table td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table {
  background: #fff;
}

.v-input--selection-controls__input + .v-label {
  margin-bottom: 0 !important;
}

.v-toolbar__title {
  padding-left: 10px;
}

.table .v-input .v-messages,
.v-input--radio-group .v-messages {
  display: none;
}

.v-toolbar__content .v-input--radio-group--row {
  margin-left: 10px;
}

.v-toolbar__content .v-input--radio-group--row + .v-input--radio-group--row {
  margin-left: 0px;
}

.v-toolbar__content .v-input--radio-group--row .v-input__slot {
  margin-bottom: 0;
}

.v-input__slot {
  background: transparent !important;

}

.table-bordered, .table-bordered th, .table-bordered td {
  border-color: #000 !important;
}
</style>