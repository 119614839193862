import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AcademicClassStudentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/academic-class-student`;

    }
    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }


    paginate(type = null) {
        let url = `${this.#api}`;
        if (type)
            url = url + '?type=' + type;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }
    create(classId, data) {
        let url = `${this.#api}/${classId}/create`
        return apiService.post(url, data);

    }
    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getByStudent(id) {
        let url = `${this.#api}/${id}/by-student`
        return apiService.get(url);
    }
}
