import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AcademicCLassTimetablePeriodService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/class-timetable`;

      }
      paginate(timetableId) {
            let url = `${this.#api}/${timetableId}/class-timetable-period`;
            return apiService.get(url);
      }

      update(timetableId,timetablePeriodId, data) {
            let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
            return apiService.put(url, data);

      }

      store(timetableId, data) {
            let url = `${this.#api}/${timetableId}/class-timetable-period`
            return apiService.post(url, data);

      }
      checkAvailablity(timetableId, data) {
            let url = `${this.#api}/${timetableId}/checkAvailablity`
            return apiService.post(url, data);

      }

      show(timetableId, timetablePeriodId) {
            let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
            return apiService.get(url);
      }
      getClassSubjectsById(timetableId) {
            let url = `${this.#api}/${timetableId}/class-subjects`
            return apiService.get(url);
      }

      delete(timetableId, timetablePeriodId) {
            let url = `${this.#api}/${timetableId}/class-timetable-period/${timetablePeriodId}`
            return apiService.delete(url);
      }

      getTimeTableBasedOnDate(data){
            let url = `${this.#api}/${data.timetable_id}/class-timetable-periods`
            return apiService.post(url, data);
      }
}
