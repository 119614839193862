import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class OnlineClassRoomsService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/online-class-rooms`;

    }

    paginate(data = null, index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null)
            url = url + '?' + "page=" + (index);
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getMeetingDetail(meetingId) {
        let url = `${this.#api}/${meetingId}/get-meeting`
        return apiService.get(url);
    }

    getAllTimeZones() {
        let url = `${this.#api}/timezones/all`
        return apiService.get(url);
    }

    paginateOnlineClassRoom(data, index) {
        let url = `${this.#api}/paginate/rooms`
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAllActiveDates(data) {
        let url = `${this.#api}/available/dates`
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getAllClassRoom(data = {}, index = null) {
        let url = `${this.#api}/show/all`
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
        return apiService.get(url);
    }

    getOnlineClassRoomForClass(data){
        let url = `${this.#api}/of/class`
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }


    prepareAndJoin(roomId, detailId){
        let url = `${this.#api}/${roomId}/room/${detailId}/prepare`
        return apiService.get(url);
    }
}
