<template>
  <v-app>
    <v-dialog
        v-model="dialog" fullscreen
    >
      <v-card>

        <v-toolbar style="background-color: var(--primary-color) !important" >
          <v-card-title>
            {{`${edit?'Update':"Add"} exam`}}
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <div class="row">
              <div class="col-12">
                <v-row>
                  <div class="col-12">
                    <h4>General</h4>
                  </div>
                  <div class="col-5">
                    <v-text-field outlined dense v-model="exam.title" label="Exam title (E.g. First terminal)"
                                  placeholder="Exam title (E.g. First terminal)"></v-text-field>
                    <span class="text-danger"
                          v-if="$v.exam.title.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="locations"
                        v-model="exam.exam_location"
                        outlined
                        dense
                        label="Exam location"
                        item-text="text"
                        item-value="value"
                    >

                    </v-select>
                    <span class="text-danger"
                          v-if="$v.exam.exam_location.$error">This information is required</span>
                  </div>
                  <div class="col-3">
                    <v-text-field outlined dense v-model="exam.exam_year" label="Exam year(Displayed on certificate)"
                                  placeholder="Exam year"></v-text-field>
                    <span class="text-danger"
                          v-if="$v.exam.exam_year.$error">This information is required</span>
                  </div>
                  <div class="col-6">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="exam.start_date"
                            label="Start date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          :min="date"
                          v-model="exam.start_date"
                          @input="menu = false"
                          no-title
                          scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                    <span class="text-danger"
                          v-if="$v.exam.start_date.$error">Start Date is required</span>
                  </div>
                  <div class="col-6">
                    <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="exam.end_date"
                            label="End date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          :min="exam.start_date"
                          v-model="exam.end_date"
                          @input="menu2 = false"
                          no-title
                          scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                    <span class="text-danger"
                          v-if="$v.exam.end_date.$error">End Date is required</span>
                  </div>
                  <div class="col-12">
                    <h4>Academic selection</h4>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="types"
                        v-model="exam.type"
                        outlined
                        dense
                        item-text="text"
                        item-value="value"
                        label="Exam type"
                    >
                    </v-select>
                    <span class="text-danger"
                          v-if="$v.exam.type.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="academicYears"
                        v-model="exam.academic_year_id"
                        outlined
                        dense
                        item-text="year"
                        item-value="id"
                        label="Academic year"
                    >
                    </v-select>
                    <span class="text-danger"
                          v-if="$v.exam.academic_year_id.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="levels"
                        v-model="exam.level_id"
                        outlined
                        dense
                        item-text="title"
                        item-value="id"
                        label="Level"
                        @change="getPrograms"
                    >

                    </v-select>
                    <span class="text-danger" v-if="$v.exam.level_id.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="programs"
                        v-model="exam.program_id"
                        outlined
                        dense
                        label="Program"
                        item-text="title"
                        item-value="id"
                        @change="getGrades()"
                    >

                    </v-select>
                    <span class="text-danger"
                          v-if="$v.exam.program_id.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-select
                        :items="gradesLevels"
                        v-model="exam.grade_id"
                        outlined
                        dense
                        label="Semester/year"
                        item-text="title"
                        item-value="id"
                        @change="getSections"
                    >

                    </v-select>
                    <span class="text-danger" v-if="$v.exam.grade_id.$error">This information is required</span>
                  </div>
                  <div class="col-4">
                    <v-switch
                        v-model="exam.is_classes_exam"
                        :label="exam.is_classes_exam?'Examine students in selected class':'Examine all students in every class'"
                    >
                    </v-switch>
                  </div>
                  <div class="col-12" v-if="exam.is_classes_exam">

                    <v-autocomplete
                        multiple
                        v-model="selected"
                        :items="classes"
                        label="Select classes to examine"
                        outlined
                        dense
                        item-text="title"
                        item-value="id"
                        name="classes"
                        class=" multiple form-control-solid form-control-lg"
                    >
                    </v-autocomplete>
                  </div>

                  <!--                <div class="col-3">
                                    <v-text-field
                                        type="number"
                                        v-model="exam.duration"
                                        outlined
                                        dense
                                        label="Duration"

                                    >

                                    </v-text-field>
                                    <span class="text-danger" v-if="$v.exam.duration.$error">Duration is required</span>
                                  </div>
                                  <div class="col-3">
                                    <v-select
                                        :items="durations_units"
                                        v-model="exam.duration_unit"
                                        outlined
                                        dense
                                        label=" Duration Unit"
                                        item-text="title"
                                        item-value="id"
                                    >

                                    </v-select>
                                    <span class="text-danger"
                                          v-if="$v.exam.program_id.$error">Duration Unit is required</span>
                                  </div>-->
                  <div class="col-12">
                    <h4>Grading settings</h4>
                  </div>

                  <div class="col-6">
                    <v-select
                        :items="points"
                        v-model="exam.grade_point_id"
                        outlined
                        dense
                        label="Course grading system"
                        item-text="display_title"
                        item-value="id"
                    >

                    </v-select>
                    <span class="text-danger"
                          v-if="$v.exam.exam_location.$error">This information is required</span>
                  </div>
                  <div class="col-6">
                    <v-select
                        :items="overall_grading_points"
                        v-model="exam.overall_grade_point_id"
                        outlined
                        dense
                        label="Overall grading system"
                        item-text="display_title"
                        item-value="id"
                    >

                    </v-select>
                  </div>

                  <div class="col-4">
                    <v-text-field
                        v-model="exam.total_attendance_days"
                        outlined
                        dense
                        label="Total attendance days"

                    >
                    </v-text-field>
                  </div>



                  <div class="col-4" v-if="exam.id && exam.result_publish_date">
                    <v-menu
                        ref="menu"
                        v-model="result_publish_date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="exam.result_publish_date"
                            label="Result publish date"
                            prepend-inner-icon="mdi-calendar"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="exam.result_publish_date"
                          no-title
                          scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>

                  <div class="col-12">
                    <v-textarea
                        v-model="exam.location_description"
                        outlined
                        dense
                        placeholder="Remarks"
                        label=" Remarks"
                    >

                    </v-textarea>
                  </div>

                  <div class="col-3">
                    <v-switch
                        v-model="exam.is_active"
                        label="Status"
                    >

                    </v-switch>
                  </div>
                  <div class="col-12 text-right mt-4">
                    <v-btn
                        class="btn btn-standard cancel-btn"
                        depressed
                        @click="resetForm"
                    >Cancel
                    </v-btn>
                    <v-btn
                        class="text-white ml-2 btn btn-primary"
                        depressed
                        @click="createAndUpdate"
                        :loading="isBusy"
                    >Save
                    </v-btn>

                  </div>
                </v-row>
                <!--                    </v-tab-item>-->
                <!--                    <v-tab-item class="mt-5">-->
                <!--                      <v-multiselect-listbox :options="['Software Engineer', 'Java']"></v-multiselect-listbox>-->
                <!--                    </v-tab-item>-->
                <!--                  </v-tabs>-->
              </div>
            </div>
          </v-container>

        </v-card-text>
      </v-card>

    </v-dialog>
  </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import GradePointService from "@/core/services/level/program/grade-point/GradePointService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
const pointService = new GradePointService();

export default {
  name: "CreateOrUpdateExams",
  validations: {
    exam: {
      title: {required},
      exam_year: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      end_date: {required},
      /*  duration: {required},
        duration_unit: {required},*/
      exam_location: {required},
    }
  },
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
      },
      exam: {
        title: null,
        exam_year: null,
        type: 'terminal',
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        /*  duration: null,
          duration_unit: 'hours',*/
        has_additional_assessment: false,
        exam_location: 'in-premise',
        location_description: null,
        is_active: true,
        isBusy: false,
        is_classes_exam: false,
        applicable_classes: null
      },
      points: [],
      additional_assessments: [],
      overall_grading_points: [],
      classes: [],
      previousExams: [],
      selected: [],
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      isBusy: false,
      result_publish_date: false,
      menu: false,
      edit: false,
      menu2: false,
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ], locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In premise',
          value: 'in-premise',
        },
        {
          text: 'Off premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears();
    this.getCourseGradePoints();
    this.getOverallGradePoints();
  },
  methods: {
    getCourseGradePoints() {
      pointService.paginate({is_active: 1, grade_point_type: 'course'}).then(response => {
        let points = response.data.data
        if (points && points.length > 0) {
          points.forEach(ele => {
            ele.display_title = `${ele.title} (${ele.type})`
            this.points.push(ele)
          })
        }
      })
    }, getOverallGradePoints() {
      pointService.paginate({is_active: 1, grade_point_type: 'overall'}).then(response => {
        let points = response.data.data
        if (points && points.length > 0) {
          points.forEach(ele => {
            ele.display_title = `${ele.title} (${ele.type})`
            this.overall_grading_points.push(ele)
          })
        }
      })
    },
    showModal(exam = {}, academic_class = {}) {
      this.buildSelected();
      if (exam.id) {
        this.edit = true
        this.exam = exam
        this.getPrograms()
        this.getGrades();
        this.getSections()
        this.buildSelected();
        this.getPreviousExams();

      } else if (academic_class.id) {
        this.exam.level_id = academic_class.level_id
        this.exam.program_id = academic_class.program_id
        this.exam.academic_year_id = academic_class.academic_year_id
        this.exam.grade_id = academic_class.grade_id
        this.selected.push(academic_class.id)
        this.getPrograms()
        this.getGrades();
        this.getSections()

        this.edit = false
      } else {
        this.edit = false;
        this.resetForm();
      }
      this.dialog = true
      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = []
      this.dialog = false;

    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    },


    buildSelected() {
      let ids = this.exam.applicable_classes;

      if (ids && ids.length > 0) {
        ids = ids.split(",");
        ids.forEach(ele => {
          this.selected.push(parseInt(ele));
        });
      }

    },
    getPreviousExams() {
      this.search.academic_year = this.exam.academic_year_id
      this.search.program_id = this.exam.program_id
      this.search.grade_id = this.exam.grade_id
      // console.log(this.search)
      examService.paginate(this.search).then(response => {
        this.previousExams = response.data.data;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.exam.level_id).then(response => {
        this.programs = response.data;
        if (this.exam.program_id)
          this.getProgram()
      });
    },
    getProgram() {
      programService.show(this.exam.program_id).then(response => {
        if (response.data.program && response.data.program.additional_assessments)
          this.additional_assessments = response.data.program.additional_assessments
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.exam.program_id).then(response => {
        this.gradesLevels = response.data;
        // console.log(response)
        this.getGradePoints()
      });
    },


    getSections() {
      let data={};
      if(this.exam.academic_year_id){
        data.academic_year_id=this.exam.academic_year_id;
      }

      examService.getClassExam(this.exam.program_id, this.exam.grade_id,data).then(response => {
        this.classes = response.data.class;
      });
    },


    createAndUpdate() {
      this.exam.applicable_classes = this.selected.join(",");
      this.$v.$touch();
      if (this.$v.exam.$error) {

        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateExam();
        } else {
          this.createExam();
        }
        this.$emit('refresh')

      }
    },
    createExam() {
      this.isBusy = true;
      examService.store(this.exam).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$snotify.success("Information added");
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(final => {
        this.isBusy = false
      });
    },
    updateExam() {
      this.isBusy = true;
      examService.update(this.exam.id, this.exam).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$snotify.success("Information updated");
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(final => {
        this.isBusy = false
      });
    }, resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        /*  duration: null,
          duration_unit: null,*/
        has_additional_assessment: false,
        exam_location: null,
        location_description: null,
        is_classes_exam: false,
        applicable_classes: null,
        is_active: true
      }
      this.selected = [];
      this.$v.$reset()
      this.hideModal()
    }
  }
};
</script>
<style>
.v-application--wrap {
  min-height: 0 !important;
}
</style>
