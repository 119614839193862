import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserService from "@/core/services/user/UserService";
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const userService = new UserService();

const FilterMixin = {
    data() {
        return {
            users: [],
            levels: [],
            programs: [],
            academicYears: [],
            gradesLevels: [],
            academic_classes: [],
            assigned_courses: [],
            zoom_teachers: [],
            courses: [],
            teachers: [],
            loading: false,


        }
    },
    methods: {
        getUsers(search) {
            userService.paginate(search).then(response => {
                this.users = response.data.data
            })
        },
        getAvailableTeachersOnlineClassroom(search) {
            userService.paginate(search).then(response => {
                this.zoom_teachers = response.data.data
                this.zoom_teachers = response.data.data
            })
        },
        getLevels() {
            this.loading = true;
            levelService.all().then(response => {
                this.levels = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        getPrograms(level_id) {
            this.loading = true;
            programService.getAllByLevelId(level_id).then(response => {
                this.programs = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        getAllPrograms() {
            this.loading = true;
            programService.all().then(response => {
                this.programs = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        getAcademicYears() {
            this.loading = true;
            academicYearService.all().then(response => {
                this.academicYears = response.data;

            }).finally(() => {
                this.loading = false;
            });
        },
        getIncompletedAcademicYears() {
            this.loading = true;
            academicYearService.getIncompletedAcademicYears().then(response => {
                this.academicYears = response.data;

            }).finally(() => {
                this.loading = false;
            });
        },
        getGrades(program_id) {
            this.loading = true;
            gradeLevelService.getByProgram(program_id).then(response => {
                this.gradesLevels = response.data;

            }).finally(() => {
                this.loading = false;
            });
        },
        getAcademicClasses(academic_year_id, program_id, grade_id) {
            this.loading = true;
            academicClassService.getByProgramLevel(academic_year_id, program_id, grade_id).then(response => {
                this.academic_classes = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        getAllActiveTeachers() {
            this.loading = true;
            userService.getAllActiveTeachers().then(response => {
                this.zoom_teachers = response.data;
                this.teachers = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        getCourses(search) {
            this.loading = true;
            this.$bus.emit('toggleLoader',);
            courseService.paginate(search).then(response => {
                this.courses = response.data.data;
                this.$bus.emit('toggleLoader');
            }).finally(() => {
                this.loading = false;
            });
        },
        getAssignedCourses(class_id) {
            this.loading = true;
            academicClassService.getAssignedCourses(class_id).then(response => {
                this.assigned_courses = response.data.courses;
            }).finally(() => {
                this.loading = false;
            });
        },

    },


}
export default FilterMixin;
