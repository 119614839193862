<template>
  <v-app>

    <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialog"

    >
      <v-toolbar
      style="background: #04458c !important;"
      >


          <v-row>
            <v-col cols="6 pt-5 ">
              <h4 class="text-white">Add students</h4>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn icon  @click="hideModal">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-col>
          </v-row>
      </v-toolbar>
      <v-card>

        <v-card-text>
          <div class="row ">
            <div class="col-2 text-right">
              <v-switch
                  v-model="selectedCourseSwitch"
                  :label="courseSwitchLabel"
              >
              </v-switch>
            </div>
            <div class="col-3">
                <v-text-field outlined dense v-model="search.title" label="Search by CRN number, first name, last name, email"></v-text-field>
            </div>
            <div class="col-2">
              <v-btn depressed class="btn btn-primary text-white" @click="getStudents">Search</v-btn>
            </div>
            <div class="col-4 text-right">
              <v-btn :loading="loading"
                     class="text-white ml-2 btn btn-primary"
                     @click="save"
              >
                Save
              </v-btn>
            </div>


          </div>
          <div class="row ">
              <div class="col-12">
                <div  v-if="loading">
                  <v-skeleton-loader
                                     v-bind="attrs"
                               type="list-item-two-line"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                                     v-bind="attrs"
                               type="list-item-two-line"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                                     v-bind="attrs"
                               type="list-item-two-line"
                  ></v-skeleton-loader>
                </div>
                <table class="table table-stripe" v-else>
                  <thead>
                  <tr>

                    <th>
                      <v-checkbox @change="selectAll()" v-model="checkAll" outlined dense
                                  label="Full Name"></v-checkbox>
                    </th>
                    <th>CRN No.</th>
                    <th v-if="selectedCourseSwitch">Courses</th>
                  </tr>
                  </thead>
                  <tbody v-if="students && students.length>0">
                  <tr v-for="(student, index) of students" :key="index">
                    <td>
                      <v-checkbox v-model="selectedStudents" :value="student.id" outlined
                                  dense
                                  v-bind:label="(student.full_name)?student.full_name:null"></v-checkbox>
                    </td>

                    <td>{{ student.user_personal_id ? student.user_personal_id : "N/A" }}
                    </td>
                    <td v-if="selectedCourseSwitch">
                      <div class="col-12">

                        <v-autocomplete v-if="student.saved_courses.length"
                                        outlined
                                        dense
                                        multiple
                                        label="Course"
                                        v-model="student.saved_courses"
                                        :items="assigned_courses"
                                        item-text="title"
                                        item-value="id"
                        >
                        </v-autocomplete>
                        <!--                        <v-autocomplete v-else-->
                        <!--                                        outlined-->
                        <!--                                        dense-->
                        <!--                                        multiple-->
                        <!--                                        label="Course"-->
                        <!--                                        v-model="student.courses"-->
                        <!--                                        :items="assigned_courses"-->
                        <!--                                        item-text="title"-->
                        <!--                                        item-value="id"-->
                        <!--                        >-->
                        <!--                        </v-autocomplete>-->


                      </div>
                    </td>


                  </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                        <td colspan="4" class="text-center">Data not available</td>
                      </tr>
                  </tbody>
                </table>

              </div>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </v-app>
</template>
<script>
import vMultiselectListbox from 'vue-multiselect-listbox'
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import UserService from "@/core/services/user/UserService";
import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";

const academicClassStudentService = new AcademicClassStudentService()
const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
const userService = new UserService();
export default {
  name: "assignStudents",

  components: {},
  data() {
    return {
      search: {
        title:null,

      },
      selectedStudents: [],
      checkAll: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      academic_class: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        is_active: true
      },
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      menu: false,
      edit: false,
      menu2: false,
      loading: false,
      users: [],
      students: [],
      programs: [],
      academicYears: [],
      selected: [],
      courses: [],
      classes: [],
      class_students: [],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
      courseSwitchLabel: 'Selected Courses',
      selectedCourseSwitch: true,
      assigned_students: {}

    };
  },

  watch: {

    selectedCourseSwitch: function () {
      if (this.selectedCourseSwitch)
        this.courseSwitchLabel = "Selected Courses"
      else
        this.courseSwitchLabel = "All Courses"

    }
  },
  mounted() {

  },
  methods: {
    showModal(academic_class = {}) {
      if (academic_class.id) {

        this.edit = true
        this.dialog = true
        this.academic_class = academic_class;
        this.getAssignedCourses();
        //this.getAssignedStudents();


      }
    },
    hideModal() {
      this.teacher = []
      this.dialog = false;
      this.$emit('refresh')
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = !dialog;
    },
    getStudents() {
      this.students = [];
      this.loading = true
      academicClassService.getStudentsForClassAssign(this.academic_class.id, this.search).then(response => {
        if(!response.data){
          this.students = [];
        }else{
          let students = response.data.students;
          this.setSavedCourses(students)
        }
      }).finally(() => {
        this.loading = false
      });
    },
    setSavedCourses(students) {
      if (students && students.length > 0) {
        students.forEach(ele => {
          let ids = this.assigned_courses.map(ele => {
            return parseInt(ele.id);
          })
          if (ele.saved_courses && ele.saved_courses.length <= 0) {
            ele.saved_courses = ids
          }
          else
          {
            this.selectedStudents.push(ele.id);
          }
          this.students.push(ele)
        })
      }
    },
    getAssignedCourses() {
      academicClassService.getAssignedCourses(this.academic_class.id).then(response => {
        this.assigned_courses = response.data.courses;
        this.getStudents();
      });
    },

    selectAll() {
      if (!this.checkAll) {
        this.selectedStudents = [];
      } else {
        this.students.forEach(student => {
          this.selectedStudents.push(student.id);
        })
      }
    },

    getAssignedStudents() {
      /*   academicClassService.getAssignedStudents(this.academic_class.id).then(response => {
           this.selectedStudents = response.data.students;
         });*/
    }
    ,
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    }
    ,
    getCourses() {
      examService.getCourseExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
        this.courses = response.data.courses;
      });
    }
    ,
    getClassExam() {
      examService.getClassExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
        this.classes = response.data.class;
      });
    }
    ,
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    }
    ,
    getGrades() {
      gradeLevelService.paginate().then(response => {
        this.gradesLevels = response.data.data;

      });
    }
    ,
    async save() {
      if(this.selectedStudents.length<1)
      {
        this.$snotify.error("Please select students to assign");
      }else
      {
        let data = await this.buildData();
       this.$confirm({
          message: `Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          callback: confirm => {
            if (confirm) {
              this.loading = true
              academicClassService.saveStudentAssignCourses(this.academic_class.id, data).then((response) => {
                this.resetForm()
                this.$snotify.success("Information updated");
              }).catch(error => {
                this.$snotify.error("Something went wrong");
              }).finally(() => {
                this.loading = false
              })
            }
          }
        });
      }

    },
    buildData() {
      let result = []
      if (this.students && this.students.length > 0) {
        this.students.forEach(ele => {
          if (this.selectedStudents.includes(ele.id))
          {
            if(!this.selectedCourseSwitch)
            {
              ele.all_course = true;
            }
            result.push(ele);
          }

        })
      }
      return result;
    },

    resetForm() {
      this.selectedStudents = [];
      this.class_students = [];
      this.students = [];
      this.academic_class = {
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        is_active: true
      }
      this.selectedCourseSwitch = true;
      this.hideModal();

    }
  }
};
</script>
