
 const OnlineClassRoomMixin={
    data(){
        return{
            room:{},
            room_id:null,
            course_id:null,
            attendance_dialog:false
        }
    },
    methods:{
        viewOnlineAttendance(room){
            this.room = room
            this.room_id = room.id
            this.course_id = room.course_id
            this.attendance_dialog=true;
            if (this.room_id && this.dialog)
            this.$refs['online-class-attendance'].getOnlineAttendance();
        },
        closeDialog(){
            this.room = {}
            this.room_id = null
            this.course_id = null
            this.attendance_dialog=false;
        }
    }
}
export default OnlineClassRoomMixin;