<template>
  <v-card>
    <v-card-text>

      <v-toolbar flat>
        <h3>Attendance</h3>
        <template v-slot:extension>
          <v-tabs
              v-model="tabs"
              fixed-tabs
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="checkIsAccessible('academic-class', 'attendance-report')">
              Report
            </v-tab>
            <v-tab v-if="checkIsAccessible('academic-class', 'add-attendance')">
              Add
            </v-tab>
            <v-tab v-if="checkIsAccessible('academic-class', 'attendance-pending')">
              Pending
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tabs">

        <v-tab-item v-if="checkIsAccessible('academic-class', 'attendance-report')">
          <attendance-report 
            :academic_class="academic_class" 
            :timetable="timetable"
            :courses="courses"
            :assigned_teachers="assigned_teachers"
          ></attendance-report>
        </v-tab-item>
        <v-tab-item v-if="checkIsAccessible('academic-class', 'add-attendance')">
          <take-attendance
              v-if="assigned_teachers && assigned_teachers.length>0"
              :academic_class="academic_class"
              :timetable="timetable"
              :courses="courses"
              :assigned_teachers="assigned_teachers"
          ></take-attendance>
        </v-tab-item>
        <v-tab-item v-if="checkIsAccessible('academic-class', 'attendance-pending')">
          <approve-class-taken :academic_class="academic_class" :courses="courses"></approve-class-taken>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import TakeAttendance from "@/view/pages/academic-class/summary/attendance/TakeAttendance";
import AttendanceReport from "@/view/pages/academic-class/summary/attendance/AttendanceReport";
import ApproveClassTaken from "@/view/pages/academic-class/summary/attendance/ApproveClassTaken";

export default {
  name: "Attendance",
  props: ['timetable', 'courses', 'academic_class', 'assigned_teachers'],
  components: {
    TakeAttendance, AttendanceReport, ApproveClassTaken
  },
  data() {
    return {
      tabs: null
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
