<template>
  <v-app>
    <div class="table-responsive" style="table-layout: fixed">
      <table class="table">
        <thead>
          <tr class="px-3">
            <th class="px-3 wrap-column">
              Title
            </th>
            <th class="px-3 wrap-column">Class required</th>
            <th class="px-3 wrap-column">Class completed</th>
            <th class="px-3 mr-2"></th>
          </tr>
        </thead>
        <tbody>
          <template>
            <tr
              v-show="assigned_courses.length > 0"
              v-for="(course, index) in assigned_courses"
              :key="index"
            >
              <td class="px-3 wrap-column">
                <a
                  v-if="course.lesson_plan_id"
                  @click="goToSummaryPage(course)"
                  class="text-primary font-weight-bolder text-hover-primary mb-1"
                >
                  {{ course.display_text_with_subject_code }}
                  <small v-if="course.lesson_plan_name"
                    >({{ course.lesson_plan_name }})</small
                  >
                </a>
                <span v-else>{{ course.display_text_with_subject_code }}</span>
                <div class="mt-2">
                  <span
                    class="badge badge-success"
                    :class="course.is_optional ? 'badge badge-info' : 'badge badge-info'"
                  >
                    {{ course.is_optional ? "Optional" : "Compulsory" }}
                  </span>
                </div>
<!--                <div class="my-2">-->
<!--                  <span class="badge badge-success ml-2">{{ course.subject_code }}</span>-->
<!--                </div>-->
              </td>
              <td class="px-3 wrap-column">NA</td>
              <td class="px-3 wrap-column">NA</td>
              <td class="pr-0 text-center">
                <template>
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <slot>
                        <span>
                          <i class="flaticon-more-1"></i>
                        </span>
                      </slot>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover">
                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="course.program.additional_assessments"
                      >
                        <a
                          href="#"
                          class="navi-link"
                          @click="manageAdditionalAssessment(course.id)"
                        >
                          <span class="navi-icon">
                            <i class="fas fa-cogs"></i>
                          </span>
                          <span class="navi-text"> Additional assessment </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="
                          checkIsAccessible('academic-class', 'create-new-lesson-plan')
                        "
                      >
                        <a href="#" class="navi-link" @click="__add(course)">
                          <span class="navi-icon">
                            <i class="flaticon-edit"></i>
                          </span>
                          <span class="navi-text"> Create new lesson plan </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="checkIsAccessible('academic-class', 'assign-lesson-plan')"
                      >
                        <a
                          href="#"
                          class="navi-link"
                          @click="assignLessonPlan(course, index)"
                        >
                          <span class="navi-icon">
                            <i class="fas flaticon2-open-text-book"></i>
                          </span>
                          <span class="navi-text"> Assign lesson plan </span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="checkIsAccessible('academic-class', 'delete-lesson-plan')"
                      >
                        <a href="#" class="navi-link" @click="__deleteCourse(index)">
                          <span class="navi-icon">
                            <i class="fas fa-trash"></i>
                          </span>
                          <span class="navi-text"> Delete</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <!--      <v-data-table
          :loading="isLoading"
          :headers="course_headers"
          :items-per-page="20"
          :items="assigned_courses"
      >
        <template v-slot:item.thumbnail="{ item }">
          <div class="symbol-label">
            <img v-if="item.photo" :src="item.image_path.thumb" class="cursor-pointer"
                 alt=""
                 style="height: 30px;">
            <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                    >
                                                        {{ item.full_name.charAt(0) }} </span>
                                                </span>
          </div>
        </template>
        <template v-slot:item.title="{ item }">
          <router-link v-if="item.lesson_plan_id" :to="{name:'course-summary',params:{'lesson_plan_id': item.lesson_plan_id} }">
            {{ item.display_text_with_subject_code }} <small v-if="item.lesson_plan_name">({{ item.lesson_plan_name }})</small>
          </router-link>
          <span v-else>{{ item.display_text_with_subject_code }}</span>
          <br/>
          <span
              :class="item.is_optional?'badge badge-info':'badge badge-info'">{{
              item.is_optional ? 'Optional' : 'Compulsory'
            }}</span>
          <span class="badge badge-success ml-2">{{ item.subject_code }}</span>
        </template>
        <template v-slot:item.is_active="{ item }">
                                                <span
                                                    :class="item.is_active?'badge badge-success':'badge badge-danger'">{{
                                                    item.is_active ? 'Active' : 'Inactive'
                                                  }}</span>
        </template>
        <template v-slot:item.action="{ item,index }">
          <b-dropdown
              size="sm"
              variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              no-caret
              right
              no-flip
          >
            <template v-slot:button-content>
              <slot>
              <span>
                <i class="flaticon-more-1"></i>
              </span>
              </slot>
            </template>
            &lt;!&ndash;begin::Navigation&ndash;&gt;
            <div class="navi navi-hover ">
              <b-dropdown-text tag="div" class="navi-item" v-if="item.program.additional_assessments">
                <a href="#" class="navi-link" @click="manageAdditionalAssessment(item.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-cogs"></i>
                                                            </span>
                  <span class="navi-text"> Additional assessment </span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('academic-class', 'create-new-lesson-plan')">
                <a href="#" class="navi-link" @click="__add(item)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                  <span class="navi-text"> Create new lesson plan </span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('academic-class', 'assign-lesson-plan')">
                <a href="#" class="navi-link" @click="assignLessonPlan(item,index)">
                                                            <span class="navi-icon">
                                                              <i class="fas flaticon2-open-text-book"></i>
                                                            </span>
                  <span class="navi-text"> Assign lesson plan </span>
                </a>
              </b-dropdown-text>

              <b-dropdown-text tag="div" class="navi-item"
                               v-if="checkIsAccessible('academic-class', 'delete-lesson-plan')">
                <a href="#" class="navi-link" @click="__deleteCourse(index)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                  <span class="navi-text">  Delete</span>
                </a>
              </b-dropdown-text>

            </div>
            &lt;!&ndash;end::Navigation&ndash;&gt;
          </b-dropdown>
          &lt;!&ndash;                                          <span class="navi-icon cursor-pointer" @click="__deleteCourse(index)">&ndash;&gt;
          &lt;!&ndash;                                                              <i class="fas fa-trash"></i>&ndash;&gt;
          &lt;!&ndash;                                                            </span>&ndash;&gt;
          &lt;!&ndash;                <span class="navi-icon cursor-pointer ml-3" @click="assignLessonPlan(item,index)">&ndash;&gt;
          &lt;!&ndash;                                                             <i title="  Assign Lesson Plan "&ndash;&gt;
          &lt;!&ndash;                                                                class="fas flaticon2-open-text-book"></i>&ndash;&gt;
          &lt;!&ndash;                                                            </span>&ndash;&gt;
          &lt;!&ndash;            __deleteCourse&ndash;&gt;
        </template>


      </v-data-table>-->
    </div>

    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title> Assign Lesson Plan </v-card-title>
        <v-card-text>
          <v-autocomplete
            outlined
            dense
            v-model="class_courses.lesson_plan_id"
            item-text="title"
            item-value="id"
            label="Select Lesson Plan"
            :items="lesson_planners"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard" text @click="dialog = false"> Close </v-btn>
          <v-btn class="btn btn-primary text-white" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <class-lesson-planner-model
      ref="lesson_plan"
      :lesson_plan_obj="lesson_plan_obj"
    ></class-lesson-planner-model>
  </v-app>
</template>

<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";

const academicClassService = new AcademicClassService();
const lessonPlannerService = new LessonPlannerService();
import ClassLessonPlannerModel from "./ClassLessonPlannerModel";

export default {
  name: "class-summary-courses",
  props: ["assigned_courses", "academic_year_id", "academic_class_obj", "isLoading"],
  components: { ClassLessonPlannerModel },
  data() {
    return {
      courses: [],
      lesson_planners: [],
      class_courses: {
        lesson_plan_id: null,
        course_id: null,
        id: null,
      },
      lesson_plan_obj: {
        course_id: null,
        class_id: this.academic_class_obj ? this.academic_class_obj.id : null,
        added_by: null,
        teacher_id: null,
        academic_year_id: this.academic_class_obj
          ? this.academic_class_obj.academic_year_id
          : null,
        title: "",
        description: null,
        is_default: 1,
        is_active: 1,
        is_added_by_admin: 1,
      },
      lessonPlannerSearch: {
        academic_year_id: null,
        course_id: null,
      },
      dialog: false,
      course_headers: [
        { text: "Title", value: "title", filterable: true, sortable: true },
        { text: "Class Required", value: "", filterable: true, sortable: true },
        { text: "Class Completed", value: "", filterable: true, sortable: true },
        { text: "", value: "action", filterable: false, sortable: true },
      ],
    };
  },
  computed: {
    academic_class_id() {
      return this.$route.params.classId;
    },
  },
  mounted() {},
  methods: {
    __add(item) {
      (this.lesson_plan_obj = {
        course_id: item.id,
        class_id: this.academic_class_obj ? this.academic_class_obj.id : null,
        added_by: null,
        teacher_id: null,
        academic_year_id: this.academic_class_obj
          ? this.academic_class_obj.academic_year_id
          : null,
        title: "",
        description: null,
        is_default: 1,
        is_active: 1,
        is_added_by_admin: 1,
      }),
        // console.log(this.lesson_plan_obj);
      this.$refs["lesson_plan"].showModal(this.lesson_plan_obj);
    },
    __edit(id) {
      this.$refs["lesson_plan"].showModal(id);
    },
    __getLessonPlanner() {
      lessonPlannerService
        .paginate(this.lessonPlannerSearch)
        .then((response) => {
          this.lesson_planners = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    assignLessonPlan(item, index) {
      this.lessonPlannerSearch.academic_year_id = this.academic_year_id;
      this.lessonPlannerSearch.course_id = item.id;
      this.__getLessonPlanner();
      this.dialog = true;
      let assignedCourse = this.assigned_courses[index];
      // const {id, course_id,class_id,lesson_plan_id} = this.assigned_courses[index].pivot;
      this.class_courses.id = item.academic_class_course_id;
      this.class_courses.course_id = item.id;
      this.class_courses.class_id = this.academic_class_id;
    },
    save() {
      this.$confirm({
        message: `Are you sure `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            academicClassService
              .updateClassCourse(this.class_courses.id, this.class_courses)
              .then((response) => {
                this.$snotify.success("Course added to this classroom");
                this.$emit("refresh");
                this.dialog = false;
              });
          }
        },
      });
    },
    __deleteCourse(id) {
      this.$confirm({
        message: `Are you sure you want to remove this course?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.courses = this.assigned_courses;
            this.courses.splice(id, 1);
            this.courses = this.courses.map((course) => {
              let data = {
                class_id: this.academic_class_id,
                course_id: course.id,
              };
              return data;
            });
            academicClassService
              .syncCourses(this.academic_class_id, this.courses)
              .then((response) => {
                this.$emit("refresh");
                this.$snotify.success("Course removed from this classroom");
              });
          }
        },
      });
    },
    manageAdditionalAssessment(id) {
      this.$router.push({ name: "course-assessment", params: { id: id } });
    },
    goToSummaryPage(course) {
      this.$router.push({
        name: "course-summary",
        params: { lesson_plan_id: course.lesson_plan_id },
      });
    },
  },
};
</script>

<style scoped></style>
