<template>
  <v-app>
    <v-dialog persistent v-model="dialog" max-width="800">

      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Generate fees
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>

            <v-col cols="12">
              <v-select v-model="fee.financial_transaction_head_id" label="Transaction Heading"
                        :items="financial_heads"
                        item-text="title" item-value="id"
                        outlined dense
                        @change="getPaymentType(fee.financial_transaction_head_id)"></v-select>
              <span class="text-danger" v-if="$v.fee.financial_transaction_head_id.$error"> Heading  is required</span>
            </v-col>
            <v-col cols="6">
              <v-select item-value="value" item-text="title" :items="frequency" v-model="fee.payment_frequency"
                        label="Payment Frequency" outlined dense></v-select>
              <span class="text-danger" v-if="$v.fee.payment_frequency.$error"> Payment Frequency  is required</span>
            </v-col>
            <v-col cols="6">
              <v-select item-value="value" item-text="title" :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                        @change="onTimesSelect"
                        v-model="fee.payment_to_be_made" label="Payment to be made (times)" outlined dense></v-select>
              <span class="text-danger"
                    v-if="$v.fee.payment_to_be_made.$error"> Payment to be made time is required</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" v-for="(installment, i) in installments" :key="i">
              <v-text-field v-model="installment.amount" outlined dense :label="`Installment ${i+1}`"></v-text-field>
              <span class="text-danger" v-if="$v.installments.$each[i].amount.$error">Installment {{ i + 1 }} is required</span>
            </v-col>
          </v-row>

          <v-row>
            <div class="col-12 text-right mt-4">
              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="resetForm"
              >Cancel
              </v-btn>
              <v-btn v-if="!is_generated"
                     class="text-white ml-2 btn btn-primary"
                     depressed
                     @click="generateFee"
                     :loading="isBusy"
              >Generate now
              </v-btn>

            </div>
            <div class="col-12 text-center">
              <span class="text-danger font-weight-bold font-size-lg" v-if="is_generated">Fee is already been generated</span>
            </div>
          </v-row>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {numeric, required} from "vuelidate/lib/validators";
import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";
import FinancialPaymentTypeService from "@/core/services/finance/finance-payment-type/FinancialPaymentTypeService";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";
import ProgramFeeService from "@/core/services/fee/programFee/ProgramFeeService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";

const academicClassService = new AcademicClassService();
const feeService = new StudentFeeService();
const financialTransactionHeadsService = new FinancialTransactionHeadsService();
const paymentTypeService = new FinancialPaymentTypeService();
const programFeeService = new ProgramFeeService();

export default {
  name: "Generate-Fee",
  props: ['academic_class', 'dialog'],
  validations() {
    return {
      fee: this.feeRule,
      installments: this.installmentRule,
    }
  },
  data() {
    return {
      menu2: false,
      isBusy: false,
      edit: false,
      is_generated: false,
      program_fee: {},
      financial_heads: [],
      financial_payment_types: [],
      installments: [],
      fee: {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        payment_frequency: null,
        payment_to_be_made: null
      },
    };
  },
  computed: {
    feeRule() {
      let rule = {
        financial_transaction_head_id: {required},
        payment_frequency: {required},
        payment_to_be_made: {required}
      }
      return rule;
    },
    installmentRule() {
      let rule = {
        $each: {
          amount: {required}
        }
      }
      return rule;
    },
    frequency() {
      let array = [
        {
          title: 'Monthly',
          value: 'monthly'
        },
        {
          title: 'Quarterly',
          value: 'quarterly'
        },
        {
          title: 'Semester',
          value: 'semester'
        },
        {
          title: 'Yearly',
          value: 'yearly'
        },
      ];

      return array;
    },
    userId() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    current_user() {
      return this.$store.getters.currentUser;
    }
  }, mounted() {
    this.getAllActiveTransactionHead();
  },
  methods: {
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getAllActiveTransactionHead() {
      financialTransactionHeadsService
          .allActiveByType('income')
          .then(response => {
            this.financial_heads = response.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },

    getStudentFeeByProgram() {
      let data = {academic_year_id: this.academic_class.academic_year_id, grade_id:this.academic_class.grade_id}
      programFeeService.getStudentFeeByProgram(this.academic_class.program_id, data).then(response => {
        if (!response.data.status) {
          this.$snotify.error(response.data.msg)
        } else {
          this.program_fee = response.data.fee
          this.isStudentFeeGenerated();
        }
      }).catch(error => {
        /*this.$snotify.error('Something went wrong');*/
      })
    },
    isStudentFeeGenerated(){
      feeService.isStudentFeeGenerated(this.userId, this.program_fee.program_id, this.program_fee.id).then(response=>{
        this.is_generated = response.data
      })
    },
    getPaymentType(headingId, index = null) {
      paymentTypeService
          .allActiveByTransactionHead(headingId)
          .then(response => {
            this.financial_payment_types = response.data
          })
          .catch(error => {
            // console.log(error);
          });
    },


    resetForm() {
      this.fee = {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        payment_frequency: null,
        payment_to_be_made: null
      }
      this.installments = []
      this.isBusy = false;
      this.$emit("close_dialog");
      this.$v.$reset()
    },


    onTimesSelect() {
      this.installments = []
      let times = this.fee.payment_to_be_made;
      let fee = this.program_fee.total_fee
      if (this.program_fee.is_admission_fee_applicable)
        fee = parseInt(fee) - parseInt(this.program_fee.admission_fee)
      let i = 0;
      while (i != parseInt(times)) {
        let installment = {
          amount: parseFloat(fee / times).toFixed(2),
          title: `Installment ${i+1}`
        }
        this.installments.push(installment)
        i++
      }
    },
    validateForm(){
      this.$v.fee.$touch();
      this.$v.installments.$touch();
      if (this.$v.fee.$error || this.$v.installments.$error) {
        setTimeout(() => {
          this.$v.fee.$reset();
          this.$v.installments.$reset();
        }, 3000);
        return false;
      } else {
        this.fee.installments = this.installments;
        return true;
      }
    },
    generateFee() {
      if (!this.validateForm())
        return;
      this.$confirm({
        message: `Are you sure to  want to generate fee of whole class students?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm ) {
            this.isBusy = true;
            this.fee.program_fee_id = this.program_fee.id;
            academicClassService.generateFee(this.academic_class.id, this.fee).then(response => {
              this.$snotify.success("Fee generation has been scheduled")
              this.resetForm();
            }).catch(error => {
              this.$snotify.error("Something went wrong")
            }).finally(() => {
              this.isBusy = false
            })
          }
        }
      });
    }
  }
};
</script>
