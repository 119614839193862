import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class GradePointService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;

    }

    paginate(data) {
        let url = `${this.#api}/grade-point`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getByProgram(programId) {
        let url = `${this.#api}/${programId}/grade-points`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/grade-point/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}/grade-point`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/grade-point/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/grade-point/${id}`
        return apiService.delete(url);
    }
}
