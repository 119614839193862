<template>
  <v-app>
    <v-card>
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4> Attendance report</h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <v-row>
            <v-col cols="12" md="3">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :error="$v.search.dates.$error"
                      v-model="dates"
                      label="Dates"
                      prepend-inner-icon="mdi-calendar"
                      prepend-icon=""
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.search.dates.$error">Dates is required</span>
            </v-col>
            
            <v-col cols="12" md="2">
              <v-select
                  :error="$v.search.course_id.$error"
                  v-model="search.course_id" :items="courses" label="Course" item-text="title" item-value="id" clearable
                  outlined dense></v-select>
              <span class="text-danger" v-if="$v.search.course_id.$error">Course is required</span>
            </v-col>

            <v-col cols="12" md="2">
              <v-autocomplete
                  :error="$v.search.teacher_id.$error"
                  :items="assigned_teachers"
                  item-text="full_name"
                  item-value="id"
                  clearable
                  outlined
                  label="Select teacher"
                  v-model="search.teacher_id"
                  dense
              >
              </v-autocomplete>
              <span class="text-danger" v-if="$v.search.teacher_id.$error">This  is required</span>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                outlined
                dense
                :items="statuses"
                item-text="title"
                item-value="value"
                v-model="search.status"
                clearable
                label="Status"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" class="text-right">
              <v-btn

                  @click="getSummaryReport"
                  class="btn btn-primary btn-search "
                  :loading="loading"
              >  <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <attendance-summary v-if="report && report.length>0" :report="report"></attendance-summary>
        <div class="" v-else>
          <table class="table">
            <thead>
              <tr class="px-3">
                <th class="px-3">Date</th>
                <!--                  <th>Course</th>-->
                <!--                  <th>Total Students</th>-->
                <th class="px-3">Present</th>
                <th class="px-3">Absent</th>
                <th class="px-3">Late</th>
                <th class="px-3">Half</th>
                <th class="pr-3 text-center">Action</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colspan="6" class="text-center">
                  <strong>No attendance report available</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import AttendanceService from "@/core/services/attendance/AttendanceService";
import AttendanceSummary from "@/view/component/Attendance/AttendanceSummary";

const attendanceService = new AttendanceService();
export default {
  name: "AttendanceReport",
  props: ["academic_class", "timetable", "courses", "assigned_teachers"],
  components: {
    AttendanceSummary
  },
  validations: {
    search: {
      dates: {required},
      course_id: {},
      teacher_id: {},
    }
  },
  data() {
    return {
      report: [],
      dates: [],
      menu: false,
      loading: false,
      search: {
        dates: [],
        course_id: '',
        status: 'approved',
      },
      statuses:[
        {
          title: 'Approved',
          value: 'approved',
        },
        {
          title: 'Pending',
          value: 'pending',
        },
      ]
    }
  }, mounted() {
    if (this.setDefaultDates()) {
      this.getSummaryReport();
    }
  }, methods: {
    getSummaryReport() {
      this.search.dates = this.dates;
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)

      } else {
        this.loading=true;
        this.search.class_id = this.academic_class.id;
        this.search.time_table_id = this.timetable.id;
        attendanceService.getSummaryReport(this.search)
            .then(response => {
              this.report = response.data.report
            })
            .catch((err) => {

            })
            .finally(()=>{
              this.loading = false;
            });
      }
    },
    setDefaultDates() {
      this.dates = [];
      let startDate = this.$moment(new Date).subtract(30, 'days').format('YYYY-MM-DD');
      let endDate = this.$moment(new Date).format('YYYY-MM-DD');
      this.dates.push(startDate)
      this.dates.push(endDate)
      this.search.course_id = this.courses[0].id
      return true
    }
  }
}
</script>

<style scoped>

</style>