import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class ZoomUserService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/zoom`;

    }

    all() {
        let url = `${this.#api}-all`;
        return apiService.get(url);
    }

    sync() {
        let url = `${this.#api}/sync-user`
        return apiService.get(url);
    }

    paginate(data = null, index = null) {
        let url = `${this.#api}/user`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getOnlineAttendanceList(roomId, type = null, page = null) {
        let url = `${this.#api}/attendance/${roomId}`;
        if (type == 'un-matched')
            url = `${this.#api}/attendance/${roomId}/un-matched-result`;
        if (type == 'matched')
            url = `${this.#api}/attendance/${roomId}/matched-result`;
        if (page)
            url = url + `?page=${page}`
        return apiService.get(url);
    }


    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    getByUser(id) {
        let url = `${this.#api}/${id}/get`
        return apiService.get(url);
    }

    getAllByUser(id) {
        let url = `${this.#api}/${id}/all`
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    makeStudentPresent(data) {
        let url = `${this.#api}/attendance/marked-as/present`;
        return apiService.post(url, data);
    }

    deactivateUser(userId) {
        let url = `${this.#api}/user/${userId}/deactivate`;
        return apiService.get(url);
    }
}
