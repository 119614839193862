<template>
    <v-app>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <h3>
                            Class timetable
                        </h3>
                    </div>
                </div>

                <v-row class="mt-6" v-if="edit || create">
                    <v-col>
                        <v-text-field
                                v-model="academicClassTimetable.title"
                                outlined
                                dense
                                label="Enter class timetable title"
                                clearable
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn @click="openWeekdayDialog()"   class="btn btn-primary ">
                            {{academicClassTimetable && academicClassTimetable.id ? 'Update' : 'Set'}} weekdays
                        </v-btn>
                    </v-col>
                </v-row>

            </div>
        </div>
        <v-dialog v-model="weekdayDialog" max-width="600">
            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  Class timetable
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="weekdayDialog=false">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                  <v-container>
                    <div class="row">
                      <div class="col-md-12">
                        <table class="table ">
                          <thead>
                          <tr class="px-3">
                            <th>Select days this class will run</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_sunday" label="Sunday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_monday" label="Monday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_tuesday" label="Tuesday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_wednesday" label="Wednesday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_thursday" label="Thursday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_friday" label="Friday"
                              ></v-checkbox>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <v-checkbox v-model="academicClassTimetable.is_working_saturday" label="Saturday"
                              ></v-checkbox>
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </v-container>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="btn btn-standard cancel-btn"
                      depressed
                      @click="weekdayDialog = false">
                    Cancel
                  </v-btn>
                  <v-btn
                      class="text-white ml-2 btn btn-primary"
                      depressed

                      @click="createOrUpdate()"
                  >
                    Save
                  </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>

</template>

<script>
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import UserService from "@/core/services/user/UserService";
    import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";


    const levelService = new LevelService()
    const academicYearService = new AcademicYearService();
    const gradeLevelService = new GradeLevelService();

    const programService = new ProgramService();
    const academicClassTimetable = new AcademicCLassTimetableService();
    const academicClassService = new AcademicClassService();
    const userService = new UserService();

    export default {
        name: "TimeTableCreate",
        props:['class_id','academic_class_room'],
        data() {

            return {
                edit: false,
                create: false,
                academicClassTimetable: {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: true,
                },
                subjects: [],
                weekdayDialog: false,
                academic_classes: [],
                academic_class: {
                    level_id: null,
                    program_id: null,
                    grade_id: null,
                    shift: null,
                    title: null,
                    is_active: true
                },
                class: null,
                loading: false,
                search: {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    type: 'teacher',
                    date: null,
                    timeTableId: null,
                    periodId: null,
                    limit: 80,

                },
                users: [],
                time_tables: [],
                programs: [],
                academicYears: [],
                gradesLevels: [],
                levels: [],
                levelId: null,
                page: null,
                total: null,
                perPage: null
            };
        },
        computed: {

            formatDate() {
                return this.class.start_date.split('/').join('-');
            },

            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
        mounted() {

            // this.getAllTimeTable();

            this.getTimeTable();




        },
        methods: {

            //fetch Fitlers methods
            changeAcademicYear(){
                this.getLevels();
                this.getClass();
                this.getUsers();
            },

            getAcademicYears() {
                this.loading = true;
                academicYearService.all().then(response => {
                    this.academicYears = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            getLevels() {
                this.loading = true;
                levelService.all().then(response => {
                    this.levels = response.data;
                    if (this.search.id && this.search.level_id) {
                        this.getPrograms();
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            getPrograms() {
                this.loading = true;
                programService.getAllByLevelId(this.search.level_id).then(response => {
                    this.programs = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },
            getGrades() {
                this.loading = true;
                gradeLevelService.getByProgram(this.search.program_id).then(response => {
                    this.gradesLevels = response.data;

                }).finally(() => {
                    this.loading = false;
                });
            },
            getAcademicClasses() {
                this.loading = true;
                academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
                    this.academic_classes = response.data;
                }).finally(() => {
                    this.loading = false;
                });
            },

            //end Fetch filters methods
            openWeekdayDialog() {
                this.weekdayDialog = true;
            },

            getClass() {
                this.academicClassTimetable.class_id = this.search.id;

            },

            resetTimeTable() {
                this.academicClassTimetable = {
                    class_id: null,
                    title: null,
                    is_working_sunday: null,
                    is_working_monday: null,
                    is_working_tuesday: null,
                    is_working_wednesday: null,
                    is_working_thursday: null,
                    is_working_friday: null,
                    is_working_saturday: null,
                    is_active: 1,
                }
            },
            getAssignedCourses() {
                this.academicClassTimetable.class_id = this.search.class_id;
                academicClassService.getAssignedCourses(this.search.class_id).then(response => {
                    this.subjects = response.data.courses;
                });
            },
            getUsers() {
                userService.paginate(this.search, this.page).then(response => {
                    this.users = response.data.data;
                });
            },

            getAllTimeTable() {
                this.$bus.emit('toggleLoader');
                academicClassTimetable.paginate().then((response) => {
                    this.time_tables = response.data.data;
                    this.$bus.emit('toggleLoader');
                }).catch((error) => {
                    this.$snotify.error(error)
                    this.$bus.emit('toggleLoader');
                })
            },
            getTimeTable() {
                // this.getAssignedCourses();
                this.$bus.emit('toggleLoader');
                academicClassTimetable.getByClass(this.class_id).then((response) => {
                    this.academicClassTimetable = response.data.academicClassTimetable;
                    this.time_tables.length = 0;
                    this.time_tables.push(this.academicClassTimetable);
                    this.$bus.emit('toggleLoader');
                }).catch((error) => {
                    this.$snotify.warning('Timetable not available. Add new to continue')
                    this.time_tables.length = 0;
                    this.resetTimeTable();
                    this.create = true;
                    if (this.academic_class_room){
                        this.academicClassTimetable.title = `${this.academic_class_room.unique_no ? this.academic_class_room.unique_no : ''} -${this.academic_class_room.title}-${this.academic_class_room.grade_title}-${this.academic_class_room.academic_year_title}`;
                    }
                    this.$bus.emit('toggleLoader');
                })
            },
            editTimeTable(timetable) {
                this.edit = true;
                this.academicClassTimetable = timetable;
            },
            createOrUpdate() {
                if (this.edit) {
                    this.updateTimeTable();
                } else {
                    this.createTimeTable();
                }
            },
            createTimeTable() {
                this.academicClassTimetable.class_id = this.class_id;
                academicClassTimetable.store(this.academicClassTimetable).then((response) => {
                    // this.$router.push({name: 'time-table-period', params: {time_table_id: response.data.time_table.id}})
                    this.weekdayDialog = false;

                    this.$snotify.success("Information updated");
                }).catch((err) => {
                    console.log(err)
                }).finally(()=>{
                    this.$emit("refresh");
                })
            },
            updateTimeTable() {
                this.academicClassTimetable.class_id = this.class_id;
                academicClassTimetable.update(this.academicClassTimetable.id, this.academicClassTimetable).then((response) => {
                    // this.$router.push({
                    //     name: 'time-table-period',
                    //     params: {time_table_id: this.academicClassTimetable.id}
                    // })
                    this.weekdayDialog = false;
                    this.$snotify.success("TimeTable Information updated");
                }).catch((err) => {
                    console.log(err)
                })
            },
            deleteTimeTable(id) {
                this.$confirm({
                    message: `Are You Suer?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            academicClassTimetable.delete(id).then((response) => {
                                this.$snotify.success("Information updated");
                                this.getAllTimeTable();
                            })
                        }
                    }
                });
            }
        }
    }
</script>