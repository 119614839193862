import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
const siteSettingService = new SiteSettingService();

export const SiteSettingMixin = {

    data() {
        return {
            setting: {

            },
            colors_variables: {
                login:{},
                header:{},
                side_bar:{},

            },
            login_bg_path: null,
            login_bg_color: null,
            college_color: null,
            slogan_color: null,
            logo_path: null,
        }

    },

    methods: {
        getSetting() {
            siteSettingService.show().then(response => {
                this.setting = response.data;
                if (this.setting && this.setting.id) {
                    if (this.setting.logo_path && this.setting.logo_path['real']) {
                        this.logo_path = this.setting.logo_path['real'];
                    }
                }
            });
        },

    },
}