<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <v-card>

          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Pending approval for additional classes taken by teachers</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3"> Course</th>
                  <th class="px-3"> Class taken</th>
                  <th class="px-3">Teacher</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(attendance,index) in pending_attendances" :key="index">
                  <td class="px-3">
                    {{ attendance.course_title }}
                  </td>
                  <td class="px-3">
                    {{ attendance.total_class_taken }}
                  </td>
                  <td class="px-3">
                    {{ attendance.teacher_name }}
                  </td>
                  <td class="pr-0 text-left">
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item" >
                          <a class="navi-link" @click="editAttendance(attendance)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                            <span class="navi-text">Edit</span>
                          </a>
                        </b-dropdown-text>

                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                    <!--                  <span @click="editAttendance(attendance)"><i class="fas fa-edit"></i></span>-->
                  </td>
                </tr>
                <tr v-if="pending_attendances.length == 0">
                  <td  class="px-3 text-center" colspan="4">
                    <strong>Data not available</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
          <v-dialog v-model="dialog" max-width="500" persistent>

            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  Approve Attendance
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="dialog=false">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field outline dense type="number" min="0" label="No of class Taken"
                                  v-model="attendance.total_class_taken"></v-text-field>
                    <span class="text-danger" v-if="$v.attendance.total_class_taken.$error">Total class taken cannot be null or negative</span>
                  </v-col>
                </v-row>
                <v-row class="row">
                  <div class="col-12 text-right">
                    <v-btn
                        class="btn btn-standard cancel-btn"
                        depressed
                        @click="dialog = false"
                    >Cancel
                    </v-btn>
                    <v-btn
                        class="text-white ml-2 btn btn-primary"
                        depressed
                        @click="approveAttendance"
                        :loading="isBusy"
                    >Save
                    </v-btn>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </div>
    </div>
  </v-app>

</template>

<script>

import AttendanceService from "@/core/services/attendance/AttendanceService";
import {required, minValue} from "vuelidate/lib/validators";
import {AttendanceMixin} from "@/mixins/AttendanceMixin";

const attendanceService = new AttendanceService();
export default {
  name: "AttendanceReport",
  mixins: [AttendanceMixin],
  props:['academic_class'],
  validations: {
    attendance: {
      total_class_taken: {required, minValue:minValue(1)}
    },
  },
  data() {
    return {
      report: [],
      dialog: false,
      isBusy: false,
      pending_attendances: [],
      attendance: {
        total_class_taken: null
      },
    }
  }, mounted() {
    this.gePendingAttendance();
  },
  computed: {},
  methods: {
    gePendingAttendance() {
      this.$bus.emit('toggleLoader');
      let classId = this.academic_class.id;
      let data ={class_id:classId}
      attendanceService.gePendingAttendance(data).then(response => {
        this.pending_attendances = response.data.attendance;
        // this.$bus.emit('toggleLoader');
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(this.$bus.emit('toggleLoader'))
    },
    editAttendance(attendance) {
      this.attendance = attendance;
      this.dialog = true;
    },
    approveAttendance() {
      this.$v.attendance.$touch();
      if (this.$v.attendance.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      } else {
        attendanceService.update(this.attendance.id, {
          total_class_taken: this.attendance.total_class_taken,
          approval_required: 0
        }).then(res => {
          this.$snotify.success('Information updated')
          this.dialog = false
          this.gePendingAttendance();
        }).catch(error => {
          this.$snotify.error("Something went wrong")
        }).finally(this.$bus.emit('toggleLoader'))
      }
    }
  }
}
</script>

<style>
.form-control {
  border: none !important;
}

.attenedance table {
  border-collapse: collapse;
}

.attenedance table, th, td {
  /*border: 1px solid black;*/
}

.table th, .table thead th, .table td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table {
  background: #fff;
}

.v-input--selection-controls__input + .v-label {
  margin-bottom: 0 !important;
}

.v-toolbar__title {
  padding-left: 10px;
}

.table .v-input .v-messages,
.v-input--radio-group .v-messages {
  display: none;
}

.v-toolbar__content .v-input--radio-group--row {
  margin-left: 10px;
}

.v-toolbar__content .v-input--radio-group--row + .v-input--radio-group--row {
  margin-left: 0px;
}

.v-toolbar__content .v-input--radio-group--row .v-input__slot {
  margin-bottom: 0;
}

.v-input__slot {
  background: transparent !important;

}

.table-bordered, .table-bordered th, .table-bordered td {
  border-color: #000 !important;
}
</style>
