<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title>
                    {{`${edit?'Update':'Add'}`}} lesson Plan
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="lesson_plan.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.lesson_plan.title.$error">This information is required</span>

                        </v-col>
                        <div class="col-12">
                            <ckeditor v-model="lesson_plan.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.lesson_plan.description.$error">This information is required</span>
                        </div>

                        <div class="col-3">
                            <v-switch
                                    v-model="lesson_plan.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard  ">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";
    import {LessonPlannerMixin} from "@/mixins/LessonPlannerMixin";
    import CoursesService from "@/core/services/courses/CoursesService";

    const courseService = new CoursesService();
    const lessonPlannerService = new LessonPlannerService();

    export default {
        name: 'lesson_plan',
        props:['lesson_plan_obj'],
        mixins: [LessonPlannerMixin],
        validations: {
            lesson_plan: {
                title: {required},
                description: {required},
            }
        },
        data() {
            return {

                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                lesson_plan: {
                    title:'',
                    description:'',
                },
                courseSearch: {
                    program_id: "",
                    grade_id: "",
                    title: "",
                    subject_code: "",
                },
              editorConfig: {
                versionCheck: false,
              }
            };
        },
        computed: {},
        mounted() {


        },
        methods: {
            getCoursesAndGrades(){
                this.getCourses();
                this.getGrades();
            },
            getSearchedCourses() {
                this.getClass();
                this.courseSearch.grade_id = this.lesson_plan.grade_id;
                this.courseSearch.program_id = this.lesson_plan.program_id;
                this.isBusy = true;
                this.$bus.emit('toggleLoader',);
                courseService.paginate(this.courseSearch,this.page).then(response => {
                    this.courses = response.data.data;
                    this.isBusy = false;
                    this.$bus.emit('toggleLoader');
                });
            },
            showModal(lessonPlan,id = null) {
                this.__reset();
                if (lessonPlan){
                    this.lesson_plan = lessonPlan;
                }
                if (id) {
                    this.edit = true
                    this.__get(id)
                } else
                    this.edit = false;
                this.dialog = true;
            },
            __reset() {
                this.lesson_plan = {
                    title: null,
                    course_id: 1,
                    is_active: true
                }
            },
            hideModal() {
                this.dialog = false;
            },
            __get(id) {
                lessonPlannerService.show(id).then(response => {
                    this.lesson_plan = response.data.lesson
                })
            },

            createOrUpdate() {
                if (this.syllabus) {
                    this.lesson_plan.course_id = this.syllabus.course_id;
                }
                this.$v.lesson_plan.$touch();
                if (this.$v.lesson_plan.$error) {
                    setTimeout(() => {
                        this.$v.lesson_plan.$reset();
                    }, 3000);
                } else {
                    let fd = this.lesson_plan;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                lessonPlannerService
                    .update(this.lesson_plan.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            __create(fd) {
                this.isBusy = true;
                lessonPlannerService
                    .store(fd)
                    .then((response) => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.lesson_plan.$reset();
                this.edit = false;
                this.lesson_plan = {
                    title: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh_lesson_plan");
            }
        }
    };
</script>
