export const AttendanceMixin = {
    data() {
        return {
            days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            monthNames: [
                {
                    name: "January",
                    value: 1,
                },
                {
                    name: "February",
                    value: 2,
                },
                {
                    name: "March",
                    value: 3,
                },
                {
                    name: "April",
                    value: 4,
                },
                {
                    name: "May",
                    value: 5,
                },
                {
                    name: "June",
                    value: 6,
                }, {
                    name: "July",
                    value: 7,
                }, {
                    name: "August",
                    value: 8,
                }, {
                    name: "September",
                    value: 9,
                },
                {
                    name: "October",
                    value: 10,
                }, {
                    name: "November",
                    value: 11,
                }, {
                    name: "December",
                    value: 12,
                },
            ],
        }
    },

    methods: {
        getColor(value) {
            switch (value) {
                case 'n' :
                    return 'lightgray';
                    break;
                case 'n/a' :
                    return 'gray';
                    break;
                case 'a' :
                    return 'red';
                    break;
                case 'p' :
                    return 'green';
                    break;
                case 'ph' :
                    return 'orange';
                    break;
                case 'pl' :
                    return 'orange';
                    break;
                default:
                    return;
                    'white';

            }
        },
        getAttendanceDay(value) {
            switch (value) {
                case 'n' :
                    return ' ';
                    break;
                case 'a' :
                    return 'A';
                    break;
                case 'p' :
                    return 'P';
                    break;
                case 'ph' :
                    return 'H';
                    break;
                case 'pl' :
                    return 'L';
                    break;
                default:
                    return;
                    '';

            }
        },
        setAttendance(index) {
            switch (this.users[index].attendance) {
                case 'p' :
                    this.users[index].present = 1;
                    this.users[index].late = 0;
                    this.users[index].half_day = 0;
                    break;
                case 'a' :
                    this.users[index].present = 0;
                    this.users[index].late = 0;
                    this.users[index].half_day = 0;
                    break;
                case 'pl' :
                    this.users[index].present = 1;
                    this.users[index].late = 1;
                    this.users[index].half_day = 0;
                    break;
                case 'ph' :
                    this.users[index].present = 1;
                    this.users[index].late = 0;
                    this.users[index].half_day = 1;
                    break;
                default:
                    this.users[index].present = 0;
                    this.users[index].late = 0;
                    this.users[index].half_day = 0;
                    break;
            }
        },
        setAllAttendance() {
            let present = null;
            let half_day = null;
            let late = null;
            switch (this.attendance) {
                case 'p' :
                    present = 1;
                    break;
                case 'a' :
                    present = 0;
                    break;
                case 'pl' :
                    present = 1;
                    late = 1;
                    break;
                case 'ph' :
                    present = 1;
                    half_day = 1;
                    break;
                default:
                    present = 0;
                    break;
            }
            this.users.forEach((user) => {
                user.attendance = this.attendance;
                user.present = present;
                user.half_day = half_day;
                user.late = late;
            })
        },
        setAllAttendanceCheckType() {
            this.users.forEach((user) => {
                user.day_attendance_type = this.day_attendance_type;
            })
        },
    },
}