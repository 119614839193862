<template>
    <v-app>
      <div class="">
        <table class="table">
          <thead>
          <tr class="px-3">
            <th class="px-3">Title</th>
            <th class="px-3">Date</th>
            <th class="px-3">Exam status</th>
            <th class="px-3">Result status</th>
            <th  class="pr-3 text-center">Action</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(exam,index) in assigned_exams" :key="index">
              <td style="max-width: 150px !important; white-space: pre-wrap;" class="px-3">
                <a @click="examSummary(exam)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                  {{ exam.title }}
                </a> &nbsp;&nbsp;
                <i class="fas fa-circle"
                   :class="exam.is_active ? 'dot-active':'dot-inactive'"></i>
              </td>
              <td class="px-3">
                <span>
                   {{ exam.start_date }} to {{ exam.end_date ? exam.end_date : 'N/A' }}
                </span>
                <div class="text-secondary mt-2">
                  <span class="badge badge-primary">{{ exam.type.toUpperCase() }}</span>
                </div>
              </td>
              <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                 <span :class="exam.is_active ? 'badge badge-success':'badge badge-danger'">
                   {{exam.is_active ? 'Active' : 'Inactive' }}
                 </span>
              </td>
              <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                <span class="badge" :class="exam.result_status ? exam.result_status =='published'? 'badge-success':'badge-danger' :'badge-danger'">
                        {{exam.result_status ? exam.result_status.toUpperCase() : "Pending" }}
                </span>
              </td>
              <td class="pr-0 text-center">
                <template>
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item" >
                        <a class="navi-link" @click="download(exam.id)">
                                    <span class="navi-icon">
                                        <i class="fas fa-download"></i>
                                    </span>
                          <span class="navi-text">Download timetable</span>
                        </a>
                      </b-dropdown-text>

                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </template>
              </td>
            </tr>
            <tr v-if="assigned_exams.length ==0">
              <td colspan="5" class="text-center">
                <span>Data not available for this class.</span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
<!--        <v-data-table
                :loading="isLoading"
                :headers="headers"
                :items-per-page="20"
                :items="assigned_exams"
        >

            <template v-slot:item.title="{ item }">

                <router-link :to="{name:'exams-summary', params:{'exam_id':item.id}}">
                    {{ item.title }}
                </router-link >
                <i class="fas fa-circle"
                   :class="item.is_active?'dot-active':'dot-inactive'"></i>


            </template>
            <template v-slot:item.type="{ item }">
                {{ item.start_date }} to {{ item.end_date ? item.end_date : 'N/A' }}<br/>
                <span class="badge badge-primary">{{ item.type.toUpperCase() }}</span>


            </template>
            <template v-slot:item.is_active="{ item }">
                                                <span
                                                        :class="item.is_active?'badge badge-success':'badge badge-danger'">{{
                                                    item.is_active ? 'Active' : 'Inactive'
                                                  }}</span>
            </template>
            <template v-slot:item.exam_status="{ item }">
                                                      <span class="badge"
                                                            :class="item.exam_status ? item.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'"
                                                      >{{
                                                  item.exam_status ? item.exam_status.toUpperCase() : 'Not Completed'
                                                }}</span>
            </template>
            <template v-slot:item.result_status="{ item }">
                                                     <span class="badge"
                                                           :class="item.result_status ? item.result_status =='published'?'badge-success':'badge-danger' :'badge-danger'"
                                                     >{{
                                                 item.result_status ? item.result_status.toUpperCase() : "Pending"
                                               }}</span>
            </template>
            <template v-slot:item.duration="{ item }">
                <a href="" @click.prevent="download(item.id)">
                    <span class="badge badge-success"> Download TimeTable</span>

                </a>
            </template>


        </v-data-table>-->
    </v-app>
</template>

<script>
    import { API_URL} from "@/core/config";

    export default {
        name: "class-summary-exams",
        props: ['assigned_exams', 'isLoading'],
        data() {
            return {
                headers: [
                    {text: 'Title', value: 'title', filterable: true, sortable: true},
                    {text: 'Date', value: 'type', filterable: true, sortable: true},
                    {text: 'Exam Status', value: 'exam_status', filterable: true, sortable: true},
                    {text: 'Result Status', value: 'result_status', filterable: true, sortable: true},
                    {text: 'Action', value: 'duration', filterable: true, sortable: true},

                ],
            }
        },
        methods: {
          examSummary(exam){
            this.$router.push({
              name:'exams-summary',
              params:{exam_id:exam.id}
            })

          },
            download(id) {
                    let url = API_URL + "timetable-pdf"
                        url = url + "?exam=" + id
                        window.open(url)
                return;
            }
        },
    }
</script>

<style scoped>

</style>