import Apiservice from '@/core/services/api.service';
import { API_URL } from '../../../../common/config'
const apiService = Apiservice;


export default class ProgramFeeService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user`;

      }
      paginate(programId, data={}) {
            let url = `${this.#api}/${programId}/program-fee`;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }

      update(programId,programFeeId, data) {
            let url = `${this.#api}/${programId}/program-fee/${programFeeId}`
            return apiService.put(url, data);

      }

      store(programId, data) {
            let url = `${this.#api}/${programId}/program-fee`
            return apiService.post(url, data);

      }

      show(programId, programFeeId) {
            let url = `${this.#api}/${programId}/program-fee/${programFeeId}`
            return apiService.get(url);
      }

      delete(programId, programFeeId) {
            let url = `${this.#api}/${programId}/program-fee/${programFeeId}`
            return apiService.delete(url);
      }

      getByAcademicYearAndProgramId(academicYearId, programId){
            let url = `${this.#api}/program/${programId}/academic_year/${academicYearId}/program-fee`
            return apiService.get(url);
      }

      getStudentProgramFee(userId){
            let url = `${this.#api}/${userId}/student-program-fee`
            return apiService.get(url);
      }

      getStudentFeeByProgram(programId, data){
            let url = `${this.#api}/program/${programId}/fee`
            return apiService.post(url, data);
      }

      enableIncrementFee(programId, id){
            let url = `${this.#api}/program/${programId}/program-fee/${id}/enable-increment-fee`;
            return apiService.post(url);
      }

      disableIncrementFee(programId, id){
            let url = `${this.#api}/program/${programId}/program-fee/${id}/disable-increment-fee`;
            return apiService.post(url);
      }
}
