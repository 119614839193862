<template>
  <v-app>
    <v-row v-if="isLoading">
      <v-col cols="12">
        <v-skeleton-loader v-bind="attrs" type="article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="3" md="3" lg="2">
        <v-card flat>
          <v-card-text>
            <!--            <small class="badge badge-primary" v-if="academic_class.is_confirmed">Confirmed</small> <span
              class="badge badge-warning" v-else>Not confirmed</span>-->

            <div></div>
            <div class="d-flex align-items-center">
              <div>
                <span class="font-weight-bolder font-size-h5 text-dark-75">
                  Class summary - {{ academic_class ? academic_class.title : "" }}
                </span>
                <span
                  class="ml-2 badge badge-primary text-right"
                  v-if="academic_class && academic_class.is_confirmed"
                  >Confirmed</span
                >
                <span class="ml-2 badge badge-warning" v-else>Not confirmed</span>
              </div>
            </div>
            <hr />
            <div class="text-center" v-if="academic_class">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Name</span>
                <span>{{academic_class && academic_class.title ? academic_class.title : "N/A" }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Identifier</span>
                <span>{{academic_class && academic_class.unique_no ? academic_class.unique_no : "" }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Program</span>
                <span v-if="academic_class.program && academic_class.program.title">
                  {{academic_class && academic_class.program && academic_class.program.title ? academic_class.program.title : "N/A" }}
                </span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Semester/year</span>
                <span>{{
                  academic_class && academic_class.grade && academic_class.grade.title
                    ? academic_class.grade.title
                    : "N/A"
                }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Batch</span>
                <span>{{
                  academic_class &&
                  academic_class.academic_year &&
                  academic_class.academic_year.title
                    ? academic_class.academic_year.title
                    : "N/A"
                }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Shift</span>
                <span>{{
                  academic_class.shift ? academic_class.shift : "N/A"
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="academic_class.rooms"
              >
                <span class="font-weight-bold mr-2">Room no.</span>
                <span>{{
                  academic_class.rooms.room_number
                    ? academic_class.rooms.room_number
                    : "N/A"
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="setting.enable_level && academic_class.rooms"
              >
                <span class="font-weight-bold mr-2">Level no.</span>
                <span>{{ academic_class.rooms.level_number }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="setting.enable_level && academic_class.rooms"
              >
                <span class="font-weight-bold mr-2">Building </span>
                <strong class="heading">{{
                  academic_class.rooms.building_number
                }}</strong>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Running mode</span>
                <span>{{
                  academic_class.running_mode ? academic_class.running_mode : "N/A"
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="academic_class.running_mode === 'online'"
              >
                <span class="font-weight-bold mr-2">Online class type</span>
                <span>{{ academic_class.online_class_type }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
                v-if="academic_class.running_mode === 'online'"
              >
                <span class="font-weight-bold mr-2">Online class</span>
                <a
                  class="btn btn-primary"
                  target="_blank"
                  :href="academic_class.online_class_url"
                >
                  Start Class</a
                >
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Start date</span>
                <span>{{ academic_class.formated_start_date }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">End date</span>
                <span>{{ academic_class.formated_end_date }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Total students</span>
                <span>{{
                  assigned_students && assigned_students.length > 0
                    ? assigned_students.length
                    : 0
                }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
                <span class="font-weight-bold mr-2">Total teachers</span>
                <span>{{ assigned_teachers.length || 0 }}</span>
              </div>
            </div>
            <v-tabs class="menu-parent" vertical v-model="model">
              <v-tab v-if="checkIsAccessible('academic-class-course', 'list')">
                <v-icon left> fas fa-book-open </v-icon>
                Courses
              </v-tab>
              <v-tab v-if="checkIsAccessible('academic-class-student', 'list')">
                <v-icon left> fa-solid fa-users </v-icon>
                Students
              </v-tab>
              <v-tab v-if="checkIsAccessible('academic-class-teacher', 'list')">
                <v-icon left> fas fa-user-tie </v-icon>
                Teachers
              </v-tab>
              <v-tab v-if="checkIsAccessible('academic-class-time-table', 'list')">
                <v-icon left> fas fa-clock </v-icon>
                Timetable
              </v-tab>
              <v-tab v-if="checkIsAccessible('academic-class-attendance', 'list')">
                <v-icon left> far fa-calendar-check </v-icon>
                Class attendance
              </v-tab>
              <v-tab v-if="checkIsAccessible('academic-class-online-class-room', 'list')">
                <v-icon left> fa-solid fa-globe </v-icon>
                Online classes
              </v-tab>
<!--              <v-tab v-if="checkIsAccessible('academic-class-exam', 'list')">-->
<!--                <v-icon left> far fa-clipboard </v-icon>-->
<!--                Exams-->
<!--              </v-tab>-->

              <v-tab v-if="checkIsAccessible('academic-class', 'show')">
                <v-icon left> fa fa-cog </v-icon>
                Settings
              </v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9" md="9" lg="10" class="mt-2 bg-gray">
        <v-tabs-items v-model="model">
          <v-tab-item v-if="checkIsAccessible('academic-class-course', 'list')">
            <div class="row">
              <div class="col-12">
                <v-card flat>
                  <div class="card-header">
                    <div class="card-title pt-3 px-3 d-flex justify-content-between">
                      <div class="breadcrumb-left">
                        <h4>Courses</h4>
                      </div>
                      <div class="breadcrumb-right">
                        <div class="card-toolbar">
                          <v-btn
                            @click="assignCourse(academic_class)"
                            class="mt-4 btn btn-primary"
                            style="color: #fff"
                          >
                            <v-icon small elevation="2" outlined>fas fa-plus</v-icon
                            >&nbsp; Assign course
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-body">
                    <courses
                      :assigned_courses="assigned_courses"
                      :isLoading="isLoading"
                      :academic_class_obj="academic_class"
                      :academic_year_id="
                        academic_class ? academic_class.academic_year_id : null
                      "
                      @refresh="getAssignedCourses"
                    ></courses>
                  </div>
                </v-card>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="checkIsAccessible('academic-class-student', 'list')">
            <v-card flat>
              <div class="card-header">
                <div class="card-title pt-3 px-3 d-flex justify-content-between">
                  <div class="breadcrumb-left">
                    <h4>Students</h4>
                  </div>
                  <div class="breadcrumb-right">
                    <div class="card-toolbar">
                      <v-btn
                        v-if="checkIsAccessible('academic-class-student', 'create')"
                        @click.prevent="assignStudents(academic_class)"
                        class="mt-4 btn btn-primary"
                        style="color: #fff"
                      >
                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                        Assign student
                      </v-btn>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4 ml-2"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('academic-class', 'welcome-mail')"
                          >
                            <a
                              class="ml-2 navi-link"
                              @click.prevent="sendWelcomeMail(academic_class)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-paper-plane"></i>
                              </span>
                              <span class="navi-text">Send welcome email</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              @click.prevent="downloadStudentImportDialog(academic_class)"
                              class="ml-2 navi-link"
                              v-if="
                                checkIsAccessible('academic-class', 'download-import-csv')
                              "
                            >
                              <span class="navi-icon">
                                <i class="fa fa-file-excel"></i>
                              </span>
                              <span class="navi-text">Download import CSV</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('import-export', 'import-student')">
                            <a @click="import_excel = !import_excel" class="ml-2 navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-excel"></i>
                          </span>
                              <span class="navi-text">Import students</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <v-card-title>
                <div class="col-md-6 text-left">
                  <h4>Students in this classroom</h4>
                  <small><a href="#" @click.prevent="downloadStudentImportDialog(academic_class)"
                            v-if="checkIsAccessible('academic-class','download-import-csv')"
                            class="font-small">
                    Download Import CSV
                    <i class="fa fa-file-excel"></i>
                  </a></small>
                </div>
                <div class="col-md-6 text-right mb-3">
                  <button @click.prevent="assignStudents(academic_class)"
                          v-if="checkIsAccessible('academic-class-student', 'create')"
                          class="btn btn-primary ml-2">
                    Add Student
                  </button>
                  <button @click.prevent="sendWelcomeMail(academic_class)"
                          v-if="checkIsAccessible('academic-class', 'welcome-mail')"
                          class="btn btn-primary ml-2">
                    Send Welcome Mail
                  </button>
                  <button @click.prevent="removeStudentFromClass(academic_class)"
                          v-if="checked_students && checked_students.length"
                          class="btn btn-danger ml-2">
                    Remove Student
                  </button>
                  <button @click.prevent="assignSubject"
                          v-if="checked_students && checked_students.length"
                          class="btn btn-primary ml-2">
                    Assign Subjects
                  </button>


                </div>
              </v-card-title>-->
              <v-card-text>
                <div class="search-wrapper border-outline mb-5  py-5 d-sm-flex justify-content-start" v-if="import_excel">
                  <div class="row px-4">
                    <div class="col-12">
                      <h4>Upload file to import students</h4>
                    </div>
                    <div class="col-8">
                      <v-file-input outlined dense v-model="excel_file" accept=".xlsx, .xls, .csv"
                                    label="File input"></v-file-input>
                    </div>
                    <div class="col-4">
                      <v-btn @click="importStudent" :loading="importUserLoading" :disabled="!excel_file"
                             class="btn btn-primary ml-2 text-white">Import
                      </v-btn>
                      <v-btn @click="cancelStudentImport"
                             class="btn btn-primary ml-2 text-white">Cancel
                      </v-btn>
                    </div>
                    <div class="col-12 text-xs">
                      <b>Note</b>
                      <ul class="ml-3">
                        <li> New student import requires following field mandatory on the excel file
                          <code> First name, Middle Name, Last name, Academic Year, Program, Grade, Class</code>
                          Any row with missing data in excel will not be imported</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <v-row v-if="import_error.length > 0">
                  <div class="col-12">
                    <b-list-group>
                      <b-list-group-item variant="danger">
                        <div class="row" v-for="(error, index) of import_error" :key="index">
                          <div class="col-4">
                            <strong>{{ error.user_name }}</strong>
                            <br>
                            <strong>{{ error.email }}</strong>
                          </div>
                          <div class="col-8">
                            <ul class="">
                              <li v-for="(item, i) of error.issues.split(',')" :key="i" v-show="item">
                                <small>{{ item }}</small>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </v-row>
                <div class="col-md-12 text-left">
                  <v-btn
                      v-if="checked_students && checked_students.length"
                      @click.prevent="removeStudentFromClass(academic_class)"
                      class="mr-3"
                  >
                    <v-icon small elevation="2" outlined>fas fa-xmark</v-icon>&nbsp;
                    Remove from class
                  </v-btn>
                  <v-btn
                      v-if="checked_students && checked_students.length"
                      @click.prevent="assignSubject()"
                  >
                    <v-icon small elevation="2" outlined>fas fa-book</v-icon>&nbsp;
                    Manage course
                  </v-btn>
                </div>
                <students
                  ref="students-list"
                  :isLoading="isLoading"
                  :academic_class="academic_class"
                  :assigned_students="assigned_students"
                  :assigned_courses="assigned_courses"
                  @refresh_student_list="getAssignedStudents"
                  @on-students-selected="onStudentsSelected"
                ></students>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="checkIsAccessible('academic-class-teacher', 'list')">
            <div class="row">
              <div class="col-12">
                <v-card flat>
                  <div class="card-header">
                    <div class="card-title pt-3 px-3 d-flex justify-content-between">
                      <div class="breadcrumb-left">
                        <h4>Teachers</h4>
                      </div>
                      <div
                        class="breadcrumb-right"
                        v-if="checkIsAccessible('academic-class-teacher', 'create')"
                      >
                        <div class="card-toolbar">
                          <v-btn
                            @click.prevent="assignTeacher(academic_class)"
                            class="mt-4 btn btn-primary"
                            style="color: #fff"
                          >
                            <v-icon small elevation="2" outlined>fas fa-plus</v-icon
                            >&nbsp; Assign teacher
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <teachers
                      :isLoading="isLoading"
                      :assigned_teachers="assigned_teachers"
                      @refresh="getAssignedTeachers"
                    ></teachers>
                  </div>
                </v-card>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item v-if="checkIsAccessible('academic-class-time-table', 'list')">
            <time-table
              :class_id="classId"
              :academicClassTimetable="academicClassTimetable"
              :academic_class_room="academic_class"
              :academicClassTimetablePeriod="academicClassTimetablePeriod"
              :classTimetablePeriods="academicClassTimetablePeriods"
              @refresh-timetable="refreshTimeTable"
            ></time-table>
          </v-tab-item>
          <v-tab-item v-if="checkIsAccessible('academic-class-attendance', 'list')">
            <!--            <pre>{{academicClassTimetable.id}}</pre>-->
            <!--            <pre>{{academic_class}}</pre>-->
            <!--            <pre>{{academicClassTimetable}}</pre>-->
            <!--            <pre>{{assigned_courses}}</pre>-->
            <attendance
              v-if="academicClassTimetable.id && assigned_teachers"
              :academic_class="academic_class"
              :timetable="academicClassTimetable"
              :courses="assigned_courses"
              :assigned_teachers="teachers"
            ></attendance>
            <v-row v-else>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <h5 class="mt-5 text-center">
                      Please setup timetable first to view attendance
                    </h5>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-if="checkIsAccessible('academic-class-online-class-room', 'list')"
          >
            <online-class-room></online-class-room>
          </v-tab-item>
<!--          <v-tab-item v-if="checkIsAccessible('academic-class-exam', 'list')">-->
<!--            <div class="row">-->
<!--              <div class="col-12">-->
<!--                <v-card flat>-->
<!--                  <div class="card-header">-->
<!--                    <div class="card-title pt-3 px-3 d-flex justify-content-between">-->
<!--                      <div class="breadcrumb-left">-->
<!--                        <h4>Exams</h4>-->
<!--                      </div>-->
<!--                      <div class="breadcrumb-right">-->
<!--                        <div class="card-toolbar">-->
<!--                          <v-btn-->
<!--                            @click="openExamDialog(academic_class)"-->
<!--                            class="mt-4 btn btn-primary"-->
<!--                            style="color: #fff"-->
<!--                          >-->
<!--                            <v-icon small elevation="2" outlined>fas fa-plus</v-icon-->
<!--                            >&nbsp; Add exam-->
<!--                          </v-btn>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="card-body">-->
<!--                    <exams-->
<!--                      :isLoading="isLoading"-->
<!--                      :assigned_exams="assigned_exams"-->
<!--                    ></exams>-->
<!--                  </div>-->
<!--                </v-card>-->
<!--              </div>-->
<!--            </div>-->
<!--          </v-tab-item>-->
          <v-tab-item v-if="checkIsAccessible('academic-class', 'show')">
            <v-card flat>
              <v-card-title> </v-card-title>
              <v-card-text>
                <update-class-room
                  :academicClass="academic_class"
                  @refresh="getClass"
                ></update-class-room>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <exam-create-and-update
      ref="exam"
      @refresh="getAssignedExams"
    ></exam-create-and-update>
    <assign-courses
      ref="assignCourse"
      :academic_class="academic_class ? academic_class : null"
      @refresh="getAssignedCourses"
    ></assign-courses>
    <assign-students
      ref="assignStudents"
      @refresh="getAssignedStudents"
    ></assign-students>
    <assign-teacher ref="assignTeacher" @refresh="getAssignedTeachers"></assign-teacher>

    <v-dialog v-model="assign_course_dialog" max-width="500">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Assign course
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                assign_course_dialog = false;
                selected_courses = [];
              "
            >
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="selected_courses"
                  outlined
                  dense
                  :items="assigned_courses"
                  item-text="display_text_with_subject_code"
                  item-value="id"
                  label="Courses"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              assign_course_dialog = false;
              selected_courses = [];
            "
            class="btn btn-standard cancel-btn"
            depressed
            >Cancel
          </v-btn>
          <v-btn
            @click="assignCoursesToStudents"
            class="text-white ml-2 btn btn-primary"
            depressed
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="studentImportDialog" max-width="500">
      <v-card>
        <v-card-title class="headline"
          >How many students you would like to import?</v-card-title
        >
        <v-card-text>
          <div class="col-12">
            <v-text-field
              outlined
              dense
              v-model="noOfStudentsToImport"
              label="No. of Students"
              max="300"
              type="number"
              hint="Maximum 300 Allowed"
              placeholder="No. of Students"
            ></v-text-field>
            <span class="text-danger" v-if="$v.noOfStudentsToImport.$error"
              >Please enter value between 0 and 300.</span
            >
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard" text @click="hideModal()"> Close </v-btn>
          <v-btn
            class="text-white btn btn-primary"
            text
            @click="downloadStudentImportTemplate(academic_class)"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { required, maxValue, requiredUnless } from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
import UserService from "@/core/services/user/UserService";
import AcademicCLassTimetablePeriodService from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";
import AttendanceService from "@/core/services/attendance/AttendanceService";
import ExamService from "@/core/services/exam/ExamService";
import { API_URL } from "@/core/config";
import { SiteSettingMixin } from "@/mixins/SiteSettingMixin";
import AssignCourses from "./AssignCourses";
import AssignStudents from "./AssignStudents";
import AssignTeacher from "./AssignTeacher";
import ExamCreateAndUpdate from "../exams/CreateAndUpdate";
import Courses from "./summary/Courses";
import Students from "./summary/Students";
import Teachers from "./summary/Teachers";
import TimeTable from "./summary/TimeTable";
import Attendance from "./summary/Attendance";
import Exams from "./summary/Exams";
import AttendeeeService from "@/core/services/attendance/AttendeeService";
import OnlineClassRoom from "@/view/pages/academic-class/summary/OnlineClassRoom";
import StudentGradeService from "@/core/services/user/student/StudentGrade/StudentGradeService";

const gradeService = new StudentGradeService();
const attendance = new AttendanceService();
const academicClassService = new AcademicClassService();
const academicClassTimetable = new AcademicCLassTimetableService();
const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
const examService = new ExamService();
const attendeeService = new AttendeeeService();
const userService = new UserService();

import UpdateClassRoom from "./UpdateClassRoom";

export default {
  name: "ClassroomSummary",
  mixins: [SiteSettingMixin],

  validations: {
    noOfStudentsToImport: { required, maxValue: maxValue(300) },
  },
  components: {
    ExamCreateAndUpdate,
    AssignCourses,
    AssignStudents,
    AssignTeacher,
    Courses,
    Students,
    Teachers,
    TimeTable,
    Attendance,
    Exams,
    UpdateClassRoom,
    OnlineClassRoom,
  },
  data() {
    return {
      import_excel: false,
      importUserLoading: false,
      excel_file: null,
      import_error: [],
      studentImportDialog: false,
      noOfStudentsToImport: 1,
      attrs: false,
      model: 0,
      pageTitle: "Class Summary",
      pageTips: "Class Summary",
      isLoading: true,
      assign_course_dialog: false,
      show_period: true,
      menu1: false,
      academic_class: null,
      assigned_students: [],
      selected_courses: [],
      checked_students: [],
      assigned_teachers: [],
      assigned_courses: [],
      attendanceReports: [],
      attendanceStudents: [],
      teachers: [],

      academicClassTimetablePeriods: [],
      assigned_exams: [],
      attendee: {},
      attendance: null,
      day_attendance_type: null,
      attendance_mode: "take",
      search: {
        academic_class_id: null,
        day_attendance: false,
        time_table_id: "",
        timetable_period_id: "",
        course_id: "",
        level_id: null,
        program_id: null,
        academic_year_id: null,
        year: 2021,
        month: 8,
        grade_id: null,
      },
      academicClassTimetable: {
        class_id: null,
        title: null,
        is_working_sunday: null,
        is_working_monday: null,
        is_working_tuesday: null,
        is_working_wednesday: null,
        is_working_thursday: null,
        is_working_friday: null,
        is_working_saturday: null,
        is_active: 0,
      },
      academicClassTimetablePeriod: {
        timetable_id: null,
        day: null,
        subject_id: null,
        subject_name: null,
        teacher_id: null,
        teacher_name: null,
        start_time: null,
        end_time: null,
        is_break: 0,
      },
    };
  },
  computed: {
    routeTab() {
      return {
        title: this.pageTitle,
        tips: `Classroom Summary for ${this.pageTitle}`,
      };
    },
    classId() {
      return this.$route.params.classId;
    },
    search_rule() {
      let rule = { date: { required } };
      if (!this.show_period) rule.course_id = { required };
      else rule.timetable_period_id = { required };
      return rule;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getClass();
      this.getSetting();
    },
    hideModal() {
      this.studentImportDialog = false;
    },
    assignCourse(academicClass) {
      this.$refs["assignCourse"].showModal(academicClass);
    },
    assignStudents(academicClass) {
      this.$refs["assignStudents"].showModal(academicClass);
    },
    assignTeacher(academicClass) {
      this.$refs["assignTeacher"].showModal(academicClass);
    },
    openExamDialog(academic_class) {
      this.$refs["exam"].showModal({}, academic_class);
    },
    getClass() {
      academicClassService
        .show(this.classId)
        .then((response) => {
          this.academic_class = response.data.class;
        })
        .catch((err) => {})
        .finally(() => {
          this.getAssignedStudents();
          this.getAssignedTeachers();
          this.getAssignedCourses();
          this.getTimeTable();
          this.getAssignedExams();

          if (this.academic_class.title) {
            this.pageTitle = this.academic_class.title;
          }
          if (this.academic_class.title) {
            this.pageTips = "Class Summary for" + this.academic_class.title;
          }
        });
    },
    getAssignedStudents() {
      academicClassService.getClassStudents(this.classId).then((response) => {
        this.assigned_students = response.data.students;
      });
    },
    getAssignedExams() {
      examService
        .getExamByClassId(this.classId)
        .then((response) => {
          this.assigned_exams = response.data.exams;
        })
        .catch((err) => {});
    },
    getAssignedTeachers() {
      this.isLoading = true;
      academicClassService.getAssignedTeachers(this.classId).then((response) => {
        this.assigned_teachers = response.data.teachers;
        this.isLoading = false;
        this.buildTeacher();
      });
    },
    buildTeacher() {
      if (this.assigned_teachers && this.assigned_teachers.length > 0) {
        this.assigned_teachers.forEach((ele) => {
          this.teachers.push(ele.teacher);
        });
      }
    },
    getAssignedCourses() {
      academicClassService.getAssignedCourses(this.classId).then((response) => {
        this.assigned_courses = response.data.courses;
      });
    },
    downloadStudentImportDialog(academic_class) {
      this.studentImportDialog = true;
    },

    downloadStudentImportTemplate(academic_class) {
      this.$v.$touch();
      if (this.$v.noOfStudentsToImport.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let url = API_URL + "user/student-export-template/" + academic_class.id;
        let data = {
          no_of_students: this.noOfStudentsToImport,
        };
        if (data) {
          let query = new URLSearchParams(data).toString();
          if (query) url = url + "?" + query;
        }
        window.open(url);
      }
    },
    getTimeTable() {
      academicClassTimetable
        .getByClass(this.classId)
        .then((response) => {
          this.academicClassTimetable = response.data.academicClassTimetable;
        })
        .catch((error) => {
          this.academicClassTimetable = {
            class_id: null,
            title: null,
            is_working_sunday: null,
            is_working_monday: null,
            is_working_tuesday: null,
            is_working_wednesday: null,
            is_working_thursday: null,
            is_working_friday: null,
            is_working_saturday: null,
            is_active: 0,
          };
        });
    },
    sendWelcomeMail(academicClass) {
      this.$confirm({
        message: `Are you sure you want to send welcome mail?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicClassService
              .sendWelcomeMail(academicClass.id)
              .then((response) => {
                this.$snotify.success("Request has been processed");
              })
              .catch((error) => {
                this.$snotify.error("Something went wrong");
              });
          }
        },
      });
    },

    refreshTimeTable() {
      this.getTimeTable();
    },
    onStudentsSelected(payload) {
      let selected = [];
      payload.forEach((ele) => {
        selected.push(ele.id);
      });
      this.checked_students = selected;
    },
    removeStudentFromClass(item) {
      this.$confirm({
        message: `Do you delete selected students from class?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              student_ids: this.checked_students,
            };
            this.isLoading = true;
            gradeService
              .removeAllStudentFromClass(this.academic_class.id, data)
              .then((response) => {
                this.isLoading = false;
                this.$snotify.success("Students removed from this classroom");
                this.$refs["students-list"].refreshCheckedStudents();
                this.getAssignedStudents();
              })
              .catch((error) => {
                this.isLoading = false;
                this.$snotify.error("Something went wrong please try again later");
              });
          }
        },
      });
    },
    assignSubject() {
      this.assign_course_dialog = true;
    },
    assignCoursesToStudents() {
      let data = {
        assigned_courses: this.selected_courses,
        selected_students: this.checked_students,
      };
      academicClassService
        .saveStudentCourses(this.academic_class.id, data)
        .then((response) => {
          this.$snotify.success("Information updated");
          this.selected_courses = [];
          this.assign_course_dialog = false;
          this.$refs["students-list"].refreshAllCheckedStudent();
          this.getAssignedStudents();
          this.checked_students = [];
        })
        .catch((error) => {
          this.isLoading = false;
          this.$snotify.error("Something went wrong please try again later");
        });
    },

    cancelStudentImport(){
      this.import_excel = false;
    },

    importStudent() {
      this.importUserLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      userService.importNewUser(fd).then(res => {
        this.import_error = res.data
        this.importUserLoading = false
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("File import success");
        this.init();
      }).catch(error => {
        this.importUserLoading = false;
        this.$snotify.error(error);
      });
    },
  },
};
</script>

<style scoped>
.menu-parent .v-tab {
  justify-content: flex-start !important;
}
</style>
