<template>
  <v-dialog fullscreen v-model="attendance_dialog">
    <v-card>
      <v-card-title>
        Student Attendance
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!--        <v-card link>-->
        <!--          <v-card-text>-->
        <!--            <h4>-->
        <!--              {{ selected_class_room.title }}-->
        <!--              <span><img src="https://img.icons8.com/color/20/000000/zoom.png"/></span>-->
        <!--            </h4>-->
        <!--            {{ selected_class_room.duration }} mins Class |-->
        <!--            {{ selected_class_room.start_from }} - {{ selected_class_room.end_to }} |-->
        <!--            {{ selected_class_room.formatted_date }}-->
        <!--            <br/>-->
        <!--            Total : <strong> {{ selected_class_room.total_attendee }}</strong>-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->
        <v-tabs v-model="tab" dark>
          <v-tab> Student List</v-tab>
          <v-tab> Matched Student List</v-tab>
          <v-tab> UnMatched Student List</v-tab>
          <v-tab> Manual Match</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
                :headers="studentsHeaders"
                :items="all_students"
                :items-per-page="perPage"
                class="elevation-1"
                :hide-default-footer="true"
            >
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
            </v-data-table>
            <div class="row mt-3">
              <div class="col-md-12 text-right">
                <b-pagination
                    @input="getAll(selected_class_room.id)"
                    :event-overlap-threshold="30"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
                <!--  -->
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
                :headers="studentsHeaders"
                :items="matchedStudents"
                :items-per-page="perPage"
                class="elevation-1"
                :hide-default-footer="true"
            >
              <template v-slot:item.join_time="{ item }">
                {{ item.join_time | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
              </template>
              <template v-slot:item.leave_time="{ item }">
                {{ item.leave_time | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
              </template>
              <template v-slot:item.duration="{ item }">
                {{ item.duration }} Minutes
              </template>
            </v-data-table>
            <div class="row mt-3">
              <div class="col-md-12 text-right">
                <b-pagination
                    @input="getMatchedStudents(selected_class_room.id)"
                    :event-overlap-threshold="30"
                    v-model="matched_page"
                    :total-rows="matched_total"
                    :per-page="matched_perPage"
                    first-number
                    last-number
                ></b-pagination>
                <!--  -->
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
                :headers="studentsHeaders"
                :items="unmatchedStudents"
                :items-per-page="perPage"
                :hide-default-footer="true"
                class="elevation-1"
            >
              <template v-slot:item.join_time="{ item }">
                {{ item.join_time | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
              </template>
              <template v-slot:item.leave_time="{ item }">
                {{ item.leave_time | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}
              </template>
              <template v-slot:item.duration="{ item }">
                {{ item.duration }} Minutes
              </template>
            </v-data-table>
            <div class="row mt-3">
              <div class="col-md-12 text-right">
                <b-pagination
                    @input="getUnmatchedStudents(selected_class_room.id)"
                    :event-overlap-threshold="30"
                    v-model="unmatched_page"
                    :total-rows="unmatched_total"
                    :per-page="unmatched_perPage"
                    first-number
                    last-number
                ></b-pagination>
                <!--  -->
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="8">
                <v-data-table
                    v-model="selected"
                    :headers="studentsHeaders"
                    :items="unmatchedStudents"
                    :items-per-page="perPage"
                    single-select
                    item-key="name"
                    show-select
                    class="elevation-1"
                    :hide-default-footer="true"
                >
                  <template v-slot:item.name="{ item }">
                    {{ item.name }}
                  </template>
                </v-data-table>
                <div class="row mt-3">
                  <div class="col-md-12 text-right">
                    <b-pagination
                        @input="getUnmatchedStudents(selected_class_room.id)"
                        :event-overlap-threshold="30"
                        v-model="unmatched_page"
                        :total-rows="unmatched_total"
                        :per-page="unmatched_perPage"
                        first-number
                        last-number
                    ></b-pagination>
                    <!--  -->
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <v-card>
                  <v-card-text v-if="selected.length">
                    <v-card class="mx-auto" max-width="434" tile>
                      <v-row>
                        <v-col align-self="start" class="pa-0" cols="3">
                          <v-avatar class="profile" color="teal" tile size="100">
                              <span class="white--text text-h5">
                                {{ selected[0].name.substring(0, 1) }}
                              </span>
                          </v-avatar>
                        </v-col>
                        <v-col align-self="start" cols="9" class="py-0">
                          <v-list-item color="rgba(0, 0, 0, .4)">
                            <v-list-item-content>
                              <v-list-item-title class="text-h6">
                                {{ selected[0].name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ selected[0].name }}@email.com
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-divider></v-divider>
                    <strong> Select Student to Match </strong>
                    <v-autocomplete
                        class="mt-5"
                        clearable
                        v-model="selectedUser"
                        :items="users"
                        :loading="isBusy"
                        outlined
                        dense
                        :search-input.sync="searchName"
                        item-text="display_text"
                        label="User CRN or Email"
                        return-object
                    ></v-autocomplete>
                    <v-card
                        v-if="selectedUser"
                        class="mx-auto mt-4"
                        max-width="434"
                        tile
                    >
                      <v-row>
                        <v-col cols="3">
                          <v-avatar
                              v-if="selectedUser.image_path['thumb']"
                              class="profile"
                              color="grey"
                              size="100"
                              tile
                          >
                            <v-img :src="selectedUser.image_path['thumb']"></v-img>
                          </v-avatar>
                        </v-col>
                        <v-col class="" cols="9">
                          <v-list-item color="rgba(0, 0, 0, .4)">
                            <v-list-item-content>
                              <v-list-item-title class="text-h6">
                                {{ selectedUser.full_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ selectedUser.personal_id }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ selectedUser.email }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-card-action class="mt-3">
                        <div class="row">
                          <v-col>
                            <v-btn block depressed @click="selectedUser = null">
                              Not Matched
                              <v-icon right dark color="red"> mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn block depressed @click="matchUser"
                            >Confirm
                              <v-icon color="green" right dark> mdi-check</v-icon>
                            </v-btn>
                          </v-col>
                        </div>
                      </v-card-action>
                    </v-card>
                  </v-card-text>
                  <v-card-text v-else>
                    <div class="row mt-3">
                      <div class="col-md-12 text-center">
                        <div class="p-20">
                          <img src="@/assets/media/images/no-data-found.png"/>
                          <h5 class="align-center mt-10">
                            Please Select Any Student To Start
                          </h5>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AttendanceService from "@/core/services/attendance/AttendanceService";
import {required} from "vuelidate/lib/validators";
import {AttendanceMixin} from "@/mixins/AttendanceMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ZoomUserService from "@/core/services/zoom-user/ZoomUserService";
import UserService from "@/core/services/user/UserService";
import OnlineClassRoomsService from "@/core/services/online-class-rooms/OnlineClassRoomsService";

const zoomUserService = new ZoomUserService();
const attendanceService = new AttendanceService();
const onlineClassRoom = new OnlineClassRoomsService();

const userService = new UserService();
export default {
  name: "OnlineClassAttendanceReport",
  props: ["attendance_dialog", "room", "room_id", "course_id"],
  mixins: [AttendanceMixin, FilterMixin],
  validations: {
    search: {
      year: {required},
      month: {required},
      course_id: {required},
    },
  },
  watch: {
    searchName(newValue, oldValue) {
      this.search.name = newValue;
      // Lazily load input items
      this.getUsers();
    },
  },
  data() {
    return {
      isBusy: false,
      loading: false,
      menu2: false,
      attrs: false,
      date: null,
      searchName: null,
      selectedUser: null,
      tab: 0,
      selected: [],
      studentsHeaders: [
        {
          text: "Student Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "Email", value: "user_email"},
        {text: "Entry Time", value: "join_time"},
        {text: "Exit Time", value: "leave_time"},
        {text: "Duration", value: "duration"},
      ],
      unmatchedStudents: [],
      matchedStudents: [],
      all_students: [],
      search: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        linked_class: null,
        date: null,
      },
      selected_class_room: {},
      class_rooms: [],
      unmatched_page: null,
      unmatched_total: null,
      unmatched_perPage: null,
      matched_page: null,
      matched_total: null,
      matched_perPage: null,
      page: null,
      total: null,
      perPage: null,
    };
  },
  computed: {
    years() {
      let years = [];
      let currentYear = new Date().getFullYear();
      let index = 0;
      while (index < 5) {
        years.push(currentYear - index);
        index++;
      }
      return years;
    },
    duration(duration) {
      return duration.toFixed(2);
    },
  },
  methods: {
    getUsers() {
      this.isBusy = true;
      this.search.academic_year_id = this.room.academic_year_id;
      this.search.level_id = this.room.level_id;
      this.search.program_id = this.room.program_id;
      this.search.grade_id = this.room.grade_id;
      this.search.linked_class = this.room.class_id;
      userService.paginate(this.search).then((response) => {
        this.isBusy = false;
        this.users = response.data.data;
        this.users.map((ele) => {
          if (
              ele.personal_id &&
              ele.personal_id != undefined &&
              ele.personal_id != null
          ) {
            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`;
            this.users.push(ele);
          }
        });
        // this.page = response.data.meta.current_page;
        // this.total = response.data.meta.total;
        // this.perPage = parseInt(response.data.meta.per_page);
        // alert(this.length)
      });
    },

    getOnlineClassRoomForClass() {
      this.loading = true;
      onlineClassRoom
          .getOnlineClassRoomForClass(this.search)
          .then((response) => {
            this.class_rooms = response.data.rooms;
            this.loading = false;
          })
          .finally();
    },

    async getUnmatchedStudents() {
      await zoomUserService
          .getOnlineAttendanceList(this.room_id, "un-matched", this.unmatched_page)
          .then((response) => {
            this.unmatchedStudents = response.data.data;
            this.unmatched_page = response.data.meta.current_page;
            this.unmatched_total = response.data.meta.total;
            this.unmatched_perPage = response.data.meta.per_page;
          })
          .catch((error) => {
            this.loading = false;
          })
          .finally(() => {
            // this.loading= false
          });
    },
    async getMatchedStudents() {
      await zoomUserService
          .getOnlineAttendanceList(this.room_id, "matched", this.matched_page)
          .then((response) => {
            this.matchedStudents = response.data.data;
            this.matched_page = response.data.meta.current_page;
            this.matched_total = response.data.meta.total;
            this.matched_perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
          });
    },
    async getAll() {
      await zoomUserService
          .getOnlineAttendanceList(this.room_id, null, this.page)
          .then((response) => {
            this.all_students = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          })
          .finally(() => {
          });
    },

    getOnlineAttendance() {
      this.selectedUser = {}
      if (this.room_id) {
        this.loading = true;
        this.getAll();
        this.loading = true;
        this.getUnmatchedStudents();
        this.loading = true;
        this.getMatchedStudents();
      }
    },
    matchUser() {
      // this.$confirm({
      //   message: `Are you sure?`,
      //   button: {
      //     no: "No",
      //     yes: "Yes",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      let data = {student_id: this.selectedUser.id, id: this.selected[0].id};
      zoomUserService.makeStudentPresent(data).then((response) => {
        this.selectedUser = {}
        this.selected = []
        this.getOnlineAttendance();
      });
      //     }
      //   },
      // });
    },
    closeDialog() {
      this.unmatchedStudents = []
      this.matchedStudents = []
      this.all_students = []
      this.$emit('close_dialog')
    }
  },
};
</script>

<style>
.form-control {
  border: none !important;
}

.attenedance table {
  border-collapse: collapse;
}

.attenedance table,
th,
td {
  /*border: 1px solid black;*/
}

.table th,
.table thead th,
.table td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table {
  background: #fff;
}

.v-input--selection-controls__input + .v-label {
  margin-bottom: 0 !important;
}

.v-toolbar__title {
  padding-left: 10px;
}

.table .v-input .v-messages,
.v-input--radio-group .v-messages {
  display: none;
}

.v-toolbar__content .v-input--radio-group--row {
  margin-left: 10px;
}

.v-toolbar__content .v-input--radio-group--row + .v-input--radio-group--row {
  margin-left: 0px;
}

.v-toolbar__content .v-input--radio-group--row .v-input__slot {
  margin-bottom: 0;
}

.v-input__slot {
  background: transparent !important;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border-color: #000 !important;
}
</style>
