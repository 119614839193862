<template>
  <v-app>
    <v-card flat>
      <v-card-title>
        <div class="col-md-6 text-left">
          <h4>Online classes</h4>
        </div>
        <div class="col-md-6 text-right mb-3"></div>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <form @submit.prevent="searchResult" class="row mt-5 mb-5 bg-filter">
            <v-col cols="12" md="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dates"
                    label="Dates"
                    clearable
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                label="Search by teacher's name, email or CRN"
                @input="searchResult"
                v-model="search.teacher"
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                outlined
                dense
                v-model="search.attendance_status"
                clearable
                :items="attendanceStatuses"
                item-text="title"
                item-value="value"
                @change="searchResult"
                label="Attended"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                outlined
                dense
                v-model="search.status"
                :items="statuses"
                item-text="title"
                item-value="value"
                clearable
                @input="searchResult"
                label="Status"
              ></v-select>
            </v-col>

            <v-col cols="2" md="2" class="text-right">
              <v-btn
                type="submit"
                @click="searchResult"
                class="btn btn-primary text-white"
              >
                Search
              </v-btn>
            </v-col>
          </form>
        </div>
        <div class="row">
          <online-class-room-list :rooms="rooms"></online-class-room-list>
          <div class="col-12" v-if="total > perPage">
            <b-pagination
              @input="getRooms"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
            ></b-pagination>
            <!--  -->
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import OnlineClassRoomsService from "@/core/services/online-class-rooms/OnlineClassRoomsService";
import OnlineClassRoomList from "@/view/component/online-class-room/OnlineClassRoomList";
const onlineCLassRoom = new OnlineClassRoomsService();
export default {
  name: "OnlineClassRoom",
  components: {
    OnlineClassRoomList,
  },
  data() {
    return {
      search: {
        teacher: "",
        attendance_status: "",
        status: "",
        class_id: null,
      },
      rooms: [],
      dates: [],
      page: null,
      total: null,
      perPage: null,
      attendanceStatuses: [
        {
          title: "Yes",
          value: "yes",
        },
        {
          title: "No",
          value: "no",
        },
      ],
      statuses: [
        {
          title: "Ongoing",
          value: "ongoing",
        },
        {
          title: "Upcoming",
          value: "upcoming",
        },
        {
          title: "Past",
          value: "past",
        },
      ],
    };
  },
  mounted() {
    this.search.class_id = this.classId;
    if (this.setDefaultDates()) {
      this.searchResult();
    }
  },
  methods: {
    searchResult() {
      this.search.dates = [];
      if (this.dates && this.dates.length > 0) {
        this.search.dates = this.dates;
      }
      this.getRooms();
    },
    getRooms() {
      onlineCLassRoom
        .paginateOnlineClassRoom(this.search, this.page)
        .then((response) => {
          this.rooms = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((error) => {});
    },
    resetSearch() {
      this.search = {
        title: null,
        program_id: null,
        active: "active",
      };
      if (this.setDefaultDates()) {
        this.searchResult();
      }
    },
    setDefaultDates() {
      this.dates = [];
      let startDate = this.$moment(new Date()).format("YYYY-MM-DD");
      let endDate = this.$moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD");
      this.dates.push(startDate);
      this.dates.push(endDate);
      return true;
    },
  },
  computed: {
    classId() {
      return this.$route.params.classId;
    },
  },
};
</script>

<style scoped>
</style>