<template>
  <v-app>
    <v-card flat>
      <v-row>
        <v-col cols="8" class="pt-5">
          <h4>Timetable</h4>
        </v-col>
        <v-col cols="4" class="text-right pr-8">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4 ml-2"
            no-caret
            right
            no-flip
          >
            <template v-slot:button-content>
              <i class="ki ki-bold-more-hor"></i>
            </template>
            <!--begin::Navigation-->
            <div class="navi navi-hover min-w-md-250px">
              <b-dropdown-text
                tag="div"
                class="navi-item"
                v-if="checkIsAccessible('academic-class', 'download-time-table') && academicClassTimetable.id"
              >
                <a class="ml-2 navi-link" @click="viewTimetablePdf">
                  <span class="navi-icon">
                    <i class="fa fa-file-pdf"></i>
                  </span>
                  <span class="navi-text">View PDF</span>
                </a>
              </b-dropdown-text>

<!--              <b-dropdown-text-->
<!--                tag="div"-->
<!--                class="navi-item"-->
<!--                v-if="checkIsAccessible('academic-class', 'download-time-table') && academicClassTimetable.id"-->
<!--              >-->
<!--                <a class="ml-2 navi-link" @click="generateReport">-->
<!--                  <span class="navi-icon">-->
<!--                    <i class="fas fa-download"></i>-->
<!--                  </span>-->
<!--                  <span class="navi-text">Download PDF</span>-->
<!--                </a>-->
<!--              </b-dropdown-text>-->

              <b-dropdown-text
                tag="div"
                class="navi-item"
                v-if="checkIsAccessible('academic-class-time-table', 'edit')"
              >
                <a class="ml-2 navi-link" @click="weekdayDialog = true">
                  <span class="navi-icon">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="navi-text">Update class days</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text
                tag="div"
                class="navi-item"
                v-if="checkIsAccessible('academic-class-time-table', 'delete')"
              >
                <a class="ml-2 navi-link" @click="__deleteTimeTable()">
                  <span class="navi-icon">
                    <i class="fa fa-trash"></i>
                  </span>
                  <span class="navi-text">Delete timetable</span>
                </a>
              </b-dropdown-text>
            </div>
            <!--end::Navigation-->
          </b-dropdown>
        </v-col>
        <v-col v-if="downloadProgress && downloadProgress < 100">
          <v-progress-linear
            class="text-white mt-5"
            color="green"
            height="20"
            v-model="downloadProgress"
          >
            <strong
              >{{ Math.ceil(downloadProgress) }}% Generating timetable pdf. Please
              wait...</strong
            >
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-card-text v-if="academicClassTimetable.id" style="min-height: 100vh;">
        <div class="">
          <table class="table">
            <tbody class="">
              <template v-if="loading">
                <v-skeleton-loader type="table" v-bind="attrs"></v-skeleton-loader>
              </template>
              <template v-for="(day, index) in classScheduleDays" v-if="!loading">
                <tr class="timetable-td-row px-3" :key="day.day" v-if="day.is_working">
                  <td class="pr-0 text-left timetable-td-head" style="width: 50px; padding-left: 5px !important; padding-right:10px !important">
                    <div class="text-secondary">
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-ver" style="color: #000000"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('academic-class-time-table', 'edit')"
                          >
                            <a
                                class="ml-2 navi-link"
                                @click="openTimeTableDialog(day.day)"
                                href="javascript:void(0)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-cog"></i>
                              </span>
                              <span class="navi-text">Manage</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('academic-class', 'clone-time-table')"
                          >
                            <a
                                class="ml-2 navi-link"
                                @click="openCloneDialog(day.day)"
                                href="javascript:void(0)"
                            >
                              <span class="navi-icon">
                                <i class="fa fa-copy"></i>
                              </span>
                              <span class="navi-text">Clone</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                      <strong>{{ day.title }}</strong>

                    </div>
                  </td>
                  <template v-if="day.value.length">
                    <td
                      class="timetable-td-data"
                      :key="wDay.id"
                      v-for="(wDay, dayIndex) in day.value"
                    >
                      <strong>
                        {{
                          `${
                            wDay.simple_start_time
                              ? wDay.simple_start_time
                              : wDay.start_time
                          } -
                                            ${
                                              wDay.simple_end_time
                                                ? wDay.simple_end_time
                                                : wDay.end_time
                                            }
                                            `
                        }}</strong
                      >

                      <br />

                      <span v-if="!wDay.is_break">
                        {{ wDay.subject_name }}
                        <br />
                        {{ wDay.teacher_name || "Not Assigned" }}
                      </span>
                      <strong v-else> Break </strong>
                    </td>
                    <td :colspan="total_col" v-if="day.value.length != total_col"></td>
                  </template>

                  <td :colspan="total_col" v-else class="text-center">Not assigned</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <create-or-update-time-table
          :academic_class_room="academic_class_room"
          :class_id="class_id"
          @refresh="refreshTimeTable"
        ></create-or-update-time-table>

        Timetable not available
      </v-card-text>
    </v-card>
    <v-dialog max-width="1500" persistent v-model="timeTableDialog">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Manage timetable
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="timeTableDialog = false">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="table-responsive">
                <table class="table">
                  <tr
                      v-if="item.day == currentDay"
                      :key="`keyDay ${item.day}`"
                      v-for="(item, i) in academicClassTimetablePeriods"
                  >
                    <td class="px-3" colspan="2" v-if="!item.is_break">
                      {{ item.subject_name ? item.subject_name : "N/A" }}
                    </td>
                    <td class="px-3" v-if="!item.is_break">
                      {{ item.teacher_name }}
                    </td>
                    <td class="px-3" colspan="3" v-else>
                      <strong>Break</strong>
                    </td>
                    <td class="px-3">
                        <span v-if="item.start_time">
                          {{ formatTime(item.start_time) }}</span
                        >
                    </td>
                    <td class="px-3">
                      <span v-if="item.end_time"> {{ formatTime(item.end_time) }}</span>
                    </td>
                    <!--                      <td class="px-3">-->
                    <!--                        {{ item.running_mode_text }}-->
                    <!--                      </td>-->
                    <!--                      <td class="px-3">-->
                    <!--                        {{ item.room_title ? item.room_title : "N/A" }}-->
                    <!--                        <br />-->
                    <!--                        No.: {{ item.room_number ? item.room_number : "N/A" }}-->
                    <!--                      </td>-->
                    <!--                      <td class="px-3">-->
                    <!--                        {{ item.online_class_url }}-->
                    <!--                      </td>-->
                    <!--                      <td class="px-3">-->
                    <!--                        {{ item.online_class_type }}-->
                    <!--                      </td>-->
                    <td class="px-3">
                      <v-btn @click="editTimeTable(item)" color="blue" icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn @click="removeTimeTable(i)" color="red" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <thead>
                    <tr class="px-3">
                      <th class="px-3" style="width: 40px !important; white-space: pre-wrap;"></th>
                      <th class="px-3">Subject</th>
                      <th class="px-3">Teacher</th>
                      <th class="px-3">Start time</th>
                      <th class="px-3">End time</th>
<!--                      <th class="px-3">Running mode</th>-->
<!--                      <th class="px-3">Room</th>-->
<!--                      <th class="px-3">Class URL</th>-->
<!--                      <th class="px-3">Online class type</th>-->
                      <th class="pr-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
                <div class="mt-20">
                  <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                    <div class="row">
                      <div class="col-md-1">
                        <v-checkbox v-model="academicClassTimetablePeriod.is_break" label="Break"
                        >
                        </v-checkbox>
                      </div>
                      <div class="col-md-2"  v-if="!academicClassTimetablePeriod.is_break">
                        <v-select
                            :items="assigned_courses"
                            @change="getAssignedTeachers"
                            dense
                            item-text="title"
                            item-value="id"
                            label="Select Subject"
                            outlined
                            return-object

                            v-model="academicClassTimetablePeriod.subject_id"
                        >
                        </v-select>
                      </div>
                      <div class="col-md-2"  v-if="!academicClassTimetablePeriod.is_break">
                        <v-select
                            :items="assigned_teachers"
                            dense
                            item-text="teacher.full_name"
                            item-value="teacher.id"
                            label="Select teacher"
                            outlined
                            :loading="teacherLoading"
                            return-object
                            v-model="academicClassTimetablePeriod.teacher_id"
                        >
                        </v-select>
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                            dense
                            outlined
                            type="time"
                            v-model="academicClassTimetablePeriod.start_time"
                        ></v-text-field>
                        <span
                            class="text-danger"
                            v-if="$v.academicClassTimetablePeriod.start_time.$error"
                        >This information is required</span
                        >
                      </div>
                      <div class="col-md-2">
                        <v-text-field
                            dense
                            outlined
                            type="time"
                            v-model="academicClassTimetablePeriod.end_time"
                        ></v-text-field>
                        <span
                            class="text-danger"
                            v-if="$v.academicClassTimetablePeriod.end_time.$error"
                        >This information is required</span
                        >
                        </div>
                      <div class="col-md-2">
                        <v-btn
                            @click="editPeriod()"
                            class="text-white"
                            color="blue"
                            depressed
                            v-if="academicClassTimetablePeriod.id"
                        >
                          Edit
                        </v-btn>
                        <v-btn
                            @click="addTimeTable"
                            class="text-white"
                            color="blue"
                            depressed
                            v-else
                        >
                          Add
                        </v-btn>
                      </div>
                    </div>
                    </div>

                  <!--                      <td class="px-3">-->
                  <!--                        <v-select-->
                  <!--                          :items="running_mode"-->
                  <!--                          dense-->
                  <!--                          item-text="title"-->
                  <!--                          item-value="value"-->
                  <!--                          label="Running Mode"-->
                  <!--                          outlined-->
                  <!--                          placeholder="Class Type "-->
                  <!--                          v-if="!academicClassTimetablePeriod.is_break"-->
                  <!--                          v-model="academicClassTimetablePeriod.running_mode"-->
                  <!--                        ></v-select>-->
                  <!--                        <strong v-else> Break </strong>-->
                  <!--                      </td>-->
                  <!--                      <td class="px-3">-->
                  <!--                        <template v-if="!academicClassTimetablePeriod.is_break">-->
                  <!--                          <v-select-->
                  <!--                            :items="rooms"-->
                  <!--                            dense-->
                  <!--                            item-text="title"-->
                  <!--                            item-value="id"-->
                  <!--                            label="Room"-->
                  <!--                            outlined-->
                  <!--                            placeholder="Class Type "-->
                  <!--                            v-if="academicClassTimetablePeriod.running_mode == 'campus'"-->
                  <!--                            v-model="academicClassTimetablePeriod.room_id"-->
                  <!--                          ></v-select>-->
                  <!--                          <span v-else>N/A</span>-->
                  <!--                        </template>-->
                  <!--                        <strong v-else> Break </strong>-->
                  <!--                      </td>-->
                  <!--                      <td class="px-3">-->
                  <!--                        <template v-if="!academicClassTimetablePeriod.is_break">-->
                  <!--                          <v-text-field-->
                  <!--                            dense-->
                  <!--                            label="Online Class URL"-->
                  <!--                            outlined-->
                  <!--                            placeholder="Online Class URL"-->
                  <!--                            v-if="academicClassTimetablePeriod.running_mode == 'online'"-->
                  <!--                            v-model="academicClassTimetablePeriod.online_class_url"-->
                  <!--                          ></v-text-field>-->
                  <!--                          <span v-else>N/A</span>-->
                  <!--                        </template>-->
                  <!--                        <strong v-else> Break </strong>-->
                  <!--                      </td>-->
                  <!--                      <td class="px-3">-->
                  <!--                        <template v-if="!academicClassTimetablePeriod.is_break">-->
                  <!--                          <v-select-->
                  <!--                            :items="online_class_type"-->
                  <!--                            dense-->
                  <!--                            item-text="title"-->
                  <!--                            item-value="value"-->
                  <!--                            label="Class Type"-->
                  <!--                            outlined-->
                  <!--                            placeholder="Class Type "-->
                  <!--                            v-if="academicClassTimetablePeriod.running_mode == 'online'"-->
                  <!--                            v-model="academicClassTimetablePeriod.online_class_type"-->
                  <!--                          ></v-select>-->
                  <!--                          <span v-else>N/A</span>-->
                  <!--                        </template>-->
                  <!--                        <strong v-else> Break </strong>-->
                  <!--                      </td>-->

                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              @click="closeTimeTableDialog()"
              class="btn btn-standard text-white"
              depressed
            >
              Cancel
            </v-btn>
            <v-btn
              @click="saveTimeTablePeriod()"
              class="btn btn-primary text-white ml-2"
              depressed
            >
              Save
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" persistent v-model="replicateDialog">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Clone timetable
            <hr />
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="replicateDialog = false">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="classWeek"
                  @change="__cloneTimeTable"
                  dense
                  item-text="title"
                  item-value="day"
                  label="Clone from"
                  outlined
                  :error="$v.currentDay.$error"
                  v-model="currentDay"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="classWeek"
                  dense
                  item-text="title"
                  item-value="day"
                  label="Replicate to "
                  outlined
                  disabled
                  v-model="selectedDay"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              @click="closeCloneDialog()"
              class="btn btn-standard text-white"
              depressed
            >
              Cancel
            </v-btn>
            <v-btn
              @click="cloneTimeTable()"
              class="btn btn-primary text-white ml-2"
              depressed
            >
              Save
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="weekdayDialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Class days
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="weekdayDialog = false">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="">
                  <table class="table">
                    <thead>
                      <tr class="px-3">
                        <th>Set class</th>
                        <th>Day</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_sunday"
                          ></v-checkbox>
                        </td>
                        <td>Sun</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_monday"
                          ></v-checkbox>
                        </td>
                        <td>Mon</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_tuesday"
                          ></v-checkbox>
                        </td>
                        <td>Tue</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_wednesday"
                          ></v-checkbox>
                        </td>
                        <td>Wed</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_thursday"
                          ></v-checkbox>
                        </td>
                        <td>Thu</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_friday"
                          ></v-checkbox>
                        </td>
                        <td>Fri</td>
                      </tr>
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="academicClassTimetable.is_working_saturday"
                          ></v-checkbox>
                        </td>
                        <td>Sat</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-standard cancel-btn"
            depressed
            @click="weekdayDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            @click="__updateTimeTable()"
            depressed
            class="text-white ml-2 btn btn-primary"
          >
            {{ academicClassTimetable.id ? "Save" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :enable-download="true"
      :filename="academic_class_room.title"
      :float-layout="true"
      :manual-pagination="false"
      :paginate-elements-by-height="1500"
      :pdf-quality="5"
      :preview-modal="false"
      :show-layout="false"
      @hasGenerated="hasGenerated($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @progress="onProgress($event)"
      pdf-content-width="1120px"
      pdf-format="a4"
      pdf-orientation="landscape"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div>
          <v-card-title class="d-flex flex justify-content-center">
            Class timetable
          </v-card-title>

          <v-card-subtitle class="d-flex flex justify-content-center">
            {{
              `${academic_class_room.program.title} - ${academic_class_room.grade_title}`
            }}
          </v-card-subtitle>

          <v-card-subtitle class="d-flex flex justify-content-center">
            <b>{{ `${academic_class_room.title} - ${academic_class_room.shift}` }}</b>
          </v-card-subtitle>

          <v-card-text>
            <div class="">
              <table class="table table-stripe">
                <tbody class="">
                  <template v-if="loading">
                    <v-skeleton-loader type="table" v-bind="attrs"></v-skeleton-loader>
                  </template>
                  <template v-for="(day, index) in classScheduleDays" v-if="!loading">
                    <tr :key="day.day" v-if="day.is_working">
                      <td>
                        <b>{{ day.title }}</b> <br />
                      </td>
                      <template v-if="day.value.length">
                        <td :key="wDay.id" v-for="(wDay, dayIndex) in day.value">
                          <strong>
                            {{
                              `${
                                wDay.simple_start_time
                                  ? wDay.simple_start_time
                                  : wDay.start_time
                              } -
                                                    ${
                                                      wDay.simple_end_time
                                                        ? wDay.simple_end_time
                                                        : wDay.end_time
                                                    }
                                                    `
                            }}</strong
                          >

                          <br />

                          <div v-if="!wDay.is_break">
                            <span class="ml-2"
                              >{{
                                wDay.subject_name &&
                                wDay.subject_name.length > 25
                                  ? wDay.subject_name.substring(0, 25) + "..."
                                  : wDay.subject_name
                              }}
                            </span>
                            <br />
                            {{ wDay.teacher_name || "Not Assigned" }}
                          </div>
                          <strong v-else> Break </strong>
                        </td>
                        <td
                          :colspan="total_col"
                          v-if="day.value.length != total_col"
                        ></td>
                      </template>

                      <td :colspan="total_col" v-else>No assigned</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-card-text>
        </div>
      </section>
    </vue-html2pdf>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import RoomsService from "@/core/services/rooms/RoomsService";
import AcademicCLassTimetablePeriod from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";
import CreateOrUpdateTimeTable from "../CreateOrUpdateTimeTable";
import VueHtml2pdf from "vue-html2pdf";
import {API_URL, APP_URL} from "@/core/config";

const academicClassService = new AcademicClassService();
const roomService = new RoomsService();
const academicCLassTimetablePeriodService = new AcademicCLassTimetablePeriod();

import AcademicCLassTimetableService from "@/core/services/academicClassTimetable/AcademicCLassTimetableService";
const academicClassTimetableService = new AcademicCLassTimetableService();
export default {
  validations: {
    academicClassTimetablePeriod: {
      start_time: { required },
      end_time: { required },
    },
    currentDay: { required },
  },
  components: { CreateOrUpdateTimeTable, VueHtml2pdf },
  name: "TimeTable",
  props: ["academicClassTimetable", "class_id", "academic_class_room"],
  data() {
    return {
      week_days: [
        {
          day: 0,
          title: "Sun",
          value: [],
        },
        {
          day: 1,
          title: "Mon",
          value: [],
        },
        {
          day: 2,
          title: "Tue",
          value: [],
        },
        {
          day: 3,
          title: "Wed",
          value: [],
        },
        {
          day: 4,
          title: "Thu",
          value: [],
        },
        {
          day: 5,
          title: "Fri",
          value: [],
        },
        {
          day: 6,
          title: "Sat",
          value: [],
        },
      ],
      timeTableDialog: false,
      weekdayDialog: false,
      attrs: false,
      replicateDialog: false,
      currentDay: null,
      start_time: false,
      end_time: false,
      loading: false,
      teacherLoading: false,
      assigned_courses: [],
      assigned_teachers: [],
      cloneWeekDays: [],
      downloadProgress: null,
      selectedDay: null,
      fromDay: null,
      academicClassTimetablePeriods: [],
      rooms: [],
      running_mode: [
        {
          title: "Online",
          value: "online",
        },
        {
          title: "Campus",
          value: "campus",
        },
      ],
      classScheduleDays: [],
      academic_class: this.academicClassTimetable.class_id,
      academicClassTimetablePeriod: {
        timetable_id: null,
        day: null,
        subject_id: null,
        subject_name: null,
        teacher_id: null,
        room_id: null,
        teacher_name: null,
        start_time: null,
        end_time: null,
        running_mode: "campus",
        is_break: 0,
      },
    };
  },
  mounted() {
    this.getAssignedCourses();
    this.getRooms();
    this.getAcademicTimetablePeriod();
  },
  methods: {
    closeTimeTableDialog() {
      this.timeTableDialog = false;
      this.academicClassTimetablePeriod = {};
    },
    openTimeTableDialog(day) {
      this.currentDay = day;
      this.academicClassTimetablePeriod.day = this.currentDay;
      this.timeTableDialog = true;
    },

    __updateTimeTable() {
      this.academicClassTimetable.class_id = this.class_id;
      academicClassTimetableService
        .update(this.academicClassTimetable.id, this.academicClassTimetable)
        .then((response) => {
          // this.$router.push({
          //     name: 'time-table-period',
          //     params: {time_table_id: this.academicClassTimetable.id}
          // })
          this.weekdayDialog = false;
          this.refreshTimeTable();
          this.$snotify.success("TimeTable Information updated");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    __deleteTimeTable() {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicClassTimetableService
              .delete(this.academicClassTimetable.id)
              .then((response) => {
                this.$emit("refresh-timetable");
                this.$snotify.success("Information deleted");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    __cloneTimeTable() {
      this.academicClassTimetablePeriod.day = this.currentDay;
      this.cloneWeekDays = this.academicClassTimetablePeriods.filter((schedule) => {
        if (schedule.day == this.currentDay) return schedule;
      });

      this.replicateDialog = true;
    },
    onProgress(e) {
      this.downloadProgress = e;
    },

    closeCloneDialog() {
      this.cloneWeekDays = [];
      this.replicateDialog = false;
    },
    cloneTimeTable() {
      this.$v.currentDay.$touch();
      if (this.$v.currentDay.$error) {
        setTimeout(() => {
          this.$v.currentDay.$reset();
        }, 3000);
      } else {
        let data = {
          timetable_id: this.academicClassTimetable.id,
          from_day: this.currentDay,
          to_day: this.selectedDay,
        };
        academicClassTimetableService
          .cloneTimetableFromWeekDay(data)
          .then((response) => {
            this.$snotify.success("Information cloned");
            this.closeCloneDialog();
            this.getAcademicTimetablePeriod();
          })
          .catch((error) => {
            this.$snotify.error("Problem occurred");
          });
      }
    },

    openCloneDialog(day) {
      this.replicateDialog = true;
      this.selectedDay = day;
    },

    viewTimetablePdf() {
      // alert(this.academicClassTimetable.id);
      window.open(
        API_URL + "user/academic-class-timetable/"+this.academicClassTimetable.id+"/preview",
        "_blank"
      );
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    refreshTimeTable() {
      this.$emit("refresh-timetable");
      this.getAssignedTeachers();
      this.getAssignedCourses();
      this.getRooms();
    },
    addTimeTable() {
      // this.checkAvailiblity.teacher_id = this.academicClassTimetablePeriod.teacher_id.id ? this.academicClassTimetablePeriod.teacher_id.id : null;
      // this.checkAvailiblity.start_time = this.academicClassTimetablePeriod.start_time;
      // this.checkAvailiblity.end_time = this.academicClassTimetablePeriod.end_time;
      //
      // this.checkAvailablity();

      this.$v.$touch();
      if (this.$v.academicClassTimetablePeriod.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.academicClassTimetablePeriod.is_break) {
          let subjectId = this.academicClassTimetablePeriod.subject_id.id;
          let subjectName = this.academicClassTimetablePeriod.subject_id.title;
          let teacherId = this.academicClassTimetablePeriod.teacher_id
            ? this.academicClassTimetablePeriod.teacher_id.teacher.id
            : null;
          let teacherName = this.academicClassTimetablePeriod.teacher_id
            ? this.academicClassTimetablePeriod.teacher_id.teacher.full_name
            : "N/A";
          this.academicClassTimetablePeriod.subject_id = subjectId;
          this.academicClassTimetablePeriod.teacher_id = teacherId;
          this.academicClassTimetablePeriod.subject_name = subjectName;
          this.academicClassTimetablePeriod.teacher_name = teacherName;
        }
        this.academicClassTimetablePeriod.day = this.currentDay;
        this.academicClassTimetablePeriod.timetable_id = this.academicClassTimetable.id;
        this.academicClassTimetablePeriods.push(this.academicClassTimetablePeriod);
        this.resetTimeTablePeriod();
        this.$v.$reset();
      }
    },
    getAssignedCourses() {
      academicClassService.getAssignedCourses(this.academic_class).then((response) => {
        this.assigned_courses = response.data.courses;
      });
    },
    getAssignedTeachers() {
      let subjectId = this.academicClassTimetablePeriod.subject_id.id || this.academicClassTimetablePeriod.subject_id;
      this.teacherLoading = true;
      academicClassService
        .getAssignedTeachersByCourse(
          this.academic_class,
          subjectId
        )
        .then((response) => {
          this.assigned_teachers = response.data.teachers;
          this.teacherLoading = false;
        });
    },
    getRooms() {
      roomService
        .paginate()
        .then((response) => {
          this.rooms = response.data.data;
        })
        .catch((error) => {});
    },
    saveTimeTablePeriod() {
      this.academicClassTimetablePeriods = this.academicClassTimetablePeriods.map(
        (period) => {
          delete period.simple_start_time;
          delete period.simple_end_time;
          delete period.rooms;
          if (!Object.keys(period).includes("id")) return period;
        }
      );
      this.academicClassTimetablePeriods = this.academicClassTimetablePeriods.filter(
        (period) => {
          return period != null;
        }
      );

      academicCLassTimetablePeriodService
        .store(this.academicClassTimetable.id, this.academicClassTimetablePeriods)
        .then(() => {
          this.selectedDay = null;
          this.timeTableDialog = false;
          this.replicateDialog = false;
          this.getAcademicTimetablePeriod();
          this.resetTimeTablePeriod();
          this.cloneWeekDays = [];
          this.$snotify.success("Information updated");

          // this.$emit("refresh");
        })
        .catch((err) => {});
    },
    editTimeTable(item) {
      this.academicClassTimetablePeriod = item;
      this.getAssignedTeachers();
    },
    deleteAssignedPeriod(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            academicCLassTimetablePeriodService
              .delete(this.academicClassTimetable.id, id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getAcademicTimetablePeriod();
              })
              .catch((error) => {});
          }
        },
      });
    },
    removeTimeTable(index) {
      if (this.academicClassTimetablePeriods[index].id) {
        this.deleteAssignedPeriod(this.academicClassTimetablePeriods[index].id);
      }
      this.academicClassTimetablePeriods.splice(index, 1);
    },
    getAcademicTimetablePeriod() {
      this.loading = true;
      academicCLassTimetablePeriodService
        .paginate(this.academicClassTimetable.id)
        .then((resources) => {
          this.academicClassTimetablePeriods = resources.data.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    formatTime(time) {
      var H = +time.substr(0, 2);
      var h = H % 12 || 12;
      var ampm = H < 12 ? "AM" : "PM";
      time = h + time.substr(2, 3) + ampm;
      return time;
    },
    editPeriod() {
      academicCLassTimetablePeriodService
        .update(
          this.academicClassTimetable.id,
          this.academicClassTimetablePeriod.id,
          this.academicClassTimetablePeriod
        )
        .then((response) => {
          this.$snotify.success("TimeTable Information updated");
          this.resetTimeTablePeriod();
          this.getAcademicTimetablePeriod();
        })
        .catch((err) => {
          this.$snotify.error("Error Occured");
        });
    },
    resetTimeTablePeriod() {
      this.academicClassTimetablePeriod = {
        timetable_id: this.academicClassTimetable.id,
        day: null,
        subject_id: null,
        subject_name: null,
        teacher_id: null,
        teacher_name: null,
        start_time: null,
        end_time: null,
        is_break: null,
      };
    },
    schedule_days() {
      if (
        this.academicClassTimetablePeriods &&
        this.academicClassTimetablePeriods.length
      ) {
        let formatedDays = this.academicClassTimetablePeriods.map((day) => {
          day.day = parseInt(day.day);
          return day;
        });

        let sunday = formatedDays.filter((obj) => {
          return obj.day == 0;
        });
        let monday = formatedDays.filter((obj) => {
          return obj.day == 1;
        });
        let tuesday = formatedDays.filter((obj) => {
          return obj.day == 2;
        });
        let wednesday = formatedDays.filter((obj) => {
          return obj.day == 3;
        });
        let thursday = formatedDays.filter((obj) => {
          return obj.day == 4;
        });
        let friday = formatedDays.filter((obj) => {
          return obj.day == 5;
        });
        let saturday = formatedDays.filter((obj) => {
          return obj.day == 6;
        });
        this.week_days.forEach((element) => {
          switch (element.day) {
            case 0:
              this.academicClassTimetable.is_working_sunday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = sunday;
              break;
            case 1:
              this.academicClassTimetable.is_working_monday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = monday;
              break;
            case 2:
              this.academicClassTimetable.is_working_tuesday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = tuesday;
              break;
            case 3:
              this.academicClassTimetable.is_working_wednesday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = wednesday;
              break;
            case 4:
              this.academicClassTimetable.is_working_thursday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = thursday;
              break;
            case 5:
              this.academicClassTimetable.is_working_friday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = friday;
              break;
            case 6:
              this.academicClassTimetable.is_working_saturday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = saturday;
              break;
            default:
              return null;
          }
        });
        return this.week_days;
      } else
        this.week_days.forEach((element) => {
          switch (element.day) {
            case 0:
              this.academicClassTimetable.is_working_sunday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 1:
              this.academicClassTimetable.is_working_monday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 2:
              this.academicClassTimetable.is_working_tuesday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 3:
              this.academicClassTimetable.is_working_wednesday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 4:
              this.academicClassTimetable.is_working_thursday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 5:
              this.academicClassTimetable.is_working_friday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 6:
              this.academicClassTimetable.is_working_saturday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            default:
              return null;
          }
        });
      return this.week_days;
    },
  },
  computed: {
    schedule_test_days() {
      if (
        this.academicClassTimetablePeriods &&
        this.academicClassTimetablePeriods.length
      ) {
        let formatedDays = this.academicClassTimetablePeriods.map((day) => {
          day.day = parseInt(day.day);
          return day;
        });

        let sunday = formatedDays.filter((obj) => {
          return obj.day == 0;
        });
        let monday = formatedDays.filter((obj) => {
          return obj.day == 1;
        });
        let tuesday = formatedDays.filter((obj) => {
          return obj.day == 2;
        });
        let wednesday = formatedDays.filter((obj) => {
          return obj.day == 3;
        });
        let thursday = formatedDays.filter((obj) => {
          return obj.day == 4;
        });
        let friday = formatedDays.filter((obj) => {
          return obj.day == 5;
        });
        let saturday = formatedDays.filter((obj) => {
          return obj.day == 6;
        });
        this.week_days.forEach((element) => {
          switch (element.day) {
            case 0:
              this.academicClassTimetable.is_working_sunday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = sunday;
              break;
            case 1:
              this.academicClassTimetable.is_working_monday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = monday;
              break;
            case 2:
              this.academicClassTimetable.is_working_tuesday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = tuesday;
              break;
            case 3:
              this.academicClassTimetable.is_working_wednesday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = wednesday;
              break;
            case 4:
              this.academicClassTimetable.is_working_thursday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = thursday;
              break;
            case 5:
              this.academicClassTimetable.is_working_friday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = friday;
              break;
            case 6:
              this.academicClassTimetable.is_working_saturday
                ? (element.is_working = true)
                : (element.is_working = false);
              element.value = saturday;
              break;
            default:
              return null;
          }
        });
        return this.week_days;
      } else
        this.week_days.forEach((element) => {
          switch (element.day) {
            case 0:
              this.academicClassTimetable.is_working_sunday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 1:
              this.academicClassTimetable.is_working_monday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 2:
              this.academicClassTimetable.is_working_tuesday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 3:
              this.academicClassTimetable.is_working_wednesday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 4:
              this.academicClassTimetable.is_working_thursday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 5:
              this.academicClassTimetable.is_working_friday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            case 6:
              this.academicClassTimetable.is_working_saturday
                ? (element.is_working = true)
                : (element.is_working = false);
              break;
            default:
              return null;
          }
        });
      return this.week_days;
    },
    total_col() {
      let col = 0;
      this.week_days.forEach((day) => {
        if (day.value.length > col) col = day.value.length;
      });
      return col;
    },
    classWeek() {
      return this.classScheduleDays.filter((day) => {
        if (day.is_working) return day;
      });
    },
  },
  watch: {
    academicClassTimetablePeriods(newValue, oldValue) {
      this.classScheduleDays = this.schedule_days();
    },
    academicClassTimetable(newValue, oldValue) {
      (this.academic_class = newValue.class_id), this.getAssignedCourses();
      this.getAcademicTimetablePeriod();
    },
  },
};
</script>

<style>
div.timetable table {
  max-width: 100%;
  word-break: break-all;
}

.timetable th,
.timetable td {
  font-size: 8.8px !important;
  padding: 2px 5px !important;
  padding: 2px 5px !important;
  box-sizing: border-box;
  word-break: break-all !important;
}

.timetable th div,
.timetable td div {
  display: block !important;
  /*width: 150px;*/
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
