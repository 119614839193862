<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Class completion record
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                This will complete this session and end class. Please make sure you are aware of class completion before any further action.
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                    v-model="complete_exam_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="academic_classroom.end_date"
                        label="Class completion date" dense outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly :error="$v.academic_classroom.end_date.$error"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="academic_classroom.end_date"
                      @input="complete_exam_menu = false"
                  ></v-date-picker>
                </v-menu>
                <span class="text-danger"
                      v-if="$v.academic_classroom.end_date.$error">This information is required</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
              color="btn btn-primary"
              dark
              x-large
              :loading="loading"
              @click="UpdateClass()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import {required} from "vuelidate/lib/validators";
const academicClassService = new AcademicClassService();
export default {
  validations: {
    academic_classroom:{
      end_date:{required}
    }
  },
  props:['academic_class'],
  data(){
    return{
      loading:false,
      dialog:false,
      complete_exam_menu:false,
      academic_classroom:{
        end_date:null,
        is_complete:1
      },
    }
  },
  methods:{
    openDialog(){
      this.dialog=true;
    },
    closeDialog(){
      this.dialog=false;
    },
    completeClassStatus() {
      this.openDialog();
    },
    UpdateClass(){
      this.$v.$touch();
      if (this.$v.academic_classroom.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        academicClassService
            .update(this.academic_class.id,this.academic_classroom)
            .then((response) => {
              this.closeDialog();
              this.$emit('refresh');
              this.$snotify.success("Classroom completed successfully");
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
  }
}
</script>