<template>
  <v-app>
    <h6 class="m-2">Total classes: {{ report.length ? report.length : 0  }}</h6>
    <div class="">
      <table class="table">
        <thead>
          <tr class="px-3">
            <th class="px-3">Date</th>
            <!--                  <th>Course</th>-->
            <!--                  <th>Total Students</th>-->
            <th class="px-3">Present</th>
            <th class="px-3">Absent</th>
            <th class="px-3">Late</th>
            <th class="px-3">Half</th>
            <th class="pr-3 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
         <tr v-show="report.length > 0" v-for="(rep, index) of report" :key="index">
          <td class="px-2" >
            <span class="text-dark-75 font-weight-medium mb-1">
            {{ rep.attendance_date }}
            </span>
          </td>
          <td class="px-3" >
               <span class="badge badge-primary text-lg`">
                 {{ rep.present }}
               </span>
          </td>
          <td class="px-3" >
            <span class="badge badge-primary text-lg`">{{ rep.absent }}</span>
          </td>
          <td class="px-3" ><span class="mt-2 badge badge-primary text-lg`">{{ rep.late }}</span></td>
          <td class="px-3" ><span class="badge badge-primary text-lg`">{{ rep.half }}</span></td>
          <td class="pr-0 text-center">
            <a @click="openDetailSummary(rep.attendance_id)" class="fas fa-eye"></a>
          </td>
        </tr>
         <tr v-if="report.length == 0">
           <td colspan="6" class="text-center">
             <strong>Data not available</strong>
           </td>
         </tr>
        </tbody>

      </table>
    </div>

    <student-summary-by-date
        :attendance_dialog="dialog"
        :report="student_report"
    @close_dialog="closeDialog"
    ></student-summary-by-date>
  </v-app>
</template>

<script>
import AttendanceService from "@/core/services/attendance/AttendanceService";
import StudentSummaryByDate from "@/view/component/Attendance/StudentSummaryByDate";

const attendanceService = new AttendanceService();

export default {
  name: "AttendanceSummary",
  components: {
    StudentSummaryByDate
  },
  data() {
    return {
      dialog: false,
      student_report: [],

    }
  },
  props: ['report'],
  methods: {
    openDetailSummary(attendanceId) {
      attendanceService.attendanceReportById(attendanceId).then(response=>{
        if (!response.data){
          this.$snotify.error("No student report found")
        }else{
          this.dialog = true
          this.student_report = response.data.report
        }
      })
    }, closeDialog(){
      this.dialog = false;
      this.student_report =[];
    }
  }
}
</script>

<style scoped>

</style>