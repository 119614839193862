<template>
  <v-app>

    <v-dialog
        v-model="dialog"
        width="600"
        title="Assign Course to Class"
    >
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Assign courses
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="dialog=false">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
             <div class="row">
            <div class="col-12">
              <v-autocomplete
                  outlined
                  dense
                  multiple deletable-chips small-chips
                  label="Assign Course"
                  v-model="newCourse"
                  :items="courses"
                  item-text="display_text_with_subject_code"
                  item-value="id"
              >
              </v-autocomplete>

              <!--              <v-multiselect-listbox-->
              <!--                  :options="courses"-->
              <!--                  :reduce-display-property="(course) => course.title"-->
              <!--                  :reduce-value-property="(course) => course.id"-->
              <!--                  v-model="selectedItem"></v-multiselect-listbox>-->
            </div>

          </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              class="btn btn-primary text-white"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
<!--  <pre>{{newCourse}}</pre>-->

    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";
import AcademicClassCourseService from "@/core/services/academic-class/class-course/AcademicClassCourseService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();
const academicClassCourseService = new AcademicClassCourseService()
export default {
  name: "assignCourses",
  props: ['academic_class'],
  validations: {
    exam: {
      title: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      end_date: {required},
      duration: {required},
      duration_unit: {required},
      exam_location: {required},
    }
  },
  components: {},
  data() {
    return {
      selectedItem: [],
      class_courses: [],
      search: {
        program_id: "",
        grade_id: "",
        title: "",
        subject_code: "",
      },
      newCourse: [],
      dialog: false,
      edit: false,
      courses: [],
    };
  },
  mounted() {

    // this.getLevels();
    // this.getAcademicYears();
    // this.getGrades();
  },
  methods: {
    showModal(academic_class = {}) {
      if (academic_class.id) {

        this.edit = true
        this.dialog = true
        this.academic_class = academic_class;
        this.getAssignedCourses();
        this.getCourses()
      }
    },

    hideModal() {

      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = !this.dialog;
    },
    getCourses() {
      this.search.program_id = this.academic_class.program_id;
      this.search.grade_id = this.academic_class.grade_id;
      this.isBusy = true;
      this.$bus.emit('toggleLoader',);
      courseService.paginate(this.search).then(response => {
        this.courses = response.data.data;
        this.isBusy = false;
        this.$bus.emit('toggleLoader');
      });
    },
    getAssignedCourses() {
      academicClassService.getAssignedCourses(this.academic_class.id).then(response => {
        this.selectedItem = response.data.courses;
        if (this.selectedItem && this.selectedItem.length > 0) {
          this.newCourse = [];
          this.selectedItem.forEach((entry) => {
            if (this.newCourse.indexOf(entry.id) < 0)
              this.newCourse.push(entry.id);
          });
        }

      });
    },

    // getLevels() {
    //     levelService.all().then(response => {
    //         this.levels = response.data;
    //
    //     });
    // },
    // getCourses() {
    //     examService.getCourseExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
    //         this.courses = response.data.courses;
    //     });
    // },
    // getClassExam() {
    //     examService.getClassExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
    //         this.classes = response.data.class;
    //     });
    // },
    // getAcademicYears() {
    //     academicYearService.paginate().then(response => {
    //         this.academicYears = response.data.data;
    //
    //     });
    // },
    // getGrades() {
    //     gradeLevelService.paginate().then(response => {
    //         this.gradesLevels = response.data.data;
    //
    //     });
    // },
    save() {

      // this.selectedItem.push({id: this.newCourse});
      let data = {
        'course_ids': this.newCourse
      }
      academicClassService.syncCourses(this.academic_class.id, data).then((response) => {
        this.resetForm()
        this.getAssignedCourses();
        this.$emit("refresh");
        this.$snotify.success("Information updated");
      });

    },
    resetForm() {
      this.newCourse = null;
      this.selectedItem = [];
      this.class_courses = [];
      this.$v.$reset()
      this.hideModal()

    }
  }
};
</script>
