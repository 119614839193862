<template>
  <v-app>
    <div class="card">
      <div class="card-header py-5 px-5">
        <div class="card-title align-items-start flex-column">
          <v-row>
            <v-col cols="8">
              <h3 class="card-label font-weight-bolder text-dark">
                Settings & Information
              </h3>
            </v-col>
            <v-col cols="4" class="text-right">
              <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4 ml-2" no-caret right no-flip>
                <template v-slot:button-content>
                  <i class="ki ki-bold-more-hor"></i>
                </template>
                <!--begin::Navigation-->
                <div class="navi navi-hover min-w-md-250px">
                  <b-dropdown-text tag="div" class="navi-item" v-if="academic_class.is_confirmed && checkIsAccessible('academic-class', 'confirm-class')" >
                    <a  class="ml-2 navi-link"
                        @click="confirmClass(false)">
                                <span class="navi-icon">
                                    <i class="fa-solid fa-xmark"></i>
                                </span>
                      <span class="navi-text">Unconfirm start</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item"  v-if="!academic_class.is_confirmed && checkIsAccessible('academic-class', 'confirm-class')" >
                    <a  class="ml-2 navi-link"
                        @click="confirmClass(true)">
                                <span class="navi-icon">
                                    <i class="fa-regular fa-circle-check"></i>
                                </span>
                      <span class="navi-text">Confirm start</span>
                    </a>
                  </b-dropdown-text>
<!--                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-class', 'generate-fee')">
                    <a class="ml-2 navi-link" @click="generateFee()"  >
                                <span class="navi-icon">
                                    <i class="fa-solid fa-file-invoice-dollar"></i>
                                </span>
                      <span class="navi-text">Generate fee</span>
                    </a>
                  </b-dropdown-text>-->
                  <b-dropdown-text tag="div" class="navi-item"  v-if="!this.edit && checkIsAccessible('academic-class', 'edit')">
                    <a class="ml-2 navi-link" @click="open()" >
                                <span class="navi-icon">
                                    <i class="fa fa-pencil"></i>
                                </span>
                      <span class="navi-text">Edit info</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-class', 'edit') && academic_class && !academic_class.is_complete">
                    <a class="ml-2 navi-link" @click="completeClass()" >
                                <span class="navi-icon">
                                    <i class="fa fa-check"></i>
                                </span>
                      <span class="navi-text">Mark as complete</span>
                    </a>
                  </b-dropdown-text>

                  <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-class', 'upgrade-class-room')" >
                    <a class="ml-2 navi-link" @click="upgradeClass" >
                                <span class="navi-icon">
                                    <i class="fa fa-user-graduate"></i>
                                </span>
                      <span class="navi-text">Upgrade class</span>
                    </a>
                  </b-dropdown-text>


                </div>
                <!--end::Navigation-->
              </b-dropdown>

            </v-col>
          </v-row>
        </div>
        <div class="card-toolbar text-right">

<!--          <v-btn-->
<!--              class="btn btn-primary text-white mx-2"-->
<!--              v-if="!academic_class.is_confirmed && checkIsAccessible('academic-class', 'confirm-class')" depressed-->
<!--              @click="confirmClass(true)" title="Confirm class">-->
<!--            Confirm class-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--              class="btn btn-primary text-white mx-2"-->
<!--              v-if="academic_class.is_confirmed && checkIsAccessible('academic-class', 'confirm-class')"-->
<!--              @click="confirmClass(false)" title="Cancel confirm class">-->
<!--            Cancel confirm class-->
<!--          </v-btn>-->

<!--          <v-btn v-if="checkIsAccessible('academic-class', 'generate-fee')" class="btn btn-primary text-white mx-2"-->
<!--                 depressed @click="generateFee()" title="Generate fee">-->
<!--            Generate fee-->
<!--          </v-btn>-->
<!--          <v-btn class="mx-2" fab small @click="open()" title="Edit Class"-->
<!--                 v-if="!this.edit && checkIsAccessible('academic-class', 'edit')">-->
<!--            <v-icon dark style="color: orange">-->
<!--              mdi-pencil-->
<!--            </v-icon>-->
<!--          </v-btn>-->

<!--          <v-btn v-if="checkIsAccessible('academic-class', 'upgrade-class-room')" class="mx-2" fab small-->
<!--                 @click="upgradeClass" title="Upgrade Class">-->
<!--            <v-icon dark style="color: green">-->
<!--              mdi-arrow-up-->
<!--            </v-icon>-->
<!--          </v-btn>-->

        </div>

      </div>
      <v-card-text>
        <form class="form">
          <div class="card-body" v-if="this.edit!=true">
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Academic year:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.academic_year.title"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.academic_year.title }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Level:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.level.title"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.level.title }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Program:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.program.title"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.program.title }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Semester/year:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.grade.title"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.grade.title }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Shift:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.shift"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.shift }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Room:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.rooms"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.rooms.title }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label text-right">Running mode:</label>
              <div class="col-lg-9 col-xl-6">
                <a v-if="academic_class.running_mode"
                   class="font-size-h6 text-dark-75 text-hover-primary">{{ academic_class.running_mode }}</a>
                <a v-else class="font-size-h6 text-dark-75 text-hover-primary">N/A</a>
              </div>
            </div>

          </div>
        </form>

        <div class="card-body" v-if="this.edit">

          <div class="row">
            <div class="col-6">
              <v-select
                  :items="academicYears"
                  v-model="academic_class.academic_year_id"
                  outlined
                  dense
                  item-text="year"
                  item-value="id"
                  label="Batch"
              >
              </v-select>
              <span class="text-danger" v-if="$v.academic_class.level_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="levels"
                  v-model="academic_class.level_id"
                  outlined
                  dense
                  item-text="title"
                  item-value="id"
                  label="Level"
                  @change="getPrograms"
              >

              </v-select>
              <span class="text-danger" v-if="$v.academic_class.level_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="programs"
                  v-model="academic_class.program_id"
                  outlined
                  dense
                  label="Program"
                  item-text="title"
                  item-value="id"
                  @change="getGrades()"
              >

              </v-select>
              <span class="text-danger"
                    v-if="$v.academic_class.program_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select
                  :items="gradesLevels"
                  v-model="academic_class.grade_id"
                  outlined
                  dense
                  label="Semester/year"
                  item-text="title"
                  item-value="id"
              >

              </v-select>
              <span class="text-danger"
                    v-if="$v.academic_class.grade_id.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-text-field outlined dense v-model="academic_class.title"
                            placeholder="Class title"></v-text-field>
              <span class="text-danger"
                    v-if="$v.academic_class.title.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select :items="['morning', 'day', 'evening', 'night']" v-model="academic_class.shift"
                        outlined dense placeholder="Class shift"></v-select>
              <span class="text-danger" v-if="$v.academic_class.shift.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-select :items="rooms" item-text="title" item-value="id" v-model="academic_class.room_id"
                        outlined dense placeholder="Class type " label="Room"></v-select>
            </div>
            <div class="col-6">
              <v-select :items="running_mode" item-value="value" item-text="title"
                        v-model="academic_class.running_mode"
                        outlined dense placeholder="Running mode" label="Running mode"></v-select>
            </div>
            <div class="col-6" v-if="academic_class.running_mode =='online'">
              <v-text-field outlined dense v-model="academic_class.online_class_url"
                            placeholder="Online class URL" label="Online class URL"></v-text-field>

            </div>
            <div class="col-6" v-if="academic_class.running_mode =='online'">
              <v-select :items="online_class_type" item-value="value" item-text="title"
                        v-model="academic_class.online_class_type"
                        outlined dense placeholder="Class type " label="Class type"></v-select>
            </div>
            <div class="col-6">
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="academic_class.start_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="academic_class.start_date"
                      label="Commencement date"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="academic_class.start_date"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(academic_class.start_date)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!--          <span class="text-danger" v-if="$v.academic_class.program_id.$error">Start Date is required</span>-->
            </div>
<!--            <div class="col-6">
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="academic_class.end_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="academic_class.end_date"
                      label="Class completion date"
                      prepend-inner-icon="mdi-calendar"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="academic_class.end_date"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(academic_class.end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              &lt;!&ndash;          <span class="text-danger" v-if="$v.exam.program_id.$error">End Date is required</span>&ndash;&gt;
            </div>

            &lt;!&ndash;        <pre>{{academic_class}}</pre>&ndash;&gt;
            <div class="md-6">
              <v-select v-model="academic_class.is_complete" outlined dense label="Status" :items="[{text:'Running',value:0},{text:'Completed',value:1}]" item-text="text" item-value="value">

              </v-select>
            </div>-->
            <div class="col-12">
              <v-switch
                  v-model="academic_class.is_active"
                  label="Status"
              >
              </v-switch>
            </div>

            <div class="card-toolbar text-right mt-10" v-if="this.edit && checkIsAccessible('academic-class','delete')">
              <v-btn depressed class="text-gray btn btn-standard cancel-btn" @click="close()">
                Cancel
              </v-btn>
              <v-btn class="btn btn-primary btn-search " @click.prevent="createAndUpdate()">
                Save
              </v-btn>

            </div>
            <div class="col-12 text-right mt-4">
              <!--                        <v-btn
                                              class="btn btn-standard"
                                              depressed
                                              @click="resetForm"
                                      >Cancel
                                      </v-btn>-->
            </div>
          </div>

        </div>
      </v-card-text>
    </div>
    <generate-fee ref="generate-fee" :academic_class="academic_class" :dialog="generate_fee_dialog"
                  @close_dialog="closeGenerateFee"></generate-fee>
    <complete-exam  ref="complete-exam" :academic_class="academic_class"></complete-exam>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import RoomsService from "@/core/services/rooms/RoomsService";
import GenerateFee from "@/view/pages/academic-class/summary/GenerateFee";
import CompleteExam from "@/view/pages/CompleteExam.vue";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const roomService = new RoomsService();

export default {
  name: "academic_class-create-and-update",
  props: ['academicClass'],
  validations: {
    academic_class: {
      level_id: {required},
      program_id: {required},
      title: {required},
      shift: {required},
      grade_id: {required}
    },

    academic_class_upgrade: {
      title: {required},
      shift: {required},
      grade_id: {required}
    }
  },
  components: {
    GenerateFee,CompleteExam
  },
  data() {
    return {
      dialog: false,
      menu: false,
      menu2: false,
      isBusy: false,
      edit: false,
      generate_fee_dialog: false,
      levels: [],
      programs: [],
      academicYears: [],
      rooms: [],
      running_mode: [
        {
          'title': 'Online',
          'value': 'online',
        },
        {
          'title': 'Campus',
          'value': 'campus',
        }
      ],
      online_class_type: [
        {
          'title': 'Zoom',
          'value': 'zoom',
        },
        {
          'title': 'Microsoft Team',
          'value': 'teams',
        },
        {
          'title': 'Google Meet',
          'value': 'meet',
        }
      ],
      gradesLevels: [],
      academic_class: {
        level_id: null,
        program_id: null,
        grade_id: null,
        shift: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      },
      academic_class_upgrade: {
        grade_id: null,
        shift: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      }
    };
  },
  mounted() {
    this.academic_class = this.academicClass
    this.getLevels();
    this.getAcademicYears()
    this.getRooms()
  },
  methods: {
    open() {
      this.edit = true;
    },

    close() {
      this.edit = false;
    },

    upgradeClass() {
      this.$router.push({name: "upgrade-class", params: {classId: this.academic_class.id}});
    },
    formatDatePickerWise(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month}-${day}`
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.academic_class.level_id)
          this.getPrograms()
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.academic_class.level_id).then(response => {
        this.programs = response.data;
        if (this.academic_class.program_id)
          this.getGrades()
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;


      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.academic_class.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getRooms() {
      roomService
          .paginate()
          .then(response => {
            this.rooms = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    showModal(academicClass = {}) {
      if (academicClass.id) {
        this.academic_class = academicClass
        this.academic_class.start_date = this.formatDatePickerWise(this.academic_class.start_date);
        this.academic_class.end_date = this.formatDatePickerWise(this.academic_class.end_date);
        this.edit = true
        this.getPrograms();
        this.getGrades();

      } else {
        this.edit = false;
        this.resetForm();
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.dialog = false;
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.academic_class.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.academic_class.id) {
          this.updateAcademicClass();
        } else {
          this.createAcademicClass();
        }
      }
    },
    createAcademicClass() {
      this.isBusy = true;
      academicClassService.store(this.academic_class).then(response => {
        this.isBusy = false;
        this.isBusy = false;
        this.resetForm()
        this.$emit("refresh");
        this.$snotify.success("Information added");
        this.$tabs.close({name: "academic-class"});
      });
    },
    confirmClass(flag) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.academic_class.is_confirmed = flag;
            this.toogleClassConfirm();
          }
        }
      });

    },
    toogleClassConfirm() {
      this.isBusy = true;
      academicClassService.toogleClassConfirm(this.academic_class.id, this.academic_class).then(response => {
        this.isBusy = false;
        this.edit = false;
        // this.resetForm()
        this.$emit("refresh");
        this.$snotify.success("Information updated");


      }).finally(() => {
        this.isBusy = false
      });
    },
    updateAcademicClass() {
      this.isBusy = true;
      academicClassService.update(this.academic_class.id, this.academic_class).then(response => {
        this.isBusy = false;
        this.edit = false;
        // this.resetForm()
        this.$emit("refresh");
        this.$snotify.success("Information updated");
        this.$tabs.close().then(response => {
          this.$router.push({name: "academic-class"})
        });

      }).finally(() => {
        this.isBusy = false
      });
    }, resetForm() {
      this.academic_class = {
        level_id: null,
        program_id: null,
        title: null,
        running_mode: "campus",
        start_date: null,
        end_date: null,
        is_active: true
      }
      this.edit = false
      this.$v.$reset()
      this.hideModal()

    },
    closeGenerateFee() {
      this.generate_fee_dialog = false;
    },
    generateFee() {
      this.generate_fee_dialog = true;
      this.$refs['generate-fee'].getStudentFeeByProgram();
    },
    completeClass(){
      this.$refs['complete-exam'].completeClassStatus();
    },
  },
};
</script>
<style>
.v-application--wrap {
  min-height: 0 !important;
}
</style>
