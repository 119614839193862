import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class StudentGradeService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;
    }

    getCurrentStudentGrade(userId) {
        let url = `${this.#api}/${userId}/grade`;
        return apiService.get(url);
    }

    saveOrUpdateUserCurrentCourses(userId, classId, data) {
        let url = `${this.#api}/${userId}/grade/${classId}`;
        return apiService.post(url, data);
    }

    removeStudentFromClass(userId, classId) {
        let url = `${this.#api}/${userId}/grade/remove/${classId}`;
        return apiService.get(url);
    }

    removeAllStudentFromClass(classId, data) {
        let url = `${this.#api}/grade/remove/${classId}/all`;
        return apiService.post(url, data);
    }
    getStudentCustomizeGrade(userId){
        let url = `${this.#api}/customize/student-grade/${userId}`;
        return apiService.get(url);
    }

}