<template>
  <div>
    <table class="table bg-white">

      <tr v-if="rooms.length > 0" v-for="(room, index) of rooms"
          :key="index">
        <td><h6 class="mb-0">{{ room.title }}
          <span v-if="room.online_medium=='zoom' || room.online_medium=='Zoom'"><img
              src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
          <span v-if="room.online_medium=='Zoom Link'"><img
              src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
          <span v-if=" room.online_medium=='Meet' || room.online_medium=='meet'"><img
              src="https://img.icons8.com/color/48/000000/google-meet.png"/></span>
          <span v-if="room.online_medium=='Teams' || room.online_medium=='teams'"><img
              src="https://img.icons8.com/color/48/000000/microsoft-teams.png"/></span>

        </h6>
          {{ room.start_from }} - {{ room.end_to }}<br/>
          {{ room.formatted_date }} <br/>
          {{ room.linked_teacher }}
        </td>
        <td>
          {{ room.linked_classes }} <br/>
          Year 1
          BIT - Batch 2020

        </td>
        <td>
          <a v-if="room.class_status === 'scheduled'"  target="_blank" class="btn btn-primary text-white p-1"
             variant="success">Scheduled</a>
          <a v-if="room.class_status === 'running' && room.is_started"  target="_blank"
             class="btn btn-danger text-white p-1"
             variant="red">Running</a>
          <a v-if="room.class_status === 'running' && !room.is_started"  target="_blank" class="btn btn-danger text-white p-1"
             variant="red">Ready to Join</a>

          <a v-if="room.class_status === 'completed'"  target="_blank" class="btn btn-success text-white p-1"
             variant="primary">Completed</a>
          <br>
          <span v-if="room.class_status != 'completed'" class="mt-3">{{ room.class_started_at }}</span>
          <span v-else class="mt-3">{{ room.class_ended_at }}</span>
        </td>

        <td>
          <a v-if="room.class_status === 'completed'" href="#" @click="viewOnlineAttendance(room)">View
            Attendees[{{ room.total_attendee }}]</a> <br/>
          <a v-if="room.class_status === 'scheduled'" href="">Edit Classroom</a> <br/>
          <a v-if="room.class_status === 'scheduled'" href="">Cancel Class</a> <br/>

        </td>

        <td>
          <code v-if="room.host_id"
                style="color:white; background: green; font-family: 'Poppins'; font-size:13px; margin-bottom: 2px;">Host:
            1234 363 456</code> <small v-if="room.host_id">
          <a href="#" v-clipboard:copy="room.host_id"
             v-clipboard:success="onCopy"
             v-clipboard:error="onError">Copy</a></small> <br/><br/>
          <code v-if="room.passcode"
                style="color:white; background: green; font-family: 'Poppins'; font-size:13px; margin-top: 2px;">Password:
            {{ room.passcode }}</code>
          <small v-if="room.passcode">
            <a href="#"
               v-clipboard:copy="room.passcode"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
            >Copy</a></small><br/>
        </td>
        <td>
          <a  v-if="room.class_status === 'running' && !room.is_started" :loading="loading" href="#" class="btn btn-primary"
             variant="primary" @click="prepareAndJoin(room.online_class_room_id, room.id)">Prepare and Join</a>
        </td>
      </tr>

      <tr v-if="rooms.length == 0">
        <td colspan="8">
          Data not available
        </td>
      </tr>
    </table>
    <online-class-attendance v-if="room_id && attendance_dialog"
                             ref="online-class-attendance"
                             :attendance_dialog="attendance_dialog"
                             :room="room"
                             :room_id="room_id"
                             :course_id="course_id"
                             @close_dialog="closeDialog"
    >
    </online-class-attendance>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <!--      <v-btn color="blue" text @click="snackbar = false">Close</v-btn>-->
    </v-snackbar>
  </div>
</template>

<script>
import OnlineClassAttendance from "@/view/component/online-class-room/OnlineClassAttendance";
import OnlineClassRoomsService from "@/core/services/online-class-rooms/OnlineClassRoomsService";
import OnlineClassRoomMixin from "@/mixins/OnlineClassRoomMixin";

const onlineClassRoom =  new OnlineClassRoomsService();

export default {
  name: "OnlineClassRoomList",
  props: ['rooms'],
  mixins: [OnlineClassRoomMixin],
  components: {
    OnlineClassAttendance
  },
  data() {
    return {
      timeout: 2000,
      snackbar: false,
      loading: false,
      text: null,
    }
  },
  methods: {
    onCopy: function (e) {
      this.text = "Copied ";
      this.snackbar = true;
    },
    onError: function (e) {
      this.text = "Failed to copy";
      this.snackbar = true;
    },
    prepareAndJoin(roomId, detailId){
      this.loading = true
      onlineClassRoom.prepareAndJoin(roomId, detailId).then(response=>{
        this.loading = false
        if (!response.data){
          this.$snotify.error("Something went wrong")
        }else{
          setTimeout(()=>{
            window.open(response.data.meeting_url, '_blank')
          }, 3000)
          this.$emit('refresh_list');
        }
      }).catch(error=>{
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>