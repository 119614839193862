<template>
  <v-card>
    <v-card-title>
      Add attendance
    </v-card-title>
    <v-card-text>
      <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
      <v-row>

        <v-col cols="2">
          <v-select
              item-text="title"
              item-value="value"
              v-model="selected_attendance_type"
              outlined
              :items="attendance_types"
              label="Attendance type"
              dense
              @change="fetchData"
          >
          </v-select>
        </v-col>

        <v-col cols="2">
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :error="$v.search.date.$error"
                  v-model="search.date"
                  prepend-inner-icon="mdi-calendar"
                  prepend-icon=""
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined dense
                  label="Date"
              ></v-text-field>
            </template>
            <v-date-picker @change="fetchData"
                           v-model="search.date"
                           @input="menu1 = false"
                           :max="today"
            ></v-date-picker>
          </v-menu>
          <span class="text-danger" v-if="$v.search.date.$error">This information is required</span>
        </v-col>

        <v-col cols="3">
          <v-autocomplete
              :error="$v.search.teacher_id.$error"
              :items="assigned_teachers"
              item-text="full_name"
              item-value="id"
              outlined
              label="Select teacher"
              v-model="search.teacher_id"
              dense
              @change="fetchData()"
          >
          </v-autocomplete>
          <span class="text-danger" v-if="$v.search.teacher_id.$error">This  is required</span>
        </v-col>


        <v-col cols="3" v-if="selected_attendance_type=='course'">
          <v-select
              :error="selected_attendance_type=='course' && $v.search.course_id.$error"
              :items="courses"
              item-text="title"
              item-value="id"
              outlined
              label="Select course"
              v-model="search.course_id"
              dense

          >
          </v-select>
          <span class="text-danger"
                v-if="$v.search.course_id.$error && !show_period">Course is required</span>
        </v-col>

        <v-col cols="3" v-if="selected_attendance_type=='period'">
          <v-select
              :error="selected_attendance_type=='period' && $v.search.timetable_period_id.$error"
              :items="academicClassTimetablePeriods"
              item-text="period_name"
              item-value="id"
              outlined
              label="Select period"
              v-model="search.timetable_period_id"
              dense
          >
          </v-select>
          <span class="text-danger" v-if="$v.search.timetable_period_id.$error && show_period">Period is required</span>
        </v-col>


        <v-col cols="1 vbn text-right">
          <v-btn depressed class="btn btn-primary text-white" @click="getStudentAttendance">
            Filter
          </v-btn>
          <!--          <v-btn v-if="attendanceReports && attendance_mode=='get'" depressed class="btn btn-success ml-2"-->
          <!--                 @click="exportReport">-->
          <!--            Export-->
          <!--          </v-btn>-->
        </v-col>
      </v-row>
      </div>
      <v-row v-if="selected.length>0">
        <v-col cols="12">
          <v-card flat color="grey lighten-4">
            <v-toolbar flat color="grey lighten-4">
              <v-toolbar-title>Total Selected : {{ selected.length }}
              </v-toolbar-title>
              <v-toolbar-title class="ml-5"> Actions :</v-toolbar-title>
              <!--                                            <v-btn depressed @click="addStudents" >-->
              <!--                                                <v-icon>assignment_ind</v-icon>-->
              <!--                                                Add-->

              <!--                                            </v-btn>-->
              <v-radio-group row v-model="attendance"
                             @change="setAllAttendance()">

                <v-radio value="p">
                  <template v-slot:label>
                    Present
                  </template>
                </v-radio>
                <v-radio value="a">
                  <template v-slot:label>
                    Absent
                  </template>
                </v-radio>
                <v-radio value="pl">
                  <template v-slot:label>
                    Late
                  </template>
                </v-radio>
                <v-radio value="ph">
                  <template v-slot:label>
                    Half Day
                  </template>
                </v-radio>
              </v-radio-group>

              <v-radio-group
                  v-if="attendance_type == 'day'"
                  row v-model="day_attendance_type"
                  @change="setAllAttendanceCheckType()">

                <v-radio value="in">
                  <template v-slot:label>
                    Check In
                  </template>
                </v-radio>
                <v-radio value="out">
                  <template v-slot:label>
                    Check Out
                  </template>
                </v-radio>

              </v-radio-group>
              <v-btn @click="saveAttendance()" depressed :loading="isLoading"
                     class="text-white btn btn-primary">
                SAVE
              </v-btn>
              <v-btn @click="refreshFrom()" depressed
                     class="ml-3 text-gray btn btn-standard">
                RESET
              </v-btn>


              <!--                                            <v-btn icon>-->
              <!--                                                <v-icon>mdi-heart</v-icon>-->
              <!--                                            </v-btn>-->

              <!--                                            <v-btn icon>-->
              <!--                                                <v-icon>mdi-dots-vertical</v-icon>-->
              <!--                                            </v-btn>-->

            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-skeleton-loader v-if="loading"
                         v-bind="attrs"
                         type="date-picker"
      ></v-skeleton-loader>
      <div v-else>
        <v-row v-if="users && users.length">
          <div class="col-12">
            <table class="table table-stripe">
              <thead>
              <th class="text-center">
                <v-checkbox @change="selectAll()" v-model="checkAll"  outlined dense
                ></v-checkbox>
              </th>
              <th class="text-center">Image</th>
              <th class="text-center">CRN</th>
              <th class="text-center">Attendance</th>
              <th class="text-center">Remark</th>
              </thead>
              <tbody>
              <tr v-for="(user, index) of users" :key="index">
                <td>
                  <v-checkbox v-model="selected" :value="user.student_id" outlined
                              dense
                              v-bind:label="(user.full_name)?user.full_name:null"></v-checkbox>
                </td>
                <td>
                  <div class="symbol-label">
                    <img v-if="user.photo"
                         :src="user.image_path.thumb"
                         class="cursor-pointer" alt=""
                         style="height: 30px;">
                    <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                        class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                    > {{
                                                        (user.full_name) ? user.full_name.charAt(0) : null
                                                      }} </span>
                                                </span>
                  </div>
                </td>

                <td>{{ user.crn_number ? user.crn_number : "N/A" }}
                </td>
                <td>
                  <v-radio-group row v-model="user.attendance"
                                 @change="setAttendance(index)">

                    <v-radio value="p">
                      <template v-slot:label>
                        Present
                      </template>
                    </v-radio>
                    <v-radio value="a">
                      <template v-slot:label>
                        Absent
                      </template>
                    </v-radio>
                    <v-radio value="pl">
                      <template v-slot:label>
                        Late
                      </template>
                    </v-radio>
                    <v-radio value="ph">
                      <template v-slot:label>
                        Half Day
                      </template>
                    </v-radio>


                  </v-radio-group>
                </td>

                <td>
                  <v-text-field
                      dense
                      v-model="user.remark"
                      outlined
                      label="Remark"
                  ></v-text-field>
                </td>


              </tr>
              </tbody>
            </table>

          </div>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {API_URL} from "@/core/config";
import AttendanceService from "@/core/services/attendance/AttendanceService";
import AcademicCLassTimetablePeriodService
  from "@/core/services/academicClassTimetablePeriod/AcademicCLassTimetablePeriodService";
import {required} from "vuelidate/lib/validators";
import {AttendanceMixin} from "@/mixins/AttendanceMixin";
import AcademicClassTeacherService from "@/core/services/academic-class/class-teacher/AcademicClassTeacherService";

const attendanceService = new AttendanceService();
const academicCLassTimetablePeriod = new AcademicCLassTimetablePeriodService();
const academicClassTeacher = new AcademicClassTeacherService();

export default {
  name: "TakeAttendance",
  props: ['academic_class', 'timetable', 'assigned_teachers'],
  mixins: [AttendanceMixin],
  validations() {
    return {
      search: this.search_rule
    }
  },
  data() {
    return {

      attendance_types:[
        {
          title: "Subject",
          value: 'course'
        },
        {
          title: "Period",
          value: 'period'
        },
      ],
      selected_attendance_type:'period',
      attendee: {},
      attrs: {},
      loading: false,
      isLoading: false,
      attendance_type: false,
      show_period: true,
      menu1: false,
      checkAll: false,
      attendance: null,
      academicClassTimetablePeriods: [],
      assigned_courses: [],
      selected: [],
      users: [],
      courses: [],
      teachers: [],
      search: {
        academic_class_id: null,
        day_attendance: false,
        time_table_id: "",
        timetable_period_id: "",
        course_id: "",
        level_id: null,
        program_id: null,
        academic_year_id: null,
        year: 2021,
        month: 8,
        grade_id: null
      },
    }
  },
  mounted() {

  }, computed: {
    search_rule() {
      let rule = {
        date: {required},
        teacher_id: {required}
      }
      if (this.selected_attendance_type=='course')
        rule.course_id = {required}
      else
        rule.timetable_period_id = {required}
      return rule
    },
    today() {
      return this.$moment(new Date).format('YYYY-MM-DD');
    },
  },
  methods: {
    getStudentAttendance() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
      } else {
        this.loading = true
        let attendance_type = this.academic_class.program.attendance_type;
        let month = this.search.month;
        this.search.academic_year_id = this.academic_class.academic_year.id;
        this.search.time_table_id = this.timetable.id;
        this.search.academic_class_id = this.academic_class.id;
        this.search.program_id = this.academic_class.program_id;
        this.search.grade_id = this.academic_class.grade_id;
        this.search.attendance_by = this.selected_attendance_type;
        if (this.search.attendance_by == "course") {
          this.search.time_table_id = null
          this.search.timetable_period_id = null
        } else {
          this.search.course_id = null;
          this.search.time_table_id = this.timetable.id;
        }
        this.search.month = this.search.month.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        attendanceService.index(this.search).then(response => {
          this.loading = false
          this.refreshFrom();
          this.attendee = response.data.attendance
          this.users = this.attendee.attendees
          this.attendance_mode = 'take';
        }).catch(error => {
          this.$snotify.error("Something went wrong")
          this.loading = false
        })
      }
    },
    getTimeTablePeriodBasedOnDate() {
      academicCLassTimetablePeriod.getTimeTableBasedOnDate({
        timetable_id: this.timetable.id,
        date: this.search.date,
        teacher_id: this.search.teacher_id
      }).then((resources) => {
        this.refreshFrom();
        this.academicClassTimetablePeriods = resources.data.time_table_periods;
        this.attendee = {}
        this.attendee.attendances = []
        this.search.timetable_period_id = null
      }).catch(err => {
      })
    },


    exportReport() {
      let month = this.search.month;
      this.search.academic_year_id = this.academic_class.academic_year.id;
      this.search.academic_class_id = this.academic_class.id;
      this.search.program_id = this.academic_class.program_id;
      this.search.grade_id = this.academic_class.grade_id;
      this.search.month = this.search.month.toLocaleString('en-US', {
        //this is the function that formats the numbers
        //change this to your minimum length
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let url = API_URL + "attendance-export-csv"
      if (this.search) {
        let query = new URLSearchParams(this.search).toString();
        if (query)
          url = url + '?' + query;
      }
      window.open(url)

    },

    saveAttendance() {
      this.isLoading = true;
      attendanceService.store(this.attendee).then((response) => {
        this.$snotify.success('Information updated');
        this.getStudentAttendance()
        this.refreshFrom();
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      })
    },
    refreshFrom() {
      this.selected = []
      this.checkAll = false
      this.attendance = null
      this.setAllAttendance();
    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user.student_id);
        })
      }
    },
    getSelected() {
      let selected = []
      this.selected.forEach(studentId => {
        this.attendanceStudents.forEach(user => {
          if (studentId == user.student_id) {
            selected.push(user)
          }
        })
      })
      return selected;
    },
    setAllAttendance() {
      let present = null;
      let half_day = null;
      let late = null;
      switch (this.attendance) {
        case 'p' :
          present = 1;
          break;
        case 'a' :
          present = 0;
          break;
        case 'pl' :
          present = 1;
          late = 1;
          break;
        case 'ph' :
          present = 1;
          half_day = 1;
          break;
        default:
          present = 0;
          break;
      }

      this.users.forEach((user) => {
        user.attendance = this.attendance;
        user.present = present;
        user.half_day = half_day;
        user.late = late;
      })
    },
    getCoursesByTeacher() {
      academicClassTeacher
          .getCoursesByUserId(this.search.teacher_id)
          .then((response) => {
            this.courses = response.data.courses;
          });
    },
    fetchData() {
      if (this.selected_attendance_type=='period' && this.search.teacher_id) {
        this.getTimeTablePeriodBasedOnDate();
      }
      if (this.selected_attendance_type=='course') {
        this.getCoursesByTeacher()
      }
    }
  }
}
</script>

<style scoped>

</style>
