<template>
  <v-app>
    <div class="">
      <table class="table">
        <thead>
          <tr class="px-3">
            <th class="px-3 wrap-column" colspan="2">Teacher</th>
            <th class="px-3 wrap-column">Subject</th>
            <th class="px-2 text-center">Action</th>
          </tr>
        </thead>
        <tr v-for="(item, index) of assigned_teachers" :key="index">
          <td class="px-3 wrap-column">
            <div class="symbol-label">
              <img
                v-if="item.teacher.photo"
                :src="item.teacher.image_path.thumb"
                class="cursor-pointer"
                alt=""
                style="height: 30px"
              />
              <span v-else class="symbol symbol-35 symbol-light-success">
                <span class="symbol-label font-size-h5 font-weight-bold cursor-pointer">
                  {{ item.teacher.full_name.charAt(0) }}
                </span>
              </span>
            </div>
          </td>
          <td class="px-3 wrap-column">
            <a
              @click="summaryPage(item)"
              class="text-primary font-weight-bolder text-hover-primary mb-1"
            >
              {{ item.teacher.full_name }}
            </a>
            <div class="text-secondary">
              {{ item.teacher.personal_id }}
            </div>
            <div class="text-secondary">
              {{ item.teacher.mobile }}
            </div>
            <div class="text-secondary">
              {{ item.teacher.email }}
            </div>
          </td>
          <td class="px-2 wrap-column">
            <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
              {{ item.course ? item.course.title : 'N/A' }}
            </span>

            <div class="mt-2 mt-2">
              <strong>Total class: </strong>
              <span class="badge badge-info">{{ item.teacher_total_class }}</span>
            </div>
          </td>

          <td class="pr-0 text-center">
            <template>
              <b-dropdown
                size="sm"
                variant="link"
                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                no-caret
                right
                no-flip
              >
                <template v-slot:button-content>
                  <i class="ki ki-bold-more-hor"></i>
                </template>
                <!--begin::Navigation-->
                <div class="navi navi-hover min-w-md-250px">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item"
                    v-if="checkIsAccessible('academic-class-teacher', 'delete')"
                  >
                    <a @click="__deleteTeacher(item.id)" class="navi-link">
                      <span class="navi-icon">
                        <i class="fa fa-trash"></i>
                      </span>
                      <span class="navi-text">Unassign</span>
                    </a>
                  </b-dropdown-text>
                </div>
                <!--end::Navigation-->
              </b-dropdown>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <!--        <v-data-table
                :loading="isLoading"
                :headers="teacher_headers"
                :items-per-page="20"
                :items="assigned_teachers"
        >
            <template v-slot:item.thumbnail="{ item }">
                <div class="symbol-label">
                    <img v-if="item.teacher.photo" :src="item.teacher.image_path.thumb" class="cursor-pointer"
                         alt=""
                         style="height: 30px;">
                    <span v-else class="symbol symbol-35 symbol-light-success">
                                                    <span
                                                            class="symbol-label font-size-h5 font-weight-bold cursor-pointer"
                                                    >
                                                        {{item.teacher.full_name.charAt(0)}} </span>
                                                </span>
                </div>
            </template>
            <template v-slot:item.teacher.full_name="{ item }">
                <router-link v-if="item.teacher.type"
                             :to="{name:'students-summary', params:{id:item.teacher.id, type:item.teacher.type[0]}}">
                    {{ item.teacher.full_name }}
                </router-link>
                <br/>{{ item.teacher.personal_id }}
                <br/>{{ item.teacher.mobile }}
                <br/>{{ item.teacher.email }}
            </template>
          <template v-slot:item.teacher_total_class="{ item }">
              <span class="badge badge-primary">{{item.teacher_total_class}}</span>
            </template>
            <template v-slot:item.action="{ item,index }">
                                          <span v-if="checkIsAccessible('academic-class-teacher', 'delete')" class="navi-icon cursor-pointer" @click="__deleteTeacher(item.id)">
                                                              <i class="fas fa-trash"></i> Remove
                                                            </span>
&lt;!&ndash;                <span class="navi-icon cursor-pointer ml-3" @click="assignLessonPlan(index)">&ndash;&gt;
&lt;!&ndash;                                                             <i title="  Assign Lesson Plan "&ndash;&gt;
&lt;!&ndash;                                                                class="fas flaticon2-open-text-book"></i>&ndash;&gt;
&lt;!&ndash;                                                            </span>&ndash;&gt;
                &lt;!&ndash;            __deleteCourse&ndash;&gt;
            </template>
        </v-data-table>-->
    <!--        <v-dialog v-model="dialog" width="400"-->
    <!--        >-->
    <!--            <v-card>-->
    <!--                <v-card-title>-->
    <!--                    Assign Lesson Plan-->
    <!--                </v-card-title>-->
    <!--                <v-card-text>-->
    <!--                    <v-autocomplete-->
    <!--                            outlined-->
    <!--                            dense-->
    <!--                            v-model="class_teacher.lesson_plan_id"-->
    <!--                            item-text="title"-->
    <!--                            item-value="id"-->
    <!--                            label="Select Lesson Plan"-->
    <!--                            :items="lesson_planners"-->
    <!--                    >-->

    <!--                    </v-autocomplete>-->
    <!--                </v-card-text>-->
    <!--                <v-card-actions>-->
    <!--                    <v-spacer></v-spacer>-->
    <!--                    <v-btn-->
    <!--                            class="btn btn-standard"-->
    <!--                            text-->
    <!--                            @click="dialog = false"-->
    <!--                    >-->
    <!--                        Close-->
    <!--                    </v-btn>-->
    <!--                    <v-btn-->
    <!--                            class="btn btn-primary text-white"-->
    <!--                            text-->
    <!--                            @click="save"-->
    <!--                    >-->
    <!--                        Save-->
    <!--                    </v-btn>-->
    <!--                </v-card-actions>-->
    <!--            </v-card>-->
    <!--        </v-dialog>-->
  </v-app>
</template>

<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";
import AcademicClassTeacherService from "@/core/services/academic-class/class-teacher/AcademicClassTeacherService";
const academicClassService = new AcademicClassService();
const lessonPlannerService = new LessonPlannerService();
const academicClassTeacherService = new AcademicClassTeacherService();
export default {
  name: "class-summary-teachers",
  props: ["assigned_teachers", "isLoading"],
  data() {
    return {
      teachers: [],
      lesson_planners: [],
      class_teacher: {
        lesson_plan_id: null,
        course_id: null,
        teacher_id: null,
        id: null,
      },
      dialog: false,
      teacher_headers: [
        {
          text: "Image",
          align: "start",
          filterable: false,
          sortable: false,
          value: "thumbnail",
        },
        { text: "Name", value: "teacher.full_name", filterable: true, sortable: true },
        { text: "Subject", value: "course.title", filterable: false, sortable: true },
        {
          text: "Total Class",
          value: "teacher_total_class",
          filterable: false,
          sortable: true,
        },
        { text: "Action", value: "action", filterable: false, sortable: true },
      ],
    };
  },
  computed: {
    academic_class_id() {
      return this.$route.params.classId;
    },
  },
  mounted() {
    // this.__getLessonPlanner()
  },
  methods: {
    summaryPage(item) {
      this.$router.push({
        name: "students-summary",
        params: {
          id: item.teacher.id,
          type: item.teacher.type[0],
        },
      });
    },
    // __getLessonPlanner() {
    //     lessonPlannerService
    //         .paginate()
    //         .then(response => {
    //             this.lesson_planners = response.data.data;
    //         })
    //         .catch(error => {
    //             // console.log(error);
    //         });
    // },
    __deleteTeacher(id) {
      this.$confirm({
        message: `Confirm unassign this teacher?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            academicClassTeacherService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information updated");
              })
              .catch((error) => {})
              .finally(() => {
                this.$emit("refresh");
              });
            // this.teachers = this.assigned_teachers;
            // this.teachers.splice(id, 1);
            // this.teachers = this.teachers.map(teacher => {
            //     let data = {
            //         class_id: this.academic_class_id,
            //         teacher_id: teacher.teacher_id,
            //         course_id: teacher.course_id,
            //     }
            //     return data;
            // })
            //
            // academicClassService.syncTeachers(this.academic_class_id, this.teachers).then((response) => {
            //     this.$emit("refresh");
            //     this.$snotify.success("Removed successfully");
            // });
          }
        },
      });
    },
    // save() {
    //     this.$confirm({
    //         message: `Are you sure you want to delete this item?`,
    //         button: {
    //             no: "No",
    //             yes: "Yes"
    //         },
    //         /**
    //          * Callback Function
    //          * @param {Boolean} confirm
    //          */
    //         callback: confirm => {
    //             if (confirm) {
    //                 academicClassTeacherService.update(this.class_teacher.id, this.class_teacher).then((response) => {
    //                     this.$snotify.success("Information updated");
    //                 });
    //             }
    //         }
    //     });
    //
    //
    // },
    assignLessonPlan(index) {
      this.dialog = true;
      const { id, teacher_id, course_id, lesson_plan_id } = this.assigned_teachers[index];
      this.class_teacher.id = id;
      this.class_teacher.teacher_id = teacher_id;
      this.class_teacher.course_id = course_id;
      this.class_teacher.lesson_plan_id = lesson_plan_id;
    },
  },
};
</script>

<style scoped></style>
