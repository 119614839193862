import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class StudentFeeService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;

    }

    paginate(data = {}, index = 1) {
        let url = `${this.#api}/student-fee/get/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.get(url);
    }

    all(userId) {
        let url = `${this.#api}/${userId}/student-fee`;
        return apiService.query(url);
    }

    update(studentId, id, data) {
        let url = `${this.#api}/${studentId}/student-fee/${id}`
        return apiService.put(url, data);
    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/student-fee`
        return apiService.post(url, data);

    }

    createInstallment(userId, data) {
        let url = `${this.#api}/${userId}/student-fee/create/installment`
        return apiService.post(url, data);

    }

    show(userId, studentId) {
        let url = `${this.#api}/${userId}/student-fee/${studentId}`
        return apiService.get(url);
    }

    delete(userId, studentId) {
        let url = `${this.#api}/${userId}/student-fee/${studentId}`
        return apiService.delete(url);
    }

    generateFeesForStudent(userId, data) {
        let url = `${this.#api}/student/${userId}/generate/fee`
        return apiService.post(url, data);
    }


    isStudentFeeGenerated(userId, programId, programFeeId) {
        let url = `${this.#api}/${userId}/student-fee/program/${programId}/program-fee/${programFeeId}`
        return apiService.get(url);
    }

    applyDiscount(userId, feeId) {
        let url = `${this.#api}/${userId}/student-fee/${feeId}/apply-discount`
        return apiService.get(url);
    }

    cancelDiscount(userId, feeId) {
        let url = `${this.#api}/${userId}/student-fee/${feeId}/cancel-discount`
        return apiService.get(url);
    }

    createInvoice(studentId, feeId) {
        let url = `${this.#api}/${studentId}/student-fee/${feeId}/create-invoice`
        return apiService.get(url);
    }

    sendInvoice(studentId, feeId) {
        let url = `${this.#api}/${studentId}/student-fee/${feeId}/send-invoice`
        return apiService.post(url);
    }

}
