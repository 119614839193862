import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class LevelService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/level`;
      }

      paginate(data = {}) {
            let url = `${this.#api}`;
            let param ={
                  params: data
            }
            return apiService.query(url,param);
      }
      all() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }
      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data);
      }
      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);
      }
      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }
      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
