<template>
    <v-app>

        <v-dialog
                v-model="dialog"
                width="400"
                title="Add/Update Exam"
        >
            <v-card>
                <v-card-title>
                   Assign teacher
                </v-card-title>
                <v-card-text>
                    <div class="row ">
                        <div class="col-12">
                            <v-autocomplete
                                    v-if="courses && courses.length > 0"
                                    outlined
                                    dense
                                    v-model="course_id"
                                    label="Select course"
                                    :items="courses"
                                    item-text="title"
                                    item-value="id"
                            >
                            </v-autocomplete>
                            <span class="text-danger font-weight-bold" v-if="courses && courses.length == 0">No course assigned. Please add course to assign teacher</span>
                        </div>
                        <div class="col-12">
                            <v-autocomplete
                                    v-model="teacher_id"
                                    outlined
                                    dense
                                    label="Select teachers"
                                    :items="users"
                                    item-text="full_name"
                                    item-value="id"
                                    v-if="courses && courses.length"
                            >
                            </v-autocomplete>
                        </div>

                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            class="btn btn-standard"
                            text
                            @click="dialog = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                            class="btn btn-primary text-white"
                            text
                            @click="save"
                            v-if="courses && courses.length"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </v-app>
</template>
<script>
    import vMultiselectListbox from 'vue-multiselect-listbox'
    import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import LevelService from "@/core/services/level/LevelService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
    import ExamService from "@/core/services/exam/ExamService";
    import UserService from "@/core/services/user/UserService";
    import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";

    const academicClassStudentService = new AcademicClassStudentService()
    const levelService = new LevelService()
    const academicClassService = new AcademicClassService();
    const programService = new ProgramService();
    const academicYearService = new AcademicYearService();
    const gradeLevelService = new GradeLevelService();
    const examService = new ExamService();
    const userService = new UserService();
    export default {
        name: "assignTeacher",

        components: {
        },
        data() {
            return {

                selectedTeachers: [],

                academic_class: {
                  academic_year_id:null,
                  level_id: null,
                  program_id: null,
                  grade_id: null,
                  shift: null,
                  title: null,
                  is_active: true
                },
                date: new Date().toISOString().substr(0, 10),
                dialog: false,
                menu: false,
                edit: false,
                menu2: false,
                users: [],
                programs: [],
                academicYears: [],
                courses: [],
                classes: [],
                class_teachers: [],
                academic_id: null,
                course_id: null,
                teacher_id: null,
                page: null,
                total: null,
                perPage: null,
                tab: null,
            };
        },
        mounted() {

        },
        methods: {
            showModal(academic_class = {}) {
                if (academic_class.id) {

                    this.edit = true
                    this.dialog = true
                    this.academic_class = academic_class;
                    this.getAssignedTeachers();
                    this.getAssignedCourses();
                    this.getTeachers();

                }
            },
            hideModal() {
                this.teacher = []
                this.dialog = false;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.dialog = !dialog;
            },
            getStudents() {
                userService.getStudentsByAcademicYearProgramAndSemester(this.academic_class.academic_year_id,this.academic_class.program_id,this.academic_class.grade_id).then(response => {
                    this.users = response.data.data;

                });
            },
            getTeachers() {
                userService.getTeacherByProgram(this.academic_class.program_id).then(response => {
                    this.users = response.data.users;
                });
            },

          getAssignedTeachers() {
            academicClassService.getAssignedTeachers(this.academic_class.id).then(response => {
              this.selectedTeachers = response.data.teachers;
            });
          },
            getAssignedCourses() {

                academicClassService.getAssignedCourses(this.academic_class.id).then(response => {
                    this.courses = response.data.courses;


                });
            },

            getLevels() {
                levelService.all().then(response => {
                    this.levels = response.data;

                });
            },
            getCourses() {
                examService.getCourseExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
                    this.courses = response.data.courses;
                });
            },
            getClassExam() {
                examService.getClassExam(this.academic_class.program_id, this.academic_class.grade_id).then(response => {
                    this.classes = response.data.class;
                });
            },
            getAcademicYears() {
                academicYearService.paginate().then(response => {
                    this.academicYears = response.data.data;

                });
            },
            getGrades() {
                gradeLevelService.paginate().then(response => {
                    this.gradesLevels = response.data.data;

                });
            },
            save() {
                this.selectedTeachers.push({
                    teacher_id:this.teacher_id,
                    course_id:this.course_id,
                })
                this.selectedTeachers.forEach((user) => {
                    this.class_teachers.push({
                        class_id: this.academic_class.id,
                        teacher_id: user.teacher_id,
                        course_id: user.course_id,
                    })
                })
                academicClassService.syncTeachers(this.academic_class.id,this.class_teachers).then((response) => {
                    this.resetForm()
                    this.$emit("refresh");
                    this.$snotify.success("Information updated");
                })

            },

           resetForm() {
             this.selectedTeachers = [];
             this.class_teachers = [];
             this.teacher_id = null;
             this.course_id = null;
                this.academic_class = {
                  level_id: null,
                  program_id: null,
                  grade_id: null,
                  shift: null,
                  title: null,
                  is_active: true
                }
                this.hideModal();

            }
        }
    };
</script>
